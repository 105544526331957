import React from "react";
import { css } from "@emotion/core";
import { FadeLoader } from "react-spinners";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ParserHTML from "./../parser";

import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
const routes = require("../../../../../../../js/fos_js_routes.json");
import Translator from "bazinga-translator";

const translationsConfig = require("../../../../../../../js/translations/fr.json");
Translator.fromJSON(translationsConfig);

const override = css`
    display: block;
    margin: 0 auto;
    border-color: blue;
`;

let parserHTML = new ParserHTML();

export default class ModalNote extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formNote: null,
            showModalNote: false,
            loadingFormNote: false,
            tiersId: props.tiersId,
            isBtn: props.isBtn
        };
    }

    initInput = () => {
        App.initSelectPicker();
        App.initDatePicker();
    };

    /**
     * Method to init form ModalNoteForm
     *
     * @param form
     */
    initNoteForm = form => {
        this.setState(
            {
                formNote: parserHTML.parse(form),
                showModalNote: true,
                loadingFormNote: false
            },
            () => {
                this.initInput();
            }
        );
    };

    clickLoadModal = async () => {
        this.setState({
            loadingFormNote: true
        });

        let url = Routing.generate("activity_bundle_note_add");

        if (this.state.tiersId) {
            url = Routing.generate("activity_bundle_note_add", { tiers: this.state.tiersId });
        }

        try {
            let response = await fetch(url, {
                method: "GET",
                credentials: "same-origin"
            });

            if (response.status == 200) {
                let form = await response.text();

                this.initNoteForm(form);
            }
        } catch (error) {
            console.log(error);
        }
    };

    closeModalNote = () => {
        this.setState({
            showModalNote: false
        });
    };

    submitNoteForm = async () => {
        this.setState({
            loadingFormNote: true
        });

        let form = document.getElementById("note-form-modal");

        try {
            let url = Routing.generate("activity_bundle_note_add");

            let response = await fetch(url, {
                method: "POST",
                body: new FormData(form),
                credentials: "same-origin"
            });

            if (response.status == 201) {
                let responseData = await response.json();

                if (this.state.tiersId) {
                    window.location = Routing.generate("tiers_bundle_tiers_show", { id: this.state.tiersId });
                }

                this.closeModalNote();
            } else {
                let form = await response.text();

                this.initNoteForm(form);
            }
        } catch (error) {
            console.log(error);
        }
    };

    render() {
        let formModalNote = (
            <Modal size="lg" isOpen={this.state.showModalNote} toggle={this.closeModalNote.bind(this)} onOpened={this.initInput.bind(this)}>
                <ModalHeader toggle={this.closeModalNote.bind(this)}>AJOUTER UNE NOTE</ModalHeader>
                <ModalBody>
                    {this.state.loadingFormNote === true ? (
                        <FadeLoader css={override} sizeUnit={"px"} size={500} color={"#004788"} loading={this.state.loadingFormNote} />
                    ) : (
                        this.state.formNote
                    )}
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={this.submitNoteForm.bind(this)}>
                        {Translator.trans("app.form.submit")}
                    </button>
                    <button className="btn btn-tertiary" onClick={this.closeModalNote.bind(this)}>
                        {Translator.trans("app.form.cancel")}
                    </button>
                </ModalFooter>
            </Modal>
        );

        let link =
            this.state.isBtn === "true" ? (
                <button className="btn btn-actions" onClick={this.clickLoadModal.bind(this)}>
                    <i className="fas fa-phone"></i>
                </button>
            ) : (
                <a className="nav-link" onClick={this.clickLoadModal.bind(this)}>
                    <i className="fas fa-phone"></i>
                </a>
            );

        return (
            <div>
                {link}
                {formModalNote}
            </div>
        );
    }
}
