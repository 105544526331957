import React from 'react';
import SaveToDashboard from '../save-to-dashboard';
import Translator from 'bazinga-translator';

require('highcharts/modules/exporting')(Highcharts);

import Routing
    from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const routes = require("../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class ActionBreakdown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            chartOption: [],
            sources: [],
            source: '',
        };

        this.getData = this.getData.bind(this);
    }

    async getData() {
        let params = {
            source: this.state.source
        };

        let url = Routing.generate('action_breakdown');
        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        let option = {
            chart: {
                type: "column"
            },
            title: {
                text: ""
            },
            series: [],
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    showInLegend: true
                }
            },
            xAxis: {
                categories: []
            },
            yAxis: {
                min: 0,
                title: {
                    text: Translator.trans('eobs.dashboard.action.indicator.action-breakdown.ylabel')
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
                    }
                }
            },
        };

        if (response.status === 200) {
            let data = await response.json();

            option.series = data.series;
            option.xAxis.categories = data.categories;

            this.setState({chartOption: option, sources: data.sources});
        }
    }

    handleSelectSource(event) {
        let source = event.target.value;

        this.setState({
            source: source
        }, () => {
            this.getData();
        });
    }

    handleClickReset() {
        this.setState({
            source: '',
        }, () => {
            this.getData();
        });

    }


    componentDidMount() {
        this.getData()
    }

    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-header">
                    <h4>
                        {Translator.trans('eobs.dashboard.action.indicator.action-breakdown.title')}
                        <SaveToDashboard
                            component="dashboard-action-breakdown"
                            size="6"
                            controller=""
                            script=""
                            title={'Actions - ' + Translator.trans('eobs.dashboard.action.indicator.action-breakdown.title')}
                        />
                    </h4>
                </div>
                <div className="card-body">
                    <form>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label"
                                   htmlFor='sourceSelect'>Type</label>
                            <div className='col-md-3'>
                                <select value={this.state.source} id='sourceSelect' onChange={(event) => this.handleSelectSource(event)}
                                        className="form-control-sm form-control">
                                    {
                                        this.state.sources.map((source, index) => {
                                            return (<option key={index} value={source.value}>{source.label}</option>)
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-md-2 center">
                                <input className="btn btn-sm btn-default" type="reset" value={Translator.trans('eobs.dashboard.reset')} id="reset"
                                       name="reset" onClick={() => this.handleClickReset()}/>
                            </div>
                        </div>
                    </form>
                    <HighchartsReact highcharts={Highcharts} options={this.state.chartOption}/>
                </div>
            </div>
        );
    }
}