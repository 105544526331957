import React from 'react';
import {WidthProvider, Responsive} from "react-grid-layout";

import Routing
    from "../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const routes = require("../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            layout: {},
            indicators: []
        };

        this.onLayoutChange = this.onLayoutChange.bind(this);
    }

    async getIndicators() {
        let url = Routing.generate('get_indicators_ajax');
        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        if (response.status === 200) {
            let data = await response.json();

            this.setState({
                indicators: data.indicators
            });
        }
    }

    onLayoutChange(layout){
        this.setState({layout: JSON.stringify(layout)})
    }

    async saveIndicatorsLayout() {
        let params = {
            layout: this.state.layout,
        };
        let url = Routing.generate('save_layout_indicator_ajax');
        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        if (response.status == 200){
            window.location.reload();
        }
    }

    onRemoveItem(i) {
        this.setState({ indicators: this.state.indicators.filter(indicator => indicator.id != i) });
    }


    handleClickValid() {
        this.saveIndicatorsLayout();
    }

    componentDidMount() {
        this.getIndicators();
    }

    render() {
        const removeStyle = {
            position: "absolute",
            right: "2px",
            top: 0,
            cursor: "pointer"
        };
        return (
            <div className="text-center">
                <div id="layout">
                    <ResponsiveReactGridLayout
                        className="layout"
                        cols={{lg: 4, md: 4, sm: 4, xs: 4, xxs: 4}}
                        rowHeight={200}
                        onLayoutChange={this.onLayoutChange}
                    >
                        {this.state.indicators.map((indicator, index) => (
                            <div className='card mt-0 align-items-center grabbable' key={indicator.id} data-grid={{
                                x: indicator.x,
                                y: indicator.y,
                                w: indicator.w,
                                h: 1,
                                minW: 1,
                                maxW: 4,
                                minH: 1,
                                maxH: 1
                            }}>
                                <span
                                    className="text-secondary remove mr-1"
                                    style={removeStyle}
                                    onClick={this.onRemoveItem.bind(this, indicator.id)}
                                >
                                <i className="icon fas fa-times"></i>
                            </span>
                                <h3 className="mt-5">{indicator.title}</h3></div>
                        ))}
                    </ResponsiveReactGridLayout>
                </div>
                <button id="validate-btn" className="btn btn-primary text-center" onClick={() => this.handleClickValid()}>Valider</button>
            </div>
        )

    }
}