import React, { Component, createRef } from "react";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import FrappeGantt from "frappe-gantt";
import Translator from "bazinga-translator";
import { Spinner, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ProjectTask from "./ProjectTask";
import moment from "moment";

Routing.setRoutingData(require("../../../../../../../js/fos_js_routes.json"));

class Gantt extends Component {
    constructor(props) {
        super(props);

        this._target = createRef();
        this._svg = createRef();
        this._gantt = null;

        this.state = {
            barHeight: 38,
            isLoading: false,
            isOpen: false,
            view_mode: "Week",
            params: null,
            project: this.props.project,
            tasks: [
                /*{
                id: "Task 1",
                name: "Redesign website",
                start: "2016-12-28",
                end: "2016-12-31",
                progress: 10,
                dependencies: ""
            },
                {
                    id: "Task 2",
                    name: "Redesign website",
                    start: "2016-12-28",
                    end: "2016-12-31",
                    progress: 20,
                    dependencies: "Task 1"
                },
                {
                    id: "Task 3",
                    name: "Redesign website",
                    start: "2016-12-28",
                    end: "2016-12-31",
                    progress: 0,
                    dependencies: "Task 2, Task 1"
                }*/
            ],
        };

        this.updateTask.bind(this);
        this.onProgressChange.bind(this);
        this.onDateChange.bind(this);
        this.onClick.bind(this);
        this.updateSize.bind(this);
    }

    async updateTask(params) {
        if (params.start && params.end) {
            const start = moment(params.start).format("DD/MM/YYYY");

            let end = moment(params.end).format("DD/MM/YYYY");

            if (start != end) {
                end = moment(params.end).add(-1, "hours").format("DD/MM/YYYY");
            }

            params.start = start;
            params.end = end;
        }

        try {
            const response = await fetch(Routing.generate("project_task_update", params), {
                method: "GET",
                credentials: "same-origin",
            });

            if (response.status === 200) {
                const tasks = this.state.tasks;
                const index = tasks.findIndex((t) => params.id === t.id);

                tasks[index] = await response.json();

                this.setState(
                    {
                        isOpen: false,
                        params: null,
                        tasks: tasks,
                    },
                    () => this._gantt.refresh(tasks)
                );
            }
        } catch (error) {}
    }

    onProgressChange(task, progress) {
        this.updateTask({ id: task.id, progress: progress });
    }

    onDateChange(task, start, end) {
        const params = { id: task.id, start: start, end: end };

        if (task.isInitialPlanning) {
            this.setState({
                isOpen: true,
                params: params,
            });

            return;
        }

        this.updateTask(params);
    }

    onClick(task) {
        if (task.isMilestone) {
            ProjectTask.editTaskMilestone(task.id);
        } else {
            ProjectTask.showTask(this.state.project, task.id);
        }
    }

    updateSize() {
        const tasks = this.state.tasks;

        let height = 38;

        tasks.forEach((task) => {
            const h = $("#task-" + task.id).height();

            if (h > height) {
                height = h;
            }
        });

        this.setState(
            {
                barHeight: height,
            },
            () => {
                this._gantt.options.bar_height = height - 18;
                this._gantt.refresh(tasks);
            }
        );
    }

    async loadData() {
        this.setState({
            isLoading: true,
        });

        try {
            const response = await fetch(Routing.generate("project_task_gantt", { id: this.state.project }), {
                method: "GET",
                credentials: "same-origin",
            });

            if (response.status === 200) {
                const data = await response.json();

                this.setState(
                    {
                        isLoading: false,
                        tasks: data.tasks,
                    },
                    () => {
                        const state = this.state;

                        if (this._gantt) {
                            this._gantt.refresh(state.tasks);
                        } else {
                            this._gantt = new FrappeGantt(this._svg.current, state.tasks, {
                                bar_height: state.barHeight - 18,
                                date_format: "DD/MM/YYYY",
                                language: "fr",
                                view_mode: state.view_mode,
                                on_progress_change: (task, progress) => {
                                    this.onProgressChange(task, progress);
                                },
                                on_date_change: (task, start, end) => {
                                    this.onDateChange(task, start, end);
                                },
                                on_click: (task) => {
                                    this.onClick(task);
                                },
                                custom_popup_html: (task) => {
                                    const initalDates = task.initalDates;

                                    return `
                                        <div style="background: #e4ecf5; display: inline-block; padding: 0.25rem; min-width: 250px;">
                                            ${task.name}
                                            <hr style="border: 0; border-top: 1px solid rgba(0, 0, 0, 0.1); margin: 0; margin-bottom: 10px;" />
                                            ${
                                                initalDates != null
                                                    ? "Planification initiale:<br/> " + initalDates.start + " - " + initalDates.end
                                                    : ""
                                            }
                                        </div>
                                    `;
                                },
                            });
                        }

                        this.updateSize();
                    }
                );
            }
        } catch (error) {}

        this.setState({
            isLoading: false,
        });
    }

    updateGanttView(mode) {
        this.setState(
            {
                view_mode: mode,
            },
            () => this._gantt.change_view_mode(this.state.view_mode)
        );
    }

    cancelModal = () => {
        this.setState(
            {
                isOpen: false,
                params: null,
            },
            () => this._gantt.refresh(this.state.tasks)
        );
    };

    validateModal = () => {
        this.updateTask(this.state.params);
    };

    componentDidMount = () => {
        this.loadData();
    };

    render() {
        const state = this.state;

        if (state.isLoading) {
            return (
                <div className="text-center">
                    <Spinner className="secondary" />
                </div>
            );
        }

        return (
            <>
                <Modal isOpen={state.isOpen} toggle={this.cancelModal.bind(this)}>
                    <ModalHeader toggle={this.cancelModal.bind(this)}>Confirmation</ModalHeader>
                    <ModalBody>Êtes-vous sûr de vouloir modifier les dates de la tâche ?</ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.validateModal.bind(this)}>
                            Valider
                        </button>
                        <button className="btn btn-default" onClick={this.cancelModal.bind(this)}>
                            Annuler
                        </button>
                    </ModalFooter>
                </Modal>

                <div className="card">
                    <div className="card-body card-table">
                        <div className=" mt-2 mb-2">
                            <div className="row">
                                <div className="col-md-4"></div>
                                <div className="text-right col-md-8 pl-1">
                                    <button
                                        className={"btn btn-primary " + (this.state.view_mode === "Month" ? "active" : "")}
                                        onClick={(event) => this.updateGanttView("Month")}
                                    >
                                        Mois
                                    </button>
                                    <button
                                        className={"btn btn-primary " + (this.state.view_mode === "Week" ? "active" : "")}
                                        onClick={(event) => this.updateGanttView("Week")}
                                    >
                                        Semaine
                                    </button>
                                    <button
                                        className={"btn btn-primary " + (this.state.view_mode === "Day" ? "active" : "")}
                                        onClick={(event) => this.updateGanttView("Day")}
                                    >
                                        Jour
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table text-center">
                                        <thead>
                                            <tr style={{ height: 60 }}>
                                                <th>{Translator.trans("mf.project.task.form.label")}</th>
                                                <th>{Translator.trans("mf.project.task.form.progress")}</th>
                                                <th>{Translator.trans("mf.project.task.form.member")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {state.tasks.map((task) => {
                                                return (
                                                    <tr key={task.id} id={"task-" + task.id} style={{ height: state.barHeight }}>
                                                        <td className="align-middle p-0">
                                                            {task.isMilestone && (
                                                                <>
                                                                    <i
                                                                        className="fas fa-bookmark"
                                                                        title={Translator.trans("mf.project.task.milestone.milestone")}
                                                                    ></i>{" "}
                                                                </>
                                                            )}
                                                            {task.name}
                                                        </td>
                                                        <td className="align-middle p-0">{task.progress} %</td>
                                                        <td className="align-middle p-0 px-1">{task.user}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div ref={this._target}>
                                    <svg
                                        ref={this._svg}
                                        width="100%"
                                        height="100%"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Gantt;
