import Routing
    from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Translator from 'bazinga-translator';

const routes = require("../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

const $collectionHolder = $('ul.activityPhases');
const $riskCollectionHolder = $('ul.otherRisks');

export default class RiskAnalysisForm {

    constructor() {
        RiskAnalysisForm.initRiskAnalysisForm();
    };

    static addActivityPhaseForm = ($collectionHolder, $newLinkLi) => {
        const prototype = $collectionHolder.data('prototype');
        const index = $collectionHolder.data('index');
        let newForm = prototype;
        newForm = newForm.replace(/__name__/g, index);
        $collectionHolder.data('index', index + 1);
        let number = $collectionHolder.find('li').length + 1;
        const $newFormLi = $('<li></li>').html("<fieldset><legend>" + Translator.trans('mf.pdp.form.riskAnalysis.activityPhase.number') + number + "</legend>" + newForm + "</fieldset>");
        $newFormLi.find('input[type="hidden"]').val(number);
        $newLinkLi.before($newFormLi);
        RiskAnalysisForm.addPhaseFormDeleteLink($newFormLi);
    };

    static addRiskForm = ($collectionHolder, $newLinkLi) => {
        const prototype = $collectionHolder.data('prototype');
        const index = $collectionHolder.data('index');
        let newForm = prototype;
        newForm = newForm.replace(/__name__/g, index);
        $collectionHolder.data('index', index + 1);
        let number = $collectionHolder.find('.other-risk-item').length + 1;
        const $newFormLi = $('<li class="other-risk-item"></li>').html("<fieldset><legend>" + Translator.trans('mf.pdp.form.riskAnalysis.other_risk.number') + number + "</legend>" + newForm + "</fieldset>");
        $newFormLi.find('input[type="hidden"]').val(number);
        $newLinkLi.before($newFormLi);
        App.initSelectPicker();
        App.initAutocomplete();
        RiskAnalysisForm.addRiskFormDeleteLink($newFormLi);
    };

    static initRiskAnalysisForm = () => {
        const $addPhaseButton = $('<button type="button" class="offset-md-4 btn btn-outline-secondary add_activity_phase_link">' + Translator.trans('mf.pdp.form.riskAnalysis.activityPhase.add') + '</button>');
        const $newLinkLi = $('<ul></ul>').append($addPhaseButton);

        const $addRiskButton = $('<button type="button" class="offset-md-4 btn btn-outline-secondary add_risk_link">' + Translator.trans('mf.pdp.form.riskAnalysis.other_risk.add') + '</button>');
        const $newRiskLi = $('<ul></ul>').append($addRiskButton);

        $collectionHolder.append($newLinkLi);
        $collectionHolder.data('index', $collectionHolder.find('li').length);

        $riskCollectionHolder.append($newRiskLi);
        $riskCollectionHolder.data('index', $riskCollectionHolder.find('.other-risk-item').length);

        $addPhaseButton.on('click', function (e) {
            RiskAnalysisForm.addActivityPhaseForm($collectionHolder, $newLinkLi);
        });
        $collectionHolder.find('li').each(function () {
            RiskAnalysisForm.addPhaseFormDeleteLink($(this));
        });

        $addRiskButton.on('click', function (e) {
            RiskAnalysisForm.addRiskForm($riskCollectionHolder, $newRiskLi);
        });
        $riskCollectionHolder.find('.other-risk-item').each(function () {
            RiskAnalysisForm.addRiskFormDeleteLink($(this));
        });

        $("input[name$='[concerned]']").on('change', function () {
            if ($(this).val() == 1) {
                $(this).parents().eq(3).find('.card-body').collapse('show')
            } else {
                $(this).parents().eq(3).find('.card-body').collapse('hide')
            }
        });

        $(".done-by-row").find('input[type="checkbox"]').each(function () {
            $(this).on('click', function () {
                if ($(this).is(':checked')) {
                    $(this).parents('.done-preventive-row').find('.preventive-row input').prop('checked', true);
                }
            })
        });

    };

    static addPhaseFormDeleteLink = ($phaseFormLi) => {
        var $removeFormButton = $('<button type="button" class="btn btn-link" title="' + Translator.trans('mf.pdp.form.riskAnalysis.activityPhase.remove') + '"><i class="text-danger fas fa-times"></i></button>');
        $phaseFormLi.find('legend').append($removeFormButton);

        $removeFormButton.on('click', function (e) {
            $phaseFormLi.remove();
            $collectionHolder.find('li').each(function (index) {
                $(this).find('input[type="hidden"]').val(index + 1);
                $(this).find('legend').text(Translator.trans('mf.pdp.form.riskAnalysis.activityPhase.number') + (index + 1))
                RiskAnalysisForm.addPhaseFormDeleteLink($(this));
            })
        });
    };

    static addRiskFormDeleteLink = ($phaseFormLi) => {
        var $removeFormButton = $('<button type="button" class="btn btn-link" title="' + Translator.trans('mf.pdp.form.riskAnalysis.other_risk.remove') + '"><i class="text-danger fas fa-times"></i></button>');
        $phaseFormLi.find('legend').append($removeFormButton);

        $removeFormButton.on('click', function (e) {
            $phaseFormLi.remove();
            $riskCollectionHolder.find('.other-risk-item').each(function (index) {
                $(this).find('legend').text(Translator.trans('mf.pdp.form.riskAnalysis.other_risk.number') + (index + 1));
                RiskAnalysisForm.addRiskFormDeleteLink($(this));
            })
        });
    }
}