import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

class ConfirmationModal extends React.Component {
    state = {
        isOpen: false,
    };

    handleModal = (isOpen) => {
        this.setState({
            isOpen: isOpen,
        });
    };

    validate = () => {
        this.handleModal(false);

        const action = this.props.action;

        if (typeof action === "function") {
            action();
        } else {
            window.location.href = action;
        }
    };

    render() {
        const props = this.props;

        return (
            <>
                <button className={this.props.className} disabled={props.disabled} onClick={this.handleModal.bind(this, true)} title={props.title}>
                    {props.text}
                </button>

                <Modal isOpen={this.state.isOpen} toggle={this.handleModal.bind(this, false)}>
                    <ModalHeader toggle={this.handleModal.bind(this, false)}>{props.modalTitle}</ModalHeader>
                    <ModalBody>{props.message}</ModalBody>
                    <ModalFooter>
                        <button className="btn btn-primary" onClick={this.validate.bind(this)}>
                            Oui
                        </button>
                        <button className="btn btn-default" onClick={this.handleModal.bind(this, false)}>
                            Annuler
                        </button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default ConfirmationModal;
