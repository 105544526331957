import React from 'react'
import 'babel-polyfill';
import {render} from 'react-dom';
import {Provider} from 'react-redux';

import FormStore from "./stores/FormStore";
import DashboardStore from "./stores/DashboardStore";

import AccessManager from "./components/Admin/AccessManager";

import ModalNote from "./components/Note/ModalNote";
import ListNote from "./components/Note/ListNote";
import ListActivities from "./components/Activity/ListActivities"
import LeafletMap from "./components/LeafletMap";
//ActivityMap
import ResourceList from "./components/ActivityMap/resource-list";
import ResourceCount from "./components/ActivityMap/resource-count";
import ActivityCount from "./components/ActivityMap/activity-count";
import QuizResultList from "./components/ActivityMap/quiz-result-list";
import QuizResultMonitoring from "./components/ActivityMap/quiz-result-monitoring";
//Action
import ActionBreakdown from "./components/ActionPlan/action-breakdown";
import ActionList from "./components/ActionPlan/action-list";
//VGP
import VgpBreakdown from "./components/Vgp/vgp-breakdown";
//AT
import AtService from "./components/Rh/At/at-service";
import AtEmployee from "./components/Rh/At/at-employee";
import AtMonthlyBreakdown from "./components/Rh/At/at-monthly-breakdown";
import AtFrequency from "./components/Rh/At/at-frequency";
import AtGravity from "./components/Rh/At/at-gravity";
import AtType from "./components/Rh/At/at-type";
import AtInjurySite from "./components/Rh/At/at-injury-site";
import AtWiBreakdown from "./components/Rh/At/at-wi-breakdown";
import AtInfoText from "./components/Rh/At/at-info-text";
import AtRawData from "./components/Rh/At/at-raw-data";
import AtFrequencyBreakdown from "./components/Rh/At/at-frequency-breakdown";
//Formation
import FormationState from "./components/Rh/Formation/formation-state";
import FormationList from "./components/Rh/Formation/formation-list";
import FormationTime from "./components/Rh/Formation/formation-time";
//Event
import EventsOverHierarchyItem from "./components/Event/events-over-hierarchy-item";
import EventsV2seTarget from "./components/Event/events-v2se-target";
//Global
import SaveToDashboard from "./components/save-to-dashboard";
import Dashboard from "./components/dashboard";
import Calendar from "./components/calendar";
import BundleNotificationList from "./components/bundle-notification-list";
import Translator from "bazinga-translator";

//Prevention Plan
import {InterventionForm} from "./components/PreventionPlan/InterventionForm";
import WorkOrder from "./components/PreventionPlan/WorkOrder";
import FirePermit from "./components/PreventionPlan/FirePermit";
import InterventionCompaniesForm from "./components/PreventionPlan/Companies/InterventionCompaniesForm";
import RiskAnalysisForm from "./components/PreventionPlan/RiskAnalysisForm";
import DocumentForm from "./components/PreventionPlan/DocumentForm";
import SurveyForm from "./components/PreventionPlan/SurveyForm";
import HierarchyItemForm from "./components/Admin/HierarchyItemForm";
import PdpArchived from "./components/PreventionPlan/Indicators/PdpArchived";
import PdpState from "./components/PreventionPlan/Indicators/PdpState";
import PdpValidityStatus from "./components/PreventionPlan/Indicators/PdpValidityStatus";
import PdpWorkType from "./components/PreventionPlan/Indicators/PdpWorkType";
import PdpInOperationCount from "./components/PreventionPlan/Indicators/PdpInOperationCount";
import PdpHoursCount from "./components/PreventionPlan/Indicators/PdpHoursCount";
import PdpDangerousCount from "./components/PreventionPlan/Indicators/PdpDangerousCount";
import PdpWorkOrders from "./components/PreventionPlan/Indicators/PdpWorkOrders";
import PdpFirePermit from "./components/PreventionPlan/Indicators/PdpFirePermit";
import MedicalSupervisionWork from "./components/PreventionPlan/MedicalSupervisionWork";
import PreventionPlanManager from "./components/PreventionPlan/PreventionPlanManager";
import StaffInvolved from "./components/PreventionPlan/StaffInvolved";
import Material from "./components/PreventionPlan/Material";
import Advice from "./components/PreventionPlan/Advice";
import Signature from "./components/PreventionPlan/Signature";
//Tiers
import ContactDocumentForm from "./components/Tiers/ContactDocumentForm";
import CoactivityMap from "./components/PreventionPlan/Indicators/CoactivityMap";

import UserDocumentForm from "./components/PreventionPlan/UserDocumentForm";
import HierarchyTree from "./components/Admin/HierarchyTree";
import UserRoleForm from "./components/Admin/UserRoleForm";
//Project
import ProjectManager from "./components/Project/ProjectManager";

const translationsConfig = require("../../../../../js/translations/fr.json");
Translator.fromJSON(translationsConfig);

//Tools
// Show an element
var show = function (elem) {
    elem.style = '';
};

// Hide an element
var hide = function (elem) {
    elem.style.display = 'none';
};

// Toggle element visibility
var toggle = function (elem) {

    // If the element is visible, hide it
    if (window.getComputedStyle(elem).display !== 'none') {
        hide(elem);
        return;
    }

    // Otherwise, show it
    show(elem);
};

/**
 * Rendering component React ListNote
 */
const listNote = document.getElementById("component-list-note");

if (listNote) {
    render(
        <Provider store={FormStore}>
            <ListNote/>
        </Provider>,
        listNote
    );
}

/**
 * Rendering component React Activities
 */
const activitiesElement = document.getElementById("container-bundle-activity-list-react");

if (activitiesElement) {
    render(<ListActivities tiersId={activitiesElement.dataset.tiersId}/>, activitiesElement);
}

/**
 * Rendering component React NoteModal
 */
const noteModals = Array.prototype.slice.call(document.getElementsByClassName("react-note-modal-component"));

noteModals.forEach(element => {
    render(
        <Provider store={FormStore}>
            <ModalNote tiersId={element.dataset.tiersId} isBtn={element.dataset.isBtn}/>
        </Provider>,
        element
    );
});

/**
 * Rendering Dasboard resource list
 */
const resourceList = document.getElementById('dashboard-resource-list');
if (resourceList) {
    render(<ResourceList/>, resourceList);
}

/**
 * Rendering Dasboard resource list
 */
const resourceCount = document.getElementById('dashboard-resource-count');
if (resourceCount) {
    render(<ResourceCount/>, resourceCount);
}

/**
 * Rendering Dasboard resource list
 */
const activityCount = document.getElementById('dashboard-activity-count');
if (activityCount) {
    render(<ActivityCount/>, activityCount);
}

/**
 * Rendering Dasboard At by service
 */
const actionBreakdown = document.getElementById('dashboard-action-breakdown');
if (actionBreakdown) {
    render(<ActionBreakdown/>, actionBreakdown);
}

/**
 * Rendering Dasboard At by service
 */
const actionList = document.getElementById('dashboard-action-list');
if (actionList) {
    render(<ActionList/>, actionList);
}

const vgpBreakdown = document.getElementById('dashboard-vgp-breakdown');
if (vgpBreakdown) {
    render(<VgpBreakdown/>, vgpBreakdown);
}

/**
 * Rendering Dasboard At by service
 */
const atService = document.getElementById('dashboard-at-service');
if (atService) {
    render(<AtService/>, atService);
}

/**
 * Rendering Dasboard employee list
 */
const atEmployee = document.getElementById('dashboard-at-employee');
if (atEmployee) {
    render(<AtEmployee/>, atEmployee);
}

/**
 * Rendering Dasboard monthly breakdown
 */
const monthlyBreakdown = document.getElementById('dashboard-at-monthly-breakdown');
if (monthlyBreakdown) {
    render(<AtMonthlyBreakdown/>, monthlyBreakdown);
}

/**
 * Rendering Dasboard wi breakdown
 */
const wiBreakdown = document.getElementById('dashboard-at-wi-breakdown');
if (wiBreakdown) {
    render(<AtWiBreakdown/>, wiBreakdown);
}

/**
 * Rendering Dasboard at fraquency list
 */
const atFrequency = document.getElementById('dashboard-at-frequency');
if (atFrequency) {
    render(<AtFrequency/>, atFrequency);
}

/**
 * Rendering Dasboard at gravity list
 */
const atGravity = document.getElementById('dashboard-at-gravity');
if (atGravity) {
    render(<AtGravity/>, atGravity);
}

/**
 * Rendering Dashboard at type
 */
const atType = document.getElementById('dashboard-at-type');
if (atType) {
    render(<AtType/>, atType);
}

/**
 * Rendering Dashboard at injury site
 */
const atInjurySite = document.getElementById('dashboard-at-injury-site');
if (atInjurySite) {
    render(<AtInjurySite/>, atInjurySite);
}

/**
 * Rendering Dasboard at gravity list
 */
const atInfoText = document.getElementById('dashboard-at-info-text');
if (atInfoText) {
    render(<AtInfoText/>, atInfoText);
}

/**
 * Rendering Dasboard at gravity list
 */
const atRawData = document.getElementById('dashboard-at-raw-data');
if (atRawData) {
    render(<AtRawData/>, atRawData);
}


/**
 * Rendering Dasboard at gravity list
 */
const atFrequencyBreakdown = document.getElementById('dashboard-at-frequency-breakdown');
if (atFrequencyBreakdown) {
    render(<AtFrequencyBreakdown/>, atFrequencyBreakdown);
}


/**
 * Rendering Dasboard formation by state
 */
const formationState = document.getElementById('dashboard-form-state');
if (formationState) {
    render(<FormationState/>, formationState);
}

/**
 * Rendering Dasboard formation list
 */
const formationList = document.getElementById('dashboard-form-list');
if (formationList) {
    render(<FormationList/>, formationList);
}

/**
 * Rendering Dasboard formation list
 */
const formationTime = document.getElementById('dashboard-rh-formation-time');
if (formationTime) {
    render(<FormationTime/>, formationTime);
}

/**
 * Rendering Dasboard at gravity list
 */
const eventsOverAgent = document.getElementById('dashboard-events-over-hierarchyItem');
if (eventsOverAgent) {
    render(<EventsOverHierarchyItem/>, eventsOverAgent);
}

/**
 * Rendering Dasboard at gravity list
 */
const eventsV2seTarget = document.getElementById('dashboard-events-v2se-target');
if (eventsV2seTarget) {
    render(<EventsV2seTarget/>, eventsV2seTarget);
}

/**
 * saveToDashboard
 */
const saveToDashboardElements = document.getElementsByClassName('save-to-dashboard');
Array.prototype.forEach.call(saveToDashboardElements, indicator => {
    render(
        <SaveToDashboard
            component=''
            controller={indicator.dataset.controller}
            script={indicator.dataset.script}
            size={indicator.dataset.size}
            title={indicator.dataset.title}
        />,
        indicator
    );
});


/**
 * Rendering Dashboard drag and drop
 */
const dashboard = document.getElementById('dashboard-dnd');
if (dashboard) {
    render(<Dashboard/>, dashboard);
    toggle(dashboard);
    $('#toggle-dashboard').on('click', function () {
        toggle(dashboard);
        toggle(document.getElementById('indicators'));
    });
}

/**
 * Redering Calendar
 */
const calendarContainer = document.getElementById("calendar-container");
if (calendarContainer) {
    render(<Calendar/>, calendarContainer);
}

/**
 * Redering work Calendar
 */
const workCalendarContainer = document.getElementById("work-calendar-container");
if (workCalendarContainer) {
    render(<Calendar workWeek='1'/>, workCalendarContainer);
}

/**
 * Rendering Notification bundle ReactJS Component
 */
const bundleNotificationContainerElements = Array.prototype.slice.call(
    document.getElementsByClassName("container-bundle-notification-list-react")
);

/**
 * Rendering Quiz result list
 */
const quizResultList = document.getElementById('quiz-results-list');
if (quizResultList) {
    render(
        <QuizResultList
            quiz={quizResultList.dataset.quiz}
        />,
        quizResultList
    );
}
/**
 *
 * Rendering Quiz result monitoring
 */
const quizResultMonitoring = document.getElementById('quiz-results-monitoring');
if (quizResultMonitoring) {
    render(
        <QuizResultMonitoring
            quiz={quizResultMonitoring.dataset.quiz}
        />,
        quizResultMonitoring
    );
}

bundleNotificationContainerElements.forEach(element => {
    render(
        <BundleNotificationList
            entityClassName={element.dataset.entityClassName}
            entityId={element.dataset.entityId}
            recipientClassName={element.dataset.recipientClassName}
            disablingLinks={element.dataset.disablingLinks}
        />,
        element
    );
});

/**
 * Rendering Quiz result list
 */
const workOrderTab = document.getElementById('work-order-tab');
if (workOrderTab) {
    render(
        <Provider store={FormStore}>
            <WorkOrder
                intervention={workOrderTab.dataset.intervention}
                hierarchyItemName={workOrderTab.dataset.hierarchyItem}
                plan={workOrderTab.dataset.plan}
                width={workOrderTab.dataset.width}
                granted={workOrderTab.dataset.granted}
            />
        </Provider>,
        workOrderTab
    );
}

const firePermitTab = document.getElementById('fire-permit-tab');
if (firePermitTab) {
    render(
        <Provider store={FormStore}>
            <FirePermit
                intervention={firePermitTab.dataset.intervention}
                hierarchyItemName={firePermitTab.dataset.hierarchyItem}
                plan={firePermitTab.dataset.plan}
                width={firePermitTab.dataset.width}
                granted={firePermitTab.dataset.granted}
            />
        </Provider>,
        firePermitTab
    );
}

const interventionPage = document.getElementById('intervention-page');
if (interventionPage) {
    new InterventionForm();
}

const interventionCompaniesForm = document.getElementById('intervention-companies-form');
if (interventionCompaniesForm) {
    render(
        <Provider store={FormStore}>
            <InterventionCompaniesForm intervention={interventionCompaniesForm.dataset.intervention}
                                       granted={interventionCompaniesForm.dataset.granted}/>
        </Provider>,
        interventionCompaniesForm
    )
}

const riskAnalysisForm = document.getElementById('risk-analysis-result-form');
if (riskAnalysisForm) {
    new RiskAnalysisForm();
}

const pdpDocumentForm = document.getElementById('pdp-document-form');
if (pdpDocumentForm) {
    new DocumentForm();
}

const pdpSurveyForm = document.getElementById('survey-answers-forms');
if (pdpSurveyForm) {
    new SurveyForm();
}

const pdpMedicalSupervisionForm = document.getElementById('medical-supervision-works');
if (pdpMedicalSupervisionForm) {
    new MedicalSupervisionWork();
}

const pdpMaterialForm = document.getElementById('intervention-materials');
if (pdpMaterialForm) {
    new Material();
}

const pdpStaffInvolved = document.getElementById('intervention-staff');
if (pdpStaffInvolved) {
    new StaffInvolved();
}

/**
 * Rendering Dasboard pdp by state
 */
const pdpAdvice = document.getElementById('intervention-advice');
if (pdpAdvice) {
    render(<Advice intervention={pdpAdvice.dataset.intervention} granted={pdpAdvice.dataset.granted}/>, pdpAdvice);
}

const pdpSignature = document.getElementById('intervention-signature');
if (pdpSignature) {
    render(<Signature intervention={pdpSignature.dataset.intervention} inspection={false}
                      granted={pdpSignature.dataset.granted}/>, pdpSignature);
}

const inspectionSignature = document.getElementById('inspection-signature');
if (inspectionSignature) {
    render(<Signature intervention={inspectionSignature.dataset.intervention} inspection={true}
                      granted={true}/>, inspectionSignature);
}

/**
 * Rendering Dasboard pdp by state
 */
const pdpState = document.getElementById('dashboard-pdp-state');
if (pdpState) {
    render(<PdpState/>, pdpState);
}

const pdpArchived = document.getElementById('dashboard-pdp-archived');
if (pdpArchived) {
    render(<PdpArchived/>, pdpArchived);
}

/**
 * Rendering Dasboard pdp by state
 */
const pdpValidityStatus = document.getElementById('dashboard-pdp-validity-status');
if (pdpValidityStatus) {
    render(<PdpValidityStatus/>, pdpValidityStatus);
}

/**
 * Rendering Dasboard pdp by work type
 */
const pdpWorkType = document.getElementById('dashboard-pdp-work-type');
if (pdpWorkType) {
    render(<PdpWorkType/>, pdpWorkType);
}

const pdpInOperationCount = document.getElementById('dashboard-pdp-in-operation-count');
if (pdpInOperationCount) {
    render(<PdpInOperationCount/>, pdpInOperationCount);
}

const pdpHoursCount = document.getElementById('dashboard-pdp-hours-count');
if (pdpHoursCount) {
    render(<PdpHoursCount/>, pdpHoursCount);
}

const pdpDangerousCount = document.getElementById('dashboard-pdp-dangerous-count');
if (pdpDangerousCount) {
    render(<PdpDangerousCount/>, pdpDangerousCount);
}

const pdpWorkOrders = document.getElementById('dashboard-pdp-work-orders');
if (pdpWorkOrders) {
    render(
        <Provider store={DashboardStore}>
            <PdpWorkOrders/>
        </Provider>, 
        pdpWorkOrders
    );
}

const pdpFirePermits = document.getElementById('dashboard-pdp-fire-permits');
if (pdpFirePermits) {
    render(
        <Provider store={DashboardStore}>
            <PdpFirePermit/>
        </Provider>, 
        pdpFirePermits
    );
}

const pdpCoactivityMap = document.getElementById('dashboard-pdp-coactivity-map');
if (pdpCoactivityMap) {
    render(
        <Provider store={DashboardStore}>
            <CoactivityMap/>
        </Provider>,  
        pdpCoactivityMap
    );
}

new PreventionPlanManager();

const contactDocumentForm = document.getElementById('contact-document-form');
if (contactDocumentForm) {
    new ContactDocumentForm();
}

const userDocument = document.getElementsByClassName('js-user-document');
if (userDocument.length) {
    new UserDocumentForm();
}

const userRole = document.getElementsByClassName('js-user-role');
if (userRole.length) {
    new UserRoleForm();
}

const hiearchyItemForm = document.getElementById('hierarchy-item-form');
if (hiearchyItemForm) {
    new HierarchyItemForm();
}

const hiearchyTree = document.getElementById('hierarchy-tree');
if (hiearchyTree) {
    render(<HierarchyTree/>, hiearchyTree)
}

new ProjectManager();

if (document.getElementById("access-form")) {
    new AccessManager();
}

const leafletMaps = Array.prototype.slice.call(document.getElementsByClassName("component-leaflet-map"));
leafletMaps.forEach(leafletMap => {
    render(
        <LeafletMap
            latitude={leafletMap.dataset.latitude}
            longitude={leafletMap.dataset.longitude}
            popup={leafletMap.dataset.popup}
            zoom={leafletMap.dataset.zoom}
        />,
        leafletMap
    );
});