import Routing
    from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Translator from 'bazinga-translator';
import bsCustomFileInput from "bs-custom-file-input";
import ContactDocumentForm from "../Tiers/ContactDocumentForm";
import StaffInvolved from "./StaffInvolved";

const routes = require("../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

const $collectionHolder = $('ul.documents');

export default class DocumentForm {

    constructor() {
        DocumentForm.initDocumentForm();
    };

    static addDocumentForm = ($collectionHolder, $newLinkLi) => {
        const prototype = $collectionHolder.data('prototype');
        const index = $collectionHolder.data('index');
        let newForm = prototype;
        newForm = newForm.replace(/__name__/g, index);
        $collectionHolder.data('index', index + 1);
        const $newFormLi = $('<li></li>').html("<fieldset><legend>" + Translator.trans('mf.pdp.form.document.new') + "</legend>" + newForm + "</fieldset>");
        $newLinkLi.before($newFormLi);
        bsCustomFileInput.init();
        App.initSelectPicker();
        DocumentForm.addDocumentFormDeleteLink($newFormLi);
    };

    static initDocumentForm = () => {
        const $addDocumentButton = $('<button type="button" class="offset-md-4 btn btn-secondary add_document_link">' + Translator.trans('mf.pdp.form.document.add') + '</button>');
        const $newLinkLi = $('<ul></ul>').append($addDocumentButton);

        $collectionHolder.append($newLinkLi);
        $addDocumentButton.on('click', function (e) {
            DocumentForm.addDocumentForm($collectionHolder, $newLinkLi);
        });
        $collectionHolder.find('li').each(function () {
            DocumentForm.addDocumentFormDeleteLink($(this));
        });

        DocumentForm.addDocumentForm($collectionHolder, $newLinkLi);

        $('.js-document-delete').on('click', function () {
            const document = $(this).data('document');
            const intervention = $(this).data('intervention');
            App.modalConfirmDelete(function () {
                DocumentForm.deleteDocument(intervention, document);
            })
        });

        $('.js-document-edit').on('click', function () {
            const document = $(this).data('document');
            const intervention = $(this).data('intervention');
            DocumentForm.editDocument(intervention, document);
        });

        $('.js-document-contact-show').on('click', function () {
            const tiersContact = $(this).data('tiers-contact');
            StaffInvolved.showDocuments(tiersContact);
        })
    };

    static addDocumentFormDeleteLink = ($documentFormLi) => {
        var $removeFormButton = $('<button type="button" class="btn btn-link" title="' + Translator.trans('mf.pdp.form.document.remove') + '"><i class="text-danger fas fa-times"></i></button>');
        $documentFormLi.find('legend').append($removeFormButton);

        $removeFormButton.on('click', function (e) {
            $documentFormLi.remove();
        });
    };

    static editDocument = async (intervention, document) => {
        let url = Routing.generate('pdp_show_intervention_document_edit', {id: intervention, documentId: document});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const documentForm = await response.text();
            $('#modal-document-edit').html(documentForm);
            bsCustomFileInput.init();
            App.initSelectPicker();
            $('#modal-document-edit').modal()
        } else {
            console.error('Une erreur est survenue, le document n\'a pas été trouvé')
        }
    };

    static deleteDocument = async (intervention, document) => {
        let url = Routing.generate('pdp_show_intervention_document_delete', {id: intervention, documentId: document});

        let response = await fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response.status == 200) {
            $('#document-' + document).remove()
        } else {
            console.error('Une erreur est survenue, le document n\'a pas été supprimé')
        }
    }
}