import React, {Component} from 'react';
import ReactPaginate from 'react-paginate';
import Translator from 'bazinga-translator';
import ParserHTML from "./../parser";
import {
    Circle,
    CircleMarker,
    FeatureGroup,
    ImageOverlay,
    LayerGroup,
    LayersControl,
    Map,
    Marker,
    Polygon,
    Popup
} from "react-leaflet";
import {EditControl} from "react-leaflet-draw"
import SignaturePad from "react-signature-canvas";

import L, {CRS} from 'leaflet';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Col,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    Table,
    UncontrolledTooltip,
} from 'reactstrap'
import Routing
    from '../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
import drawLocales from "leaflet-draw-locales";
import {connect} from "react-redux";
import Signature from "./Signature";

L.drawLocal = drawLocales('fr');

import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl,
    iconUrl,
    shadowUrl
});


const routes = require('../../../../../../../js/fos_js_routes.json');

Routing.setRoutingData(routes);

let parserHTML = new ParserHTML();

const defaultFirePermit = {
    id: '',
    date: '',
    start: '',
    end: '',
    startDate: '',
    endDate: '',
    zones: [],
    plan: {width: 0, name: '', path: ''}
};
const {Overlay} = LayersControl;

class FirePermit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingSpinner: false,
            resultsPerPage: 5,
            nbTotalResults: 0,
            resultsOffsetPage: 0,
            nbTotalResultsPage: 5,
            results: [],
            intervention: props.intervention,
            action: "",
            firePermit: defaultFirePermit,
            firePermitModalContent: "",
            showFirePermitModal: false,
            submitted: false
        };

        this.selectFirePermit = this.selectFirePermit.bind(this);
        this.addFirePermit = this.addFirePermit.bind(this);
        this.submitFirePermit = this.submitFirePermit.bind(this);
        this.signFirePermit = this.signFirePermit.bind(this);
    }

    sigCanvas = {};
    sigName = "";

    componentDidMount = () => {
        this.loadResults();
    };

    async loadResults() {
        this.setState({
            loadingSpinner: true,
            submitted: false,
        });

        let params = {
            limit: this.state.resultsPerPage,
            offset: this.state.resultsOffsetPage,
        };

        let url = Routing.generate('mf_fire_permit_list_by_intervention', {'id': this.state.intervention});
        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        if (response.status === 200) {
            let data = await response.json();

            if (data.informations.firePermits[0]) {
                defaultFirePermit.plan = data.informations.firePermits[0].plan
            }

            this.setState({
                loadingSpinner: false,
                results: data.informations.firePermits,
                defaultFirePermit: defaultFirePermit,
                nbTotalResults: data.informations.nbTotalItems,
                nbTotalResultsPage: Math.ceil(data.informations.nbTotalItems / this.state.resultsPerPage)
            });
        }
    }

    handleClickPages(page) {
        let offset = Math.ceil(page.selected * this.state.resultsPerPage);

        this.setState({resultsOffsetPage: offset}, () => {
            this.loadResults();
        });
    }

    handleSelectNbItem(event) {
        this.setState({resultsPerPage: event.target.value}, () => {
            this.loadResults();
        });
    }

    selectFirePermit = async firePermit => {

        let response = await fetch(Routing.generate('mf_fire_permit_get_zones', {'id': firePermit.id}),
            {
                method: "GET",
                credentials: "same-origin",
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            }
        );

        if (response.status === 200) {
            let data = await response.json();
            firePermit.zones = data.current;
            firePermit.plan = data.plan;
        }

        this.setState({firePermit: firePermit}, function () {
            App.scrollTo('#map');
        });
    };

    submitFirePermit = async (firePermit, sign = null) => {
        this.setState({
            submitted: true
        });
        let form = document.getElementById("fire_permit_form");

        let url = Routing.generate("mf_fire_permit_modal_add", {
            id: this.state.intervention
        });

        if (this.state.action === "edit") {
            url = Routing.generate("mf_fire_permit_modal_edit", {
                id: this.state.firePermit.id
            });
        }

        try {
            let response = await fetch(url, {
                method: "POST",
                body: new FormData(form),
                credentials: "same-origin",
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            });

            if (response.status === 201) {
                let data = await response.json();

                let firePermit = this.state.firePermit;
                firePermit.id = data.result;
                this.setState({firePermit: firePermit});

                if (sign === true) {
                    this.displaySignForm();
                } else {
                    this.closeFirePermitModal();
                }
            } else {
                let form = await response.text();
                this.initFirePermitForm(form, this.state.action, this.state.firePermit);
            }
        } catch (error) {
            console.error("Error submit firePermit");
            this.loadResults();
        }
    };

    editFirePermit = async firePermit => {
        try {
            let response = await fetch(
                Routing.generate("mf_fire_permit_modal_edit", {
                    id: firePermit.id
                }),
                {
                    method: "GET",
                    credentials: "include"
                }
            );

            if (response.status === 200) {
                let form = await response.text();

                this.initFirePermitForm(form, "edit", firePermit);
            }
        } catch (error) {
            console.error("Error edit work order");
        }
    };

    addFirePermit = async () => {
        try {
            let response = await fetch(
                Routing.generate("mf_fire_permit_modal_add", {
                    id: this.state.intervention
                }),
                {
                    method: "GET",
                    credentials: "include"
                }
            );

            if (response.status === 200) {
                let form = await response.text();

                this.initFirePermitForm(form, "add");
            }
        } catch (error) {
            console.error("Error add fire permit");
        }
    };

    initFirePermitForm = (form, action, firePermit = defaultFirePermit) => {
        this.setState({firePermitModalContent: null, submitted: false}, () => {
            this.setState(
                {
                    action: action,
                    firePermit: firePermit,
                    firePermitModalContent: parserHTML.parse(form),
                    showFirePermitModal: true
                },
                () => {
                    this.initInput();
                }
            );
        });
    };

    signFirePermit = async () => {
        await this.submitFirePermit(this.state.firePermit, true);
    };

    displaySignForm = () => {
        this.setState({
            action: 'sign',
            submitted: false
        });
    };

    initInput() {
        App.initDatePicker();
        App.initSelectPicker();
        App.initCustomFileUpload();
    }

    closeFirePermitModal() {
        this.loadResults();
        this.setState(
            {
                firePermit: defaultFirePermit,
                showFirePermitModal: false
            }
        );
    }

    onLayerEdit = async e => {
        let layers = e.layers;
        layers.eachLayer(async function (layer) {
            let type = layer.options.type,
                id = layer.options.id,
                data = FirePermit.getLayerData(type, layer);

            try {
                let response = await fetch(Routing.generate('mf_fire_permit_leaflet_zone_edit', {'id': id}),
                    {
                        method: "POST",
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({type: type, data: data}),
                        credentials: "include"
                    }
                );
            } catch (error) {
                console.error(error);
                console.error("Error on leaflet zone edit");
            }
        });
    };

    onLayerCreate = async e => {
        if (this.state.firePermit.id) {
            let type = e.layerType,
                layer = e.layer,
                data = FirePermit.getLayerData(type, layer);

            try {
                let response = await fetch(Routing.generate('mf_fire_permit_leaflet_zone_add', {'id': this.state.firePermit.id}),
                    {
                        method: "POST",
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({type: type, data: data}),
                        credentials: "include"
                    }
                );

                if (response.status === 200) {
                    let zone = await response.json();
                    this.state.firePermit.zones.push(zone);
                    this.setState({firePermit: this.state.firePermit});
                    layer.remove();
                }
            } catch (error) {
                console.error(error);
                console.error("Error on leaflet zone add");
            }
        } else {
            e.layer.remove();
        }
    };

    onLayerDelete = async e => {
        let layers = e.layers;
        let firePermit = this.state.firePermit;
        layers.eachLayer(async function (layer) {
            try {
                if (firePermit.id === layer.options.firePermit) {
                    let response = await fetch(Routing.generate('mf_fire_permit_leaflet_zone_delete', {'id': layer.options.id}),
                        {
                            method: "GET",
                            credentials: "include"
                        }
                    );
                    if (response.status === 200) {

                        firePermit.zones = firePermit.zones.filter(function (zone) {
                            return layer.options.id !== zone.id;
                        });
                    }
                }
            } catch (error) {
                console.error(error);
                console.error("Error on leaflet zone delete");
            }
        });
        this.setState({firePermit: firePermit});
    };

    static getLayerData = (type, layer) => {
        let data = [];
        if (type === "circle" || type === "circlemarker") {
            let radius = layer.getRadius();
            let center = layer.getLatLng();

            data = {'radius': radius, 'center': center}
        } else if (type === "marker") {
            let position = layer.getLatLng();

            data = {'position': position}
        } else if (type === "polygon") {
            let points = layer.getLatLngs();

            data = {'points': points}
        }
        return data;
    };

    render() {
        let listingResults = <tr className="text-center">
            <td colSpan={5}><Spinner type="grow" color="secondary"/></td>
        </tr>;
        if (!this.state.loadingSpinner) {
            listingResults = this.state.results.map((result, index) => {

                let pdfLink = Routing.generate('pdp_fire_permit_pdf', {'id': result.id});

                return (
                    <tr key={index}>
                        <td>
                            {result.date}
                        </td>
                        <td>
                            {result.start} {Translator.trans('mf.pdp.form.firePermit.to')} {result.end}
                        </td>
                        <td>
                            {result.sector}
                        </td>
                        <td className="text-right" width="20%">
                            <Button onClick={this.editFirePermit.bind(this, result)} id={"tooltip-edit" + index}>
                                <i className="fas fa-pen"/>
                            </Button>
                            <UncontrolledTooltip
                                target={"tooltip-edit" + index}>{Translator.trans('mf.pdp.form.firePermit.edit')}</UncontrolledTooltip>
                            <Button onClick={this.selectFirePermit.bind(this, result)}
                                    id={"tooltip-to_map" + index}
                                    className={result === this.state.firePermit ? 'active' : ''}>
                                <i className="fas fa-map-marked"/>
                            </Button>
                            <UncontrolledTooltip
                                target={"tooltip-to_map" + index}>{Translator.trans('mf.pdp.form.firePermit.to_map')}</UncontrolledTooltip>
                            <a className="btn btn-secondary" target="_blank" href={pdfLink} id={"tooltip-pdf" + index}>
                                <i className="fas fa-file-pdf"/>
                            </a>
                            <UncontrolledTooltip
                                target={"tooltip-pdf" + index}>{Translator.trans('mf.pdp.form.firePermit.pdf')}</UncontrolledTooltip>
                        </td>
                    </tr>
                )
            });
        }

        let firePermitModal = (
            <Modal
                onOpened={this.initInput}
                size="xl"
                isOpen={this.state.showFirePermitModal}
                toggle={this.closeFirePermitModal.bind(this)}
            >
                <ModalHeader
                    toggle={this.closeFirePermitModal.bind(this)}>{Translator.trans('mf.pdp.form.intervention.firePermit')}</ModalHeader>
                {
                    this.state.action === "sign" ?
                        <>
                            <ModalBody>
                                <Card>
                                    <CardHeader>{Translator.trans('mf.pdp.form.firePermit.agree')}</CardHeader>
                                    <CardBody className="row">
                                        <Signature firePermit={this.state.firePermit.id} firePermitStep={1}
                                                   label={Translator.trans('mf.pdp.form.firePermit.add_sign')}
                                                   granted={true}/>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardHeader>{Translator.trans('mf.pdp.form.firePermit.control')}</CardHeader>
                                    <CardBody className="row">
                                        <div
                                            className="col-md-12">{Translator.trans('mf.pdp.form.firePermit.secondStep')}</div>
                                        <Signature firePermit={this.state.firePermit.id} firePermitStep={2}
                                                   label={Translator.trans('mf.pdp.form.firePermit.add_sign')}
                                                   granted={true}/>
                                        <div
                                            className="col-md-12">{Translator.trans('mf.pdp.form.firePermit.thirdStep')}</div>
                                        <Signature firePermit={this.state.firePermit.id} firePermitStep={3}
                                                   label={Translator.trans('mf.pdp.form.firePermit.add_sign')}
                                                   granted={true}/>
                                    </CardBody>
                                </Card>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary"
                                        onClick={this.editFirePermit.bind(this, this.state.firePermit)}>{Translator.trans('app.form.cancel')}</Button>
                            </ModalFooter>
                        </>
                        :
                        <>
                            <ModalBody>
                                {this.state.firePermitModalContent}
                                <div className="text-center">
                                    <Button
                                        onClick={this.signFirePermit}>{Translator.trans('mf.pdp.form.firePermit.sign')}</Button>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary"
                                        onClick={this.closeFirePermitModal.bind(this)}>{Translator.trans('app.form.cancel')}</Button>
                                <Button color="secondary"
                                        disabled={this.state.submitted}
                                        onClick={this.submitFirePermit}>
                                    {
                                        this.state.submitted ?
                                            <Spinner size="sm" type="grow" color="light" className="mr-1"/>
                                            :
                                            ''
                                    }
                                    {Translator.trans('app.form.submit')}
                                </Button>
                            </ModalFooter>
                        </>
                }
            </Modal>
        );

        let currentLeafletZones = (
            <FeatureGroup>
                <EditControl
                    position='topright'
                    onEdited={this.onLayerEdit}
                    onCreated={this.onLayerCreate}
                    onDeleted={this.onLayerDelete}
                    draw={{
                        circlemarker: false,
                        polyline: false,
                        rectangle: false
                    }}
                />
                {this.state.firePermit.zones.map((zone, index) => {
                    if (zone.type === "circle") {
                        return (
                            <Circle key={index} id={zone.id} firePermit={this.state.firePermit.id}
                                    type="circle"
                                    center={[zone.data.center.lat, zone.data.center.lng]}
                                    radius={zone.data.radius}/>
                        )
                    } else if (zone.type === "circlemarker") {
                        return (
                            <Circle key={index} id={zone.id} firePermit={this.state.firePermit.id}
                                    type="circlemarker"
                                    center={[zone.data.center.lat, zone.data.center.lng]}
                                    radius={zone.data.radius}/>
                        )
                    } else if (zone.type === "marker") {
                        return (
                            <Marker key={index} id={zone.id} firePermit={this.state.firePermit.id}
                                    type="marker"
                                    position={zone.data.position}
                            />
                        )
                    } else if (zone.type === "polygon") {
                        return (
                            <Polygon key={index} id={zone.id} firePermit={this.state.firePermit.id}
                                     type="polygon"
                                     positions={zone.data.points}
                            />
                        )
                    }
                })};
            </FeatureGroup>
        );


        return (
            <Row>
                <Col xl='6'>
                    <Card className="card-table table-responsive">
                        <Table hover striped>
                            <thead>
                            <tr>
                                <th>{Translator.trans('mf.pdp.form.firePermit.day')}</th>
                                <th>{Translator.trans('mf.pdp.form.firePermit.hours')}</th>
                                <th>{Translator.trans('mf.pdp.form.firePermit.sector_short')}</th>
                                <th className="text-right">
                                    {
                                        this.props.granted ?
                                            <>
                                                <Button outline onClick={this.addFirePermit} id="tooltip-add">
                                                    <i className="fas fa-plus"></i>
                                                </Button>
                                                <UncontrolledTooltip
                                                    target="tooltip-add">{Translator.trans('mf.pdp.form.firePermit.add')}</UncontrolledTooltip>
                                            </>
                                            :
                                            ''
                                    }

                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {listingResults}
                            </tbody>
                        </Table>
                        <CardFooter>
                            <Row>
                                <Col md={3} sm={12}>
                                    {
                                        this.state.results.length > 0 ? (
                                                <div className="datatable-info">
                                                    {this.state.resultsOffsetPage + 1} - {this.state.resultsOffsetPage + this.state.results.length} {Translator.trans('app.pagination.on')} {this.state.nbTotalResults} {Translator.trans('app.pagination.result')}
                                                </div>
                                            ) :
                                            (
                                                <div className="datatable-info">
                                                    0 -
                                                    0 {Translator.trans('app.pagination.on')} 0 {Translator.trans('app.pagination.result')}
                                                </div>
                                            )
                                    }
                                </Col>
                                <Col md={6} sm={12}>
                                    <ReactPaginate
                                        breakClassName="page-item"
                                        breakLabel={<span className="page-link">...</span>}
                                        pageClassName="page-item"
                                        previousLabel={Translator.trans('app.pagination.previous')}
                                        previousClassName="page-item"
                                        nextClassName="page-item"
                                        nextLabel={Translator.trans('app.pagination.next')}
                                        pageLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        pageCount={this.state.nbTotalResultsPage}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.handleClickPages.bind(this)}
                                        containerClassName={'pagination'}
                                        activeClassName={'active'}
                                    />
                                </Col>
                                <Col md={3} sm={12}>
                                    <Input type="select" className="float-right"
                                           onChange={this.handleSelectNbItem.bind(this)}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </Input>
                                </Col>
                            </Row>
                        </CardFooter>
                    </Card>
                </Col>
                {firePermitModal}
                <Col id="map" xl="6">
                    <Card style={{height: 500 + 'px'}}>
                        <Map center={[200, this.state.firePermit.plan.width / 2]} zoom={0} maxZoom={5}
                             doubleClickZoom={false}
                             crs={CRS.Simple}>
                            {currentLeafletZones}
                            <LayersControl position='topleft'>
                                <Overlay key={this.state.firePermit.id} checked
                                         name={"Plan " + this.state.firePermit.plan.name}>
                                    <ImageOverlay
                                        id={1}
                                        bounds={[[0, 0], [400, this.state.firePermit.plan.width]]}
                                        url={this.state.firePermit.plan.path}
                                    />
                                </Overlay>
                            </LayersControl>
                        </Map>
                    </Card>
                </Col>
            </Row>
        )
    }
}

export default connect(state => state.formReducer)(FirePermit);
