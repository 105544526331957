import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import SaveToDashboard from "../../save-to-dashboard";

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/js/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
import Routing
    from "../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const routes = require("../../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class AtFrequency extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartOption: []
        };

        this.getData = this.getData.bind(this);
    }

    async getData() {
        let url = Routing.generate('rh_at_frequency');

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        let options = {
            chart: {
                type: 'solidgauge',
                margin: [0, 0, 0, 0],
            },
            tooltip: {
                enabled: false
            },
            title: {
                text: ''
            },
            pane: {
                center: ['50%', '70%'],
                size: '100%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    backgroundColor: '#000000',
                    innerRadius: '75%',
                    outerRadius: '100%',
                    shape: 'arc'
                }
            },
            plotOptions: {
                solidgauge: {
                    innerRadius: '75%',
                    radius: '100%',
                    dataLabels: {
                        y: -80,
                        borderWidth: 0,
                        useHTML: true,
                        zIndex: 1000 //added
                    }
                }
            },
            yAxis: {
                labels: {
                    enabled: false
                },
                min: 0,
                max: 1,
                minColor: '#00bc4c',
                maxColor: '#00bc4c',
                lineColor: 'transparent',
                minorTickLength: 0,
                tickPositions: [0],
                tickColor: '#000000',
                tickPosition: 'inside',
                title: {
                    text: 'Objectif : ' + 0
                },
                tickLength: 50,
                tickWidth: 8,
                zIndex: 100, //added zindex
            },
            series: [{
                name: '',
                data: [0],
                dataLabels: {
                    borderRadius: 0,
                    backgroundColor: "#fff",
                    borderWidth: 0,
                    borderColor: "#FFF",
                    style: {
                        fontSize: "50px"
                    },
                    color: "grey",
                    crop: true,
                    formatter: function () {
                        var s;
                        s = '<span style="font-size: 50px;">' + this.point.y + '</span>';
                        return s;
                    },

                }
            }]
        };

        if (response.status === 200) {
            let data = await response.json();

            options.yAxis.max = data.informations.max;
            options.yAxis.minColor = data.informations.color;
            options.yAxis.maxColor = data.informations.color;
            options.yAxis.tickPositions = [data.informations.objectif];
            options.yAxis.title.text = 'Objectif : ' + data.informations.objectif;
            options.series[0].data = [data.informations.frequency];

            this.setState({chartOption: options});
        }

    }


    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-header ">
                    <h4>
                        Taux de fréquence
                        <SaveToDashboard
                            component="dashboard-at-frequency"
                            size="6"
                            controller=""
                            script=""
                            title="RH - Taux de fréquence"
                        />
                    </h4>
                </div>
                <div className="card-body">
                    <HighchartsReact highcharts={Highcharts} options={this.state.chartOption}/>
                </div>
            </div>
        );
    }
}
