import React, {Component} from 'react';
import Translator from 'bazinga-translator';
import ParserHTML from "../../parser";

import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader
} from 'reactstrap'


export default class TiersCard extends Component {
    constructor(props) {
        super(props);

        this.state = this.props.tiersData;
    }

    render() {
        return (
            <CardBody>
                <p>
                    <strong>{Translator.trans('mf.pdp.form.tiers.name')}</strong> {this.state.tiers.name}
                </p>
                <Row>
                    <Col md={6}>
                        <Card>
                            <CardHeader className="bg-light text-dark">
                                {Translator.trans("mf.pdp.form.intervention.tiersAddress")}
                            </CardHeader>
                            <CardBody>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.address.name')} :</strong> {this.state.tiers_address.address.name}
                                </p>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.address.address')} :</strong> {this.state.tiers_address.address.address}
                                </p>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.address.city')} :</strong> {this.state.tiers_address.address.city}
                                </p>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.address.zipCode')} :</strong> {this.state.tiers_address.address.zip_code}
                                </p>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.address.country')} :</strong> {this.state.tiers_address.address.country}
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <CardHeader className="bg-light text-dark">
                                {Translator.trans("mf.pdp.form.intervention.tiersContact")}
                            </CardHeader>
                            <CardBody>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.contact.lastName')} :</strong> {this.state.tiers_contact.contact.last_name}
                                </p>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.contact.firstName')} :</strong> {this.state.tiers_contact.contact.first_name}
                                </p>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.contact.phone')} :</strong> {this.state.tiers_contact.contact.phone}
                                </p>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.contact.mobile')} :</strong> {this.state.tiers_contact.contact.mobile}
                                </p>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.contact.email')} :</strong> {this.state.tiers_contact.contact.email}
                                </p>
                                {
                                    this.state.substitutes !== undefined && this.state.substitutes.length ?
                                        <div>
                                            <strong>{Translator.trans('mf.pdp.form.tiers.substitutes')} :</strong>
                                            {
                                                this.state.substitutes.map((substitute) => {
                                                    return <div>{substitute.contact.first_name + " " +
                                                    substitute.contact.last_name}</div>
                                                })
                                            }
                                        </div>
                                        :
                                        ''
                                }

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </CardBody>
        )
    }
}