import React, {Component} from 'react';
import Translator from 'bazinga-translator';
import Routing
    from '../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

import SignaturePad from "react-signature-canvas";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledTooltip,
    FormGroup,
    Input,
    Label,
    Col
} from "reactstrap";

const routes = require('../../../../../../../js/fos_js_routes.json');

Routing.setRoutingData(routes);

export default class Signature extends Component {
    constructor(props) {
        super(props);

        this.state = {
            granted: props.granted,
            inspection: props.inspection,
            intervention: props.intervention,
            firePermit: props.firePermit,
            firePermitStep: props.firePermitStep,
            showSignatureModal: false,
            showSignaturePad: false,
            name: "",
            signatures: [],
            valid: null
        }
    }

    sigCanvas = {};

    componentDidMount() {
        this.loadSignatures();
    }

    loadSignatures = async () => {
        try {
            let response;
            if (this.state.intervention !== undefined) {
                response = await fetch(
                    Routing.generate("pdp_signature_list", {
                        id: this.state.intervention,
                        inspection: this.state.inspection
                    }),
                    {
                        method: "GET",
                        credentials: "include"
                    }
                );
            } else {
                response = await fetch(
                    Routing.generate("pdp_fire_permit_signature_list", {
                        id: this.state.firePermit,
                        firePermitStep: this.state.firePermitStep
                    }),
                    {
                        method: "GET",
                        credentials: "include"
                    }
                );
            }

            if (response.status === 200) {
                let data = await response.json();
                this.setState({signatures: JSON.parse(data)});
            }
        } catch (error) {
            console.error("Error loading signatures", error);
        }
    };

    showSignatureModal = () => {
        this.setState(
            {
                showSignatureModal: true
            }
        );
    };

    closeSignatureModal = () => {
        this.setState(
            {
                showSignatureModal: false
            }
        );
    };

    showSignaturePad = () => {
        this.setState(
            {
                showSignaturePad: true
            }
        );
    };

    closeSignaturePad = () => {
        this.setState(
            {
                name: '',
                showSignaturePad: false,
                valid: null
            }
        );
    };

    clearSignature = () => {
        this.sigCanvas.clear();
    };

    saveSignature = async () => {
        let signatureImage = this.sigCanvas.getTrimmedCanvas().toDataURL("image/png");
        let name = this.state.name;
        if (name !== "") {
            let form = new FormData();
            form.append('canvas', signatureImage)
            form.append('name', name)
            form.append('inspection', this.state.inspection)
            form.append('firePermitStep', this.state.firePermitStep)

            let response
            if (this.state.intervention !== undefined) {
                response = await fetch(
                    Routing.generate("pdp_signature_add", {
                        id: this.state.intervention
                    }),
                    {
                        method: "POST",
                        body: form,
                        credentials: "include"
                    }
                );
            } else {
                response = await fetch(
                    Routing.generate("pdp_fire_permit_signature_add", {
                        id: this.state.firePermit,
                        firePermitStep: this.state.firePermitStep
                    }),
                    {
                        method: "POST",
                        body: form,
                        credentials: "include"
                    }
                );
            }

            if (response.status === 200) {
                let data = await response.json();
                this.setState(
                    {signatures: JSON.parse(data)},
                    this.closeSignaturePad);
            }

        } else {
            this.setState({valid: false})
        }
    };

    deleteSignature = async signatureId => {
        try {
            let response
            if (this.state.intervention !== undefined) {
                response = await fetch(
                    Routing.generate("pdp_signature_delete", {
                        intervention: this.state.intervention,
                        id: signatureId
                    }),
                    {
                        method: "GET",
                        credentials: "include"
                    }
                );
            } else {
                response = await fetch(
                    Routing.generate("pdp_fire_permit_signature_delete", {
                        id: signatureId,
                        firePermit: this.state.firePermit,
                        firePermitStep: this.state.firePermitStep
                    }),
                    {
                        method: "GET",
                        credentials: "include"
                    }
                );
            }

            if (response.status === 200) {
                let data = await response.json();
                this.setState({signatures: JSON.parse(data)});
            }
        } catch (error) {
            console.error("Error on signature delete");
        }
    };

    render() {
        let signaturePad = (
            <Modal isOpen={this.state.showSignaturePad} toggle={this.closeSignaturePad}>
                <ModalHeader toggle={this.closeSignaturePad}>
                    {Translator.trans('mf.pdp.form.intervention.signature_add')}
                </ModalHeader>
                <ModalBody>
                    <SignaturePad
                        ref={(ref) => {
                            this.sigCanvas = ref
                        }}
                        canvasProps={{
                            className: "signature-canvas"
                        }}
                    />
                    <FormGroup row>
                        <Label for="name" sm={2}>Nom</Label>
                        <Col sm={10}>
                            <Input value={this.state.name}
                                   invalid={this.state.valid !== null && !this.state.valid}
                                   onChange={e => this.setState({name: e.target.value})}
                                   type="text" name="name" id="name"/>
                        </Col>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary"
                            onClick={this.closeSignaturePad}>{Translator.trans('app.form.cancel')}
                    </Button>
                    <Button color="primary"
                            onClick={this.clearSignature}>{Translator.trans('app.form.clear')}
                    </Button>
                    <Button color="secondary"
                            onClick={this.saveSignature}>{Translator.trans('app.form.submit')}
                    </Button>
                </ModalFooter>
            </Modal>
        );

        let signatureContent = (
            this.props.label !== undefined ?
                this.state.signatures.length ?
                    this.state.signatures.map((signature) => {
                        let date = moment(signature.date);
                        return (
                            <div className="col-md-3" key={signature.id}>
                                <figure className="figure text-center">
                                    <img src={signature.canvas}
                                         style={{maxHeight: 70 + 'px'}}
                                         className="figure-img img-fluid" alt={signature.name}/>
                                    {this.state.granted ?
                                        <button onClick={this.deleteSignature.bind(this, signature.id)}
                                                className="pt-0 float-right btn btn-link text-secondary">
                                            <i className="fas fa-times"></i>
                                        </button>
                                        :
                                        ""
                                    }
                                    <figcaption className="figure-caption">
                                        {signature.name} ({date.format('DD/MM/YYYY HH') + "h" + date.format('mm')})
                                    </figcaption>
                                </figure>
                            </div>
                        );
                    })
                    :
                    ""
                :
                <Modal size={'lg'} isOpen={this.state.showSignatureModal} toggle={this.closeSignatureModal}>
                    <ModalHeader toggle={this.closeSignatureModal}>
                        {Translator.trans('mf.pdp.form.intervention.signatures')}
                    </ModalHeader>
                    <ModalBody className="row">
                        {
                            this.state.signatures.length ?
                                this.state.signatures.map((signature) => {
                                    let date = moment(signature.date);
                                    return (
                                        <div className="col-md-3" key={signature.id}>
                                            <figure className="figure text-center">
                                                <img src={signature.canvas}
                                                     style={{maxHeight: 70 + 'px'}}
                                                     className="figure-img img-fluid" alt={signature.name}/>
                                                {this.state.granted ?
                                                    <button onClick={this.deleteSignature.bind(this, signature.id)}
                                                            className="pt-0 float-right btn btn-link text-secondary">
                                                        <i className="fas fa-times"></i>
                                                    </button>
                                                    :
                                                    ""
                                                }
                                                <figcaption className="figure-caption">
                                                    {signature.name} ({date.format('DD/MM/YYYY HH') + "h" + date.format('mm')})
                                                </figcaption>
                                            </figure>
                                        </div>
                                    );
                                })
                                :
                                ""
                        }
                    </ModalBody>
                    {
                        this.state.granted ?
                            <ModalFooter className="text-center">
                                <Button color="secondary" onClick={this.showSignaturePad}>
                                    {Translator.trans('app.action.add')}
                                </Button>
                            </ModalFooter>
                            :
                            ''
                    }
                </Modal>
        );

        return (
            this.props.label !== undefined ?
                <>
                    {signatureContent}
                    <div className="text-center col-md-12">
                        <Button color="secondary" onClick={this.showSignaturePad}>
                            {Translator.trans('app.action.add')}
                        </Button>
                    </div>
                    {signaturePad}
                </>
                :
                this.state.inspection ?
                    <>
                        <Button color="secondary" onClick={this.showSignatureModal}>
                            {Translator.trans('mf.pdp.form.intervention.signatures')}
                            <i className="fas fa-file-signature fa-fw"></i>
                        </Button>
                        {signatureContent}
                        {signaturePad}
                    </>
                    :
                    <>
                        <Button id="tooltip-signature" color="link" className="btn-actions"
                                onClick={this.showSignatureModal}>
                            <i className="fas fa-file-signature fa-fw"></i>
                        </Button>
                        <UncontrolledTooltip
                            target={"tooltip-signature"}>{Translator.trans('mf.pdp.layout.pdp.btn.sign')}</UncontrolledTooltip>
                        {signatureContent}
                        {signaturePad}
                    </>
        )
            ;
    }
}
