import React, {Component} from "react";
import {connect} from "react-redux";

import Routing
    from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const routes = require("../../../../../../../js/fos_js_routes.json");

/**
 * Basic component for0 autocomplete field
 */
class AutocompleteField extends Component {
    constructor(props) {
        super(props);

        let latest = this.props["data-target"].split("_");
        let field = latest[latest.length - 1];

        this.state = {
            value: this.props.value === undefined ? "" : this.props.value,
            field: field
        };
    }

    componentDidMount() {
        let onChangeAutocomplete = (value, id) => {
            this.setState(
                {
                    value: value
                },
                () => {
                    let setterReducer = "set" + this.state.field.charAt(0).toUpperCase() + this.state.field.slice(1);
                    this.props.dispatch({type: setterReducer, value: id, autocompleteTiers: true});
                }
            );
        };

        $(".autocomplete").each(function () {
            var field = $(this);
            var _id = field.data("target");
            var list = $("#" + _id + "_list");
            var originValue = field.val();

            if (false == field.data("required")) {
                field.removeAttr("required");
            }

            list.on(
                {
                    "click.item-autocomplete": function (e) {
                        $(this)
                            .closest("li")
                            .fadeOut(300, function () {
                                $(this).remove();
                            });
                        e.preventDefault();
                        e.stopPropagation();
                    }
                },
                "a"
            );

            field.on("change", function (e) {
                if ("" == field.val()) {
                    $("#" + _id).val("");
                }
            });

            field.autocomplete({
                deferRequestBy: field.data("delay"),
                lookupLimit: field.data("max-items"),
                minChars: field.data("min-chars"),
                serviceUrl: field.data("url"),
                onSelect: function (suggestion) {
                    if (false == field.data("multiple")) {
                        $("#" + field.data("target")).val(suggestion.data);
                        originValue = suggestion.value;
                        onChangeAutocomplete(suggestion.value, suggestion.data);
                    } else {
                        var formName = field.closest("form").prop("name");

                        if (list.length) {
                            var li = list.find("#" + _id + "_" + suggestion.data);

                            if (!li.length) {
                                var name = (formName ? _id.replace(formName + "_", "") : _id).split("_");

                                if (formName) {
                                    name.unshift(formName);
                                }

                                name = (name.length > 1 ? name.shift() + "[" + name.join("][") + "]" : name.join()) + "[]";

                                li = $($("#" + _id).data("prototype"));
                                li.data("value", suggestion.data)
                                    .find("input:hidden")
                                    .val(suggestion.data)
                                    .attr("id", _id + "_" + suggestion.data)
                                    .attr("name", name)
                                    .end()
                                    .find(".autocomplete-item")
                                    .text(suggestion.value)
                                    .end()
                                    .appendTo(list);
                            }
                        }
                    }
                }
            });
        });
    }

    onChange = (e) => {
        let targetValue = e.target.value;
        this.setState({
            value: targetValue
        })
    };

    render() {
        return (
            <input
                type={this.props.type}
                id={this.props.id}
                name={this.props.name}
                required={this.props["data-required"] == "true" ? "required" : ""}
                autoComplete={this.props.autoComplete}
                className={this.props.class}
                data-delay={this.props["data-delay"]}
                data-max-items={this.props["data-max-items"]}
                data-min-chars={this.props["data-min-chars"]}
                data-multiple={this.props["data-multiple"]}
                data-origin-value={this.props["data-origin-value"]}
                data-property={this.props["data-property"]}
                data-render={this.props["data-render"]}
                data-required={this.props["data-required"]}
                data-route={this.props["data-route"]}
                data-route-params={this.props["data-route-params"]}
                data-target={this.props["data-target"]}
                data-url={this.props["data-url"]}
                data-on-select-callback={this.props["data-on-select-callback"]}
                data-redirectroute={this.props["data-redirectroute"]}
                value={this.state.value}
                placeholder={this.props.placeholder}
                readOnly={false}
                disabled={false}
                style={this.props.style}
                onChange={this.onChange.bind(this)}
            />
        );
    }
}

export default connect(state => state.formReducer)(AutocompleteField);

