import React, {Component} from 'react';
import Routing
    from '../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
import SortableTree, {toggleExpandedForAll} from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-minimal';
import {Button, Card, CardBody, CardHeader, Col, UncontrolledTooltip} from 'reactstrap'
import Translator from "bazinga-translator";

const routes = require('../../../../../../../js/fos_js_routes.json');

Routing.setRoutingData(routes);


export default class HierarchyTree extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchString: '',
            searchFocusIndex: 0,
            searchFoundCount: null,
            treeData: [],
            hierarchy_item: null
        };

        this.updateTreeData = this.updateTreeData.bind(this);
        this.expandAll = this.expandAll.bind(this);
        this.collapseAll = this.collapseAll.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let url = Routing.generate('hierarchy_item_list_all');
        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });
        if (response.status === 200) {
            let data = await response.json();

            this.setState({
                treeData: data,
            }, () => {
                this.expandAll();
            });
        }
    }

    getHierarchyItem = async id => {
        let url = Routing.generate('hierarchy_item_get', {'id': id});
        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });
        if (response.status === 200) {
            let data = await response.json();

            this.setState({
                hierarchy_item: JSON.parse(data),
            });
        }
    }

    updateTreeData(treeData) {
        this.setState({treeData});
    }

    expand(expanded) {
        this.setState({
            treeData: toggleExpandedForAll({
                treeData: this.state.treeData,
                expanded,
            }),
        });
    }

    expandAll() {
        this.expand(true);
    }

    collapseAll() {
        this.expand(false);
    }

    render() {
        const {
            treeData,
            searchString,
            searchFocusIndex,
            searchFoundCount,
        } = this.state;
        const selectPrevMatch = () =>
            this.setState({
                searchFocusIndex:
                    searchFocusIndex !== null
                        ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
                        : searchFoundCount - 1,
            });

        const selectNextMatch = () =>
            this.setState({
                searchFocusIndex:
                    searchFocusIndex !== null
                        ? (searchFocusIndex + 1) % searchFoundCount
                        : 0,
            });

        return (
            <Card>
                <CardHeader>
                    <Button className="mr-1" onClick={this.expandAll}>Tout ouvrir</Button>
                    <Button className="mr-1" onClick={this.collapseAll}>Tout fermer</Button>
                    <label htmlFor="find-box">
                        Recherche:&nbsp;
                        <input
                            id="find-box"
                            type="text"
                            value={searchString}
                            onChange={event =>
                                this.setState({searchString: event.target.value})
                            }
                        />
                    </label>
                    <Button className="ml-1" disabled={!searchFoundCount} onClick={selectPrevMatch}>
                        <i className="fas fa-angle-left"/>
                    </Button>
                    <Button disabled={!searchFoundCount} onClick={selectNextMatch}>
                        <i className="fas fa-angle-right"/>
                    </Button>
                    <span>
                        &nbsp;
                        {searchFoundCount > 0 ? searchFocusIndex + 1 : 0}
                        &nbsp;/&nbsp;
                        {searchFoundCount || 0}
                    </span>
                </CardHeader>
                <CardBody className="row">
                    <Col md={6} style={{height: '500px'}}>
                        <SortableTree
                            canDrag={false}
                            treeData={treeData}
                            theme={FileExplorerTheme}
                            onChange={this.updateTreeData}
                            searchQuery={searchString}
                            searchFocusOffset={searchFocusIndex}
                            rowHeight={45}
                            searchFinishCallback={matches =>
                                this.setState({
                                    searchFoundCount: matches.length,
                                    searchFocusIndex:
                                        matches.length > 0 ? searchFocusIndex % matches.length : 0,
                                })
                            }
                            getNodeKey={({node}) => node.id}
                            generateNodeProps={rowInfo => ({
                                buttons: [
                                    <Button onClick={this.getHierarchyItem.bind(this, rowInfo.node.id)}>
                                        <i className="fas fa-eye"></i>
                                    </Button>,
                                ],
                            })}
                        />
                    </Col>
                    <Col md={6}>
                        {
                            this.state.hierarchy_item ?
                                <Card>
                                    <CardHeader>
                                        <a href={Routing.generate('hierarchy_item_edit', {'id': this.state.hierarchy_item.id})}
                                           className="tools"
                                           id={"hierarchy-edit" + this.state.hierarchy_item.id}>
                                            <i className="fas fa-fw fa-pencil-alt"></i>
                                        </a>
                                        <UncontrolledTooltip
                                            target={"hierarchy-edit" + this.state.hierarchy_item.id}>{Translator.trans('mf.hierarchy_item.list.updateAction')}</UncontrolledTooltip>
                                        <a href={Routing.generate('hierarchy_item_delete', {'id': this.state.hierarchy_item.id})}
                                           className="tools"
                                           id={"hierarchy-delete" + this.state.hierarchy_item.id}>
                                            <i className="fas fa-fw fa-times"></i>
                                        </a>
                                        <UncontrolledTooltip
                                            target={"hierarchy-delete" + this.state.hierarchy_item.id}>{Translator.trans('mf.hierarchy_item.list.deleteAction')}</UncontrolledTooltip>
                                        {this.state.hierarchy_item.name}
                                    </CardHeader>
                                    <CardBody>
                                        <div className="form-group">
                                            <strong className="col-md-4">
                                                Type
                                            </strong>
                                            <p className="col-md-8">
                                                {this.state.hierarchy_item.hierarchy_type.name}
                                            </p>
                                        </div>
                                        {
                                            this.state.hierarchy_item.count_children_by_type ?
                                                this.state.hierarchy_item.count_children_by_type.map((child) => {
                                                    return (
                                                        <div className="form-group">
                                                            <strong className="col-md-4">
                                                                Nombre
                                                                de {child.name}s
                                                            </strong>
                                                            <p className="col-md-8">
                                                                {child.count}
                                                            </p>
                                                        </div>
                                                    )
                                                })
                                                :
                                                ''
                                        }
                                    </CardBody>
                                </Card>
                                :
                                ''
                        }
                    </Col>
                </CardBody>
            </Card>
        )
    }
}