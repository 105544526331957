import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import SaveToDashboard from "../../save-to-dashboard";
import Translator from 'bazinga-translator';

require('highcharts/modules/exporting')(Highcharts);
import Routing
    from "../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const routes = require("../../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class FormationTime extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            service: 0,
            services: [],
            chartOption: []
        }
    }

    async getData() {

        let params = {
            service: this.state.service
        };

        let url = Routing.generate('rh_formation_time');
        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        let option = {
            chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: ['Salariés']
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Nombre d\'Heures'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
                    }
                }
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: '',
                    dataLabels: {
                        enabled: true,
                        color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
                    }
                }
            },
            series: []
        };

        if (response.status === 200) {
            let data = await response.json();

            option.series = data.informations.employees;
            this.setState({
                chartOption: option,
                services: data.services,
            });
        }
    }

    handleClickReset() {
        this.setState({
            service: 0
        }, () => {
            this.getData();
        });

    }

    handleSelectService(event) {
        let service = event.target.value;

        this.setState({
            service: service
        }, () => {
            this.getData();
        });
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-header">
                    <h4>
                        {Translator.trans('eobs.dashboard.rh.indicator.formation_time.title')}
                        <SaveToDashboard
                            component="dashboard-rh-formation-time"
                            size="12"
                            controller=""
                            script=""
                            title={'RH - '+Translator.trans('eobs.dashboard.rh.indicator.formation_time.title')}
                        />
                    </h4>
                </div>
                <div className="card-body">
                    <form>
                        <div className="form-group row">
                            <label className="col-md-2 col-form-label col-form-label-sm" htmlFor="form-list-service">
                                {Translator.trans('eobs.dashboard.rh.indicator.formation_time.service')}
                            </label>
                            <div className="col-md-2">
                                <select value={this.state.service} id="form-list-service"
                                        onChange={(event) => this.handleSelectService(event)}
                                        className="form-control form-control-sm">
                                    {
                                        this.state.services.map((service, index) => {
                                            return (<option key={index} value={service.id}>{service.name}</option>)
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-md-2 center">
                                <input className="btn btn-sm btn-default" type="reset" value={Translator.trans('eobs.dashboard.reset')} id="reset"
                                       name="reset" onClick={() => this.handleClickReset()}/>
                            </div>
                        </div>
                    </form>
                    <HighchartsReact highcharts={Highcharts} options={this.state.chartOption}/>
                </div>
            </div>
        );
    }
}