import React, {Component} from 'react';
import Translator from 'bazinga-translator';

import Routing from "../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import {UncontrolledTooltip} from "reactstrap";

const routes = require("../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class SaveToDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {saved: true};

        this.handleClick = this.handleClick.bind(this);
    }

    async saveIndicator() {
        let params = {
            component: this.props.component,
            script: this.props.script,
            controller: this.props.controller,
            size: this.props.size,
            title: this.props.title,
        };

        let url = Routing.generate('save_indicator_ajax');

        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        if (response.status == 200) {
            let data = await response.json();

            App.modalAlert('L\'indicateur à bien été ajouté', '');

            this.setState({
                saved: true
            });
        }
    }

    async getSavedIndicator() {
        let params = {
            component: this.props.component,
            controller: this.props.controller,
        };

        let url = Routing.generate('find_saved_indicator_ajax');

        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        if (response.status == 200) {
            let data = await response.json();

            if (!data.exist) {
                this.setState({
                    saved: false
                });
            }
        }
    }

    handleClick() {
        if (!this.state.saved) {
            this.saveIndicator()
        }
    }

    componentDidMount() {
        this.getSavedIndicator();
    }

    render() {
        if (!this.state.saved) {
            return (
                <span className='tools' id='save-to-dashboard' onClick={() => this.handleClick()}>
                    <span className="icon fas fa-plus"></span>
                      <UncontrolledTooltip
                          target={"save-to-dashboard"}>{Translator.trans('eobs.dashboard.saveToDashboard')}</UncontrolledTooltip>
                </span>
            );
        } else {
            return ('');
        }
    }
}