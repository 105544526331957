import React, {Component} from "react";
import {connect} from "react-redux";
import Translator from "bazinga-translator";

class WorkorderDate extends Component {
    updateDate(date) {
        this.props.dispatch({type: "setWorkorderDate", value: date});
    }

    navigateDate(value) {
        this.updateDate(moment(this.props.workorderDate, "DD/MM/YYYY").add(value, "days").format("DD/MM/YYYY"), value);
    }

    onSelectDate = (event) => {
        this.updateDate(event.target.value);
    };

    render() {
        return (
            <div className="form-group col-md-6 offset-3 mt-3">
                <div className="input-group">
                    <div className="input-group-prepend">
                        <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={this.navigateDate.bind(this, -1)}
                            title={Translator.trans("app.pagination.previous")}
                        >
                            <i className="fas fa-arrow-left"></i>
                        </button>
                    </div>
                    <input
                        className="form-control input-date"
                        onChange={() => ""}
                        onSelect={this.onSelectDate.bind(this)}
                        type="text"
                        value={this.props.workorderDate}
                    />
                    <div className="input-group-prepend">
                        <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={this.navigateDate.bind(this, 1)}
                            title={Translator.trans("app.pagination.next")}
                        >
                            <i className="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state) => state.dashboardReducer)(WorkorderDate);
