import Routing
    from '../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

const routes = require('../../../../../../../js/fos_js_routes.json');

Routing.setRoutingData(routes);

export default class ProjectDocument {

    constructor() {
        ProjectDocument.initDocumentForm();
    }

    static initDocumentForm = () => {
        if (!$('#ajax-modal').length) {
            $('body').append("<div class='modal fade' role='dialog' id='ajax-modal'></div>")
        }

        $('.js-add-project-document').on('click', function () {
            const project = $(this).data('project');
            ProjectDocument.addDocument(project);
        });
        $('.js-add-task-document').on('click', function () {
            const task = $(this).data('task');
            ProjectDocument.addTaskDocument(task);
        });
        $('.js-edit-project-document').on('click', function () {
            const document = $(this).data('project-document');
            ProjectDocument.editDocument(document);
        });
        $('.js-delete-project-document').on('click', function () {
            const document = $(this).data('project-document');
            App.modalConfirmDelete(function () {
                ProjectDocument.removeDocument(document);
            });
        });
    };

    static addDocument = async (project) => {
        App.loading($('#ajax-modal .modal-body'));
        let url = Routing.generate('project_document_add', {id: project});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const form = await response.text();
            $('#ajax-modal').html(form);
            App.initCustomFileUpload();
            $('#ajax-modal').modal();
            ProjectDocument.handleSubmitAjax();
        } else {
            console.error('Une erreur est survenue, le tiers n\'a pas été trouvé')
        }
    };

    static addTaskDocument = async (task) => {
        App.loading($('#ajax-modal .modal-body'));
        let url = Routing.generate('project_task_document_add', {id: task});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const form = await response.text();
            $('#ajax-modal').html(form);
            App.initCustomFileUpload();
            $('#ajax-modal').modal();
            ProjectDocument.handleSubmitAjax();
        } else {
            console.error('Une erreur est survenue, la tache n\'a pas été trouvée')
        }
    };

    static editDocument = async (document) => {
        App.loading($('#ajax-modal .modal-body'));
        let url = Routing.generate('project_document_edit', {id: document});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const form = await response.text();
            $('#ajax-modal').html(form);
            App.initCustomFileUpload();
            $('#ajax-modal').modal();
            ProjectDocument.handleSubmitAjax();
        } else {
            console.error('Une erreur est survenue, le membre n\'a pas été trouvé')
        }
    };

    static removeDocument = async (document) => {
        let url = Routing.generate('project_document_remove', {id: document});

        let response = await fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            $('#project-document-' + document).remove()
        } else {
            console.error('Une erreur est survenue, le document n\'a pas été supprimé')
        }
    };


    static handleSubmitAjax = () => {
        $('form[name="document"]').on('submit', function (e) {
            e.preventDefault();
            ProjectDocument.submitForm($(this), 'document');
        });
    };

    static submitForm = async (form, name) => {
        const post_url = form.attr("action");
        const form_data = new FormData(document.forms[name]);
        App.loading($('#ajax-modal .modal-body'));
        let response = await fetch(post_url, {
            method: "POST",
            credentials: 'same-origin',
            body: form_data
        });

        if (response && response.status == 201 || response.status == 200) {
            if (response.status == 201) {
                let data = await response.json()
                window.location.reload();
            } else if (response.status == 200) {
                const form = await response.text();
                $('#ajax-modal').html(form);
                App.initCustomFileUpload();
                ProjectDocument.handleSubmitAjax();
            }
        } else {
            console.error('Une erreur est survenue, le projet n\'a pas été trouvé')
        }
    }
}
