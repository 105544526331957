import React from 'react';
import Routing
    from "../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Highcharts from "highcharts";
import SaveToDashboard from "../../save-to-dashboard";
import HighchartsReact from "highcharts-react-official";
import Translator from "bazinga-translator";

require('highcharts/modules/exporting')(Highcharts);

const routes = require("../../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class PdpValidityStatus extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            chartOption: [],
            services: [],
            service: 0
        };

        this.getData = this.getData.bind(this);
    }

    getData = async () => {

        let option = {
            chart: {
                type: "pie"
            },
            title: {
                text: ""
            },
            series: [
                {
                    name: Translator.trans("mf.pdp.indicator.validity_status.series_name"),
                    data: [
                        {
                            'name': Translator.trans("mf.pdp.indicator.validity_status.up_to_date"),
                            'y': 0,
                            'color': "#A9CF3D"
                        },
                        {
                            'name': Translator.trans("mf.pdp.indicator.validity_status.expiring"),
                            'y': 0,
                            'color': "#EEB85F"
                        },
                        {
                            'name': Translator.trans("mf.pdp.indicator.validity_status.late"),
                            'y': 0,
                            'color': "#D23B44"
                        }
                    ],
                    dataLabels: {
                        format: '{point.percentage:.2f} %'
                    },
                }],
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    showInLegend: true
                }
            },
            legend: {
                labelFormat: '{name} ({y})'
            },
            yAxis: {
                title: {
                    text: Translator.trans("mf.pdp.indicator.validity_status.y_label")
                }
            },
        };

        let url = Routing.generate('pdp_dashboard_validity_status');

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        if (response.status == 200) {
            let data = await response.json();

            option.series[0].data[0]['y'] = data.up_to_date;
            option.series[0].data[1]['y'] = data.expiring;
            option.series[0].data[2]['y'] = data.late;

            this.setState({
                chartOption: option,
            });
        }
    };

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-header ">
                    <h4>
                        {Translator.trans("mf.pdp.indicator.validity_status.title")}
                        <SaveToDashboard
                            component="dashboard-pdp-validity-status"
                            size="6"
                            controller=""
                            script=""
                            title={"PDP - " + Translator.trans("mf.pdp.indicator.validity_status.title")}
                        />
                    </h4>
                </div>
                <div className="card-body">
                    <HighchartsReact highcharts={Highcharts} options={this.state.chartOption}/>
                </div>
            </div>
        );
    }
}