import React from 'react';
import Routing
    from "../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import SaveToDashboard from "../../save-to-dashboard";
import Translator from "bazinga-translator";
import {Bar} from 'react-chartjs-2'

const routes = require("../../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class PdpArchived extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            chartOption: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                scales: {
                    xAxes: [{
                        stacked: true
                    }],
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            },
            data: {}
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    async getData() {
        let url = Routing.generate('pdp_dashboard_archived_count');

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        if (response.status == 200) {
            let data = await response.json();
            this.setState({
                data: {
                    labels: data.years,
                    datasets: data.data
                },
            });
        }
    }

    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-header ">
                    <h4>
                        {Translator.trans("mf.pdp.indicator.archived")}
                        <SaveToDashboard
                            component="dashboard-pdp-archived"
                            size="6"
                            controller=""
                            script=""
                            title={"PDP - " + Translator.trans("mf.pdp.indicator.archived")}
                        />
                    </h4>
                </div>
                <div className="card-body">
                    <Bar
                        data={this.state.data}
                        options={this.state.chartOption}
                    />
                </div>
            </div>
        );
    }
}