import Routing
    from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Translator from 'bazinga-translator';
import bsCustomFileInput from "bs-custom-file-input";

const routes = require("../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class UserRoleForm {

    constructor() {
        $('.js-user-role').on('click', function () {
            const user = $(this).data('user');
            UserRoleForm.showRoleList(user);
        });
        $('body').append("<div class=\"modal fade\" id=\"user-role-list-modal\" tabindex=\"-1\" role=\"dialog\"></div>");
    };

    static addRoleForm = ($collectionHolder, $newLinkLi) => {
        const prototype = $collectionHolder.data('prototype');
        const index = $collectionHolder.data('index');
        let newForm = prototype;
        newForm = newForm.replace(/__name__/g, index);
        $collectionHolder.data('index', index + 1);
        const $newFormLi = $('<li></li>').html("<fieldset><legend>" + Translator.trans('eobs.user.admin.user.form.role.new') + "</legend>" + newForm + "</fieldset>");
        $newLinkLi.before($newFormLi);
        bsCustomFileInput.init();
        App.initSelectPicker();
        App.initDatePicker();
        UserRoleForm.addRoleFormDeleteLink($newFormLi);
    };

    static initRoleForm = ($collectionHolder) => {
        const $addRoleButton = $('<button type="button" class="btn btn-outline-secondary add_role_link">' + Translator.trans('eobs.user.admin.user.form.role.add') + '</button>');
        const $newLinkLi = $('<ul class="text-center"></ul>').append($addRoleButton);

        $collectionHolder.append($newLinkLi);

        $addRoleButton.on('click', function (e) {
            UserRoleForm.addRoleForm($collectionHolder, $newLinkLi);
        });
        $collectionHolder.find('li').each(function () {
            UserRoleForm.addRoleFormDeleteLink($(this));
        });

        $('#user-role-form-modal').on('show.bs.modal', function () {
            if (!$collectionHolder.children('li').length) {
                UserRoleForm.addRoleForm($collectionHolder, $newLinkLi);
            }
        })

        $('.js-user-role-delete').on('click', function () {
            const role = $(this).data('role');
            const user = $(this).data('user');
            App.modalConfirmDelete(function () {
                UserRoleForm.deleteRole(user, role);
            })
        });

        $('.js-user-role-edit').on('click', function () {
            const role = $(this).data('role');
            const user = $(this).data('user');
            UserRoleForm.editRole(user, role);
        });
        UserRoleForm.handleSubmitAjax();
    };

    static addRoleFormDeleteLink = ($roleFormLi) => {
        var $removeFormButton = $('<button type="button" class="btn btn-link" title="' + Translator.trans('eobs.user.admin.user.form.role.remove') + '"><i class="text-danger fas fa-times"></i></button>');
        $roleFormLi.find('legend').append($removeFormButton);

        $removeFormButton.on('click', function (e) {
            $roleFormLi.remove();
        });
    };

    static editRole = async (user, role) => {
        App.loading($('#user-role-list-modal .modal-body'));
        let url = Routing.generate('user_role_edit', {
            id: user,
            idRole: role,
        });

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const roleForm = await response.text();

            $('#user-role-list-modal').html(roleForm);
            $('#user-role-list-modal').modal()

            UserRoleForm.handleSubmitAjax();
            App.initTooltip();
            bsCustomFileInput.init();
            App.initSelectPicker();
            App.initDatePicker();
        } else {
            console.error('Une erreur est survenue, le role n\'a pas été trouvé')
        }
    };

    static deleteRole = async (user, role) => {
        let url = Routing.generate('user_role_delete', {
            id: user,
            idRole: role
        });

        let response = await fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response.status == 200) {
            $('#user-role-' + role).remove()
        } else {
            console.error('Une erreur est survenue, le role n\'a pas été supprimé')
        }
    };

    static showRoleList = async (user) => {
        let url = Routing.generate('user_role_show', {
            id: user,
        });

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response.status == 200) {
            const modal = await response.text();
            $('#user-role-list-modal').html(modal)
            UserRoleForm.initRoleForm($('.user-roles'))
            $('#user-role-list-modal').modal();
        } else {
            console.error('Une erreur est survenue, l\'utilisateur n\'a pas été trouvé')
        }
    };

    static handleSubmitAjax = () => {
        $('form[name="user_role"]').on('submit', function (e) {
            e.preventDefault();
            UserRoleForm.submitForm($(this), 'user_role');
        });
        $('form[name="hierarchy_role_edit"]').on('submit', function (e) {
            e.preventDefault();
            UserRoleForm.submitForm($(this), 'hierarchy_role_edit');
        })
    };

    static submitForm = async (form, name) => {
        const post_url = form.attr("action");
        const form_data = new FormData(document.forms[name]);
        App.loading($('#user-role-list-modal .modal-body'));
        let response = await fetch(post_url, {
            method: "POST",
            credentials: 'same-origin',
            body: form_data
        });

        if (response && response.status == 201) {
            let data = await response.json();
            UserRoleForm.showRoleList(data.user)
        } else {
            console.error('Une erreur est survenue, l\'utilisateur n\'a pas été trouvé')
        }
    }
}