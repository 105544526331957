import Routing
    from '../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
import ContactDocumentForm from "../Tiers/ContactDocumentForm";

const routes = require('../../../../../../../js/fos_js_routes.json');

Routing.setRoutingData(routes);

export default class StaffInvolved {
    constructor() {
        StaffInvolved.initMemberForm();
    }

    static initMemberForm = () => {
        $('.js-add-staff-member').on('click', function () {
            const interventionTiers = $(this).data('intervention-tiers');
            StaffInvolved.addMember(interventionTiers);
        });
        $('.js-add-external-staff-member').on('click', function () {
            const intervention = $(this).data('intervention');
            StaffInvolved.addExternalMember(intervention);
        });
        $('.js-delete-staff-member').on('click', function () {
            const interventionTiers = $(this).data('intervention-tiers');
            const tiersContact = $(this).data('tiers-contact');
            App.modalConfirmDelete(function () {
                StaffInvolved.removeMember(interventionTiers, tiersContact);
            });
        });
        $('.js-delete-external-staff-member').on('click', function () {
            const intervention = $(this).data('intervention');
            const user = $(this).data('user');
            App.modalConfirmDelete(function () {
                StaffInvolved.removeExternalMember(intervention, user);
            });
        });
        $('.js-document-staff-member').on('click', function () {
            const tiersContact = $(this).data('tiers-contact');
            StaffInvolved.showDocuments(tiersContact);
        });
    };

    static addMember = async (interventionTiers) => {
        let url = Routing.generate('pdp_tiers_staff_member_add', {id: interventionTiers});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const form = await response.text();
            $('#staff-form-modal').html(form);
            App.initSelectPicker();
            $('#staff-form-modal').modal();
            $('#create-contact-btn').on('click', function (e) {
                StaffInvolved.createMember(interventionTiers);
            });
        } else {
            console.error('Une erreur est survenue, le tiers n\'a pas été trouvé')
        }
    };

    static addExternalMember = async (intervention) => {
        let url = Routing.generate('pdp_external_staff_member_add', {id: intervention});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const form = await response.text();
            $('#staff-form-modal').html(form);
            App.initSelectPicker();
            $('#staff-form-modal').modal();
        } else {
            console.error('Une erreur est survenue, le pdp n\'a pas été trouvé')
        }
    };

    static createMember = async (interventionTiers) => {
        let url = Routing.generate('pdp_tiers_staff_member_create', {id: interventionTiers});
        App.loading($('#staff-form-modal .modal-body'));
        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const form = await response.text();
            $('#staff-form-modal').html(form);
            App.initSelectPicker();
            $('#staff-form-modal').modal("show");
            StaffInvolved.handleSubmitAjax();
        } else {
            console.error('Une erreur est survenue, le tiers n\'a pas été trouvé')
        }
    };

    static removeMember = async (interventionTiers, tiersContact) => {
        let url = Routing.generate('pdp_tiers_staff_member_remove', {id: interventionTiers, idContact: tiersContact});

        let response = await fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            $('#staff-member-' + interventionTiers + '-' + tiersContact).remove()
        } else if (response && response.status == 201) {
            let data = await response.json()
            let url = Routing.generate('pdp_show_intervention_staff', {id: data});
            window.location.replace(url)
        } else {
            console.error('Une erreur est survenue, le contact n\'a pas été supprimé')
        }
    };

    static removeExternalMember = async (intervention, user) => {
        let url = Routing.generate('pdp_external_staff_member_remove', {id: intervention, idUser: user});

        let response = await fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            $('#external-staff-member-' + user).remove()
        } else if (response && response.status == 201) {
            let data = await response.json()
            let url = Routing.generate('pdp_show_intervention_staff', {id: data});
            window.location.replace(url)
        } else {
            console.error('Une erreur est survenue, le contact n\'a pas été supprimé')
        }
    };

    static showDocuments = async (tiersContact) => {
        let url = Routing.generate('pdp_tiers_staff_member_document_show', {id: tiersContact});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const modal = await response.text();
            $('#staff-form-modal').html(modal);
            App.initTooltip();
            ContactDocumentForm.initDocumentForm($('ul.staff-documents'), 1);
            $('#staff-form-modal').modal("show");
        } else {
            console.error('Une erreur est survenue, le contact n\'a pas été trouvé')
        }
    };

    static handleSubmitAjax = () => {
        $('form[name="tiers_contact"]').on('submit', function (e) {
            e.preventDefault();
            StaffInvolved.submitForm($(this), 'tiers_contact');
        });
    };

    static submitForm = async (form, name) => {
        const post_url = form.attr("action");
        const form_data = new FormData(document.forms[name]);
        App.loading($('#staff-form-modal .modal-body'));
        let response = await fetch(post_url, {
            method: "POST",
            credentials: 'same-origin',
            body: form_data
        });

        if (response && response.status == 201 || response.status == 200) {
            if (response.status == 201) {
                let data = await response.json()
                let url = Routing.generate('pdp_show_intervention_staff', {id: data});
                window.location.replace(url)
            } else if (response.status == 200) {
                const form = await response.text();
                $('#staff-form-modal').html(form);
                App.initSelectPicker();
                StaffInvolved.handleSubmitAjax();
            }
        } else {
            console.error('Une erreur est survenue, le contact n\'a pas été trouvé')
        }
    }
}