import React from "react";
import ReactPaginate from "react-paginate";
import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import ModalNote from "./ModalNote";

import Translator from "bazinga-translator";
const translationsConfig = require("../../../../../../../js/translations/fr.json");
Translator.fromJSON(translationsConfig);

import ParserHTML from "../parser";
const parserHTML = new ParserHTML();

export default class ListNote extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            notes: [],
            formNote: null,
            loadingNotes: false,
            showModalNote: false,
            loadingNoteModal: false,
            customerId: this.props.customerId,
            notePerPage: 5,
            noteOffsetPage: 0,
            nbTotalNotePage: 5,
            noteSelectedId: null
        };
    }

    async componentDidMount() {
        this.loadNotes();
    }

    loadNotes = async () => {
        this.setState({
            loadingNotes: true
        });

        const params = {
            offset: this.state.offset,
            limit: this.state.notePerPage
        };

        let url = Routing.generate("activity_bundle_note_list");

        url = url.concat(
            "?",
            Object.entries(params)
                .map(([key, val]) => `${key}=${val}`)
                .join("&")
        );

        if (this.state.customerId !== undefined) {
            url += "?customerId=" + this.state.customerId;
        }

        try {
            const response = await fetch(url, {
                method: "GET",
                credentials: "same-origin"
            });

            if (response.status == 200) {
                const datas = await response.json();

                this.setState({
                    notes: datas.notes,
                    nbTotalNotePage: Math.ceil(datas.nbNotes / this.state.notePerPage),
                    loadingNotes: false
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    initInput = () => {
        App.initSelectPicker();
        App.initDatePicker();
    };

    /**
     * Method to init form ModalNoteForm
     *
     * @param form
     */
    initNoteForm = form => {
        this.setState(
            {
                formNote: parserHTML.parse(form),
                showModalNote: true,
                loadingFormNote: false
            },
            () => {
                this.initInput();
            }
        );
    };

    clickLoadModal = async note => {
        this.setState({
            loadingFormNote: true,
            loadingNoteModal: true
        });

        const url = Routing.generate("activity_bundle_note_edit", { id: note.id });

        try {
            const response = await fetch(url, {
                method: "GET",
                credentials: "same-origin"
            });

            if (response.status == 200) {
                const form = await response.text();

                this.setState(
                    {
                        noteSelectedId: note.id
                    },
                    () => {
                        this.initNoteForm(form);
                    }
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    closeModalNote = () => {
        this.setState({
            showModalNote: false
        });
    };

    submitNoteForm = async () => {
        this.setState({
            loadingFormNote: true
        });

        try {
            const form = document.getElementById("note-form-modal");
            const url = Routing.generate("activity_bundle_note_edit", { id: this.state.noteSelectedId });

            const response = await fetch(url, {
                method: "POST",
                body: new FormData(form),
                credentials: "same-origin"
            });

            if (response.status == 201) {
                const responseData = await response.json();

                this.loadNotes();
                this.closeModalNote();
            } else {
                const form = await response.text();

                this.initNoteForm(form);
            }
        } catch (error) {
            console.log(error);
        }
    };

    async handleClickPages(page) {
        const offset = Math.ceil(page.selected * this.state.notePerPage);

        this.setState(
            {
                noteOffsetPage: offset
            },
            () => {
                this.loadNotes();
            }
        );
    }

    render() {
        const formModalNote = (
            <Modal size="lg" isOpen={this.state.showModalNote} toggle={this.closeModalNote.bind(this)} onOpened={this.initInput.bind(this)}>
                <ModalHeader toggle={this.closeModalNote.bind(this)}>
                    Note {this.state.noteSelectedId !== null ? "#" + this.state.noteSelectedId : ""}
                </ModalHeader>
                <ModalBody>{this.state.loadingFormNote === true ? <Spinner className="primary" /> : <div>{this.state.formNote}</div>}</ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={this.submitNoteForm.bind(this)}>
                        {Translator.trans("app.form.submit")}
                    </button>
                    <button className="btn btn-secondary" onClick={this.closeModalNote.bind(this)}>
                        {Translator.trans("app.form.cancel")}
                    </button>
                </ModalFooter>
            </Modal>
        );

        const listNotes = this.state.notes.map((item, key) => {
            return (
                <tr key={key}>
                    <td>
                        {item.description !== undefined ? (
                            item.description.length > 10 ? (
                                <a data-toggle="tooltip" title={item.description}>
                                    {item.description.substring(0, 10) + "..."}
                                </a>
                            ) : (
                                item.description
                            )
                        ) : (
                            ""
                        )}
                    </td>
                    <td>{item.tiers !== undefined ? item.tiers.name : ""}</td>
                    <td>{item.dateNote}</td>
                    <td>{item.author}</td>
                    <td>
                        <div className="row">
                            <button onClick={this.clickLoadModal.bind(this, item)} className="btn btn-actions">
                                <i className="fas fa-eye"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            );
        });

        return (
            <div className="card">
                <div className="card-header">
                    {Translator.trans("activity_bundle.note.table.title")}
                    <span className="float-right">
                        <ChartLocationGenerator type="ID" component="component-list-note" title="Liste des notes" />
                        {/*<ModalNote customerId={this.state.customerId} />*/}
                    </span>
                </div>
                <div className="card-body table-responsive">
                    <table className="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th>{Translator.trans("activity_bundle.note.table.header.title")}</th>
                                <th>{Translator.trans("activity_bundle.note.table.header.tiers")}</th>
                                <th>{Translator.trans("activity_bundle.note.table.header.dateNote")}</th>
                                <th>{Translator.trans("activity_bundle.note.table.header.author")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.loadingNotes === true ? (
                                <tr>
                                    <td colSpan={4} className="text-center">
                                        <Spinner className="primary" />
                                    </td>
                                </tr>
                            ) : (
                                listNotes
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-md-8">
                            <ReactPaginate
                                breakClassName="page-item"
                                breakLabel={<span className="page-link">...</span>}
                                pageClassName="page-item"
                                previousClassName="page-item"
                                nextClassName="page-item"
                                pageLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                pageCount={this.state.nbTotalNotePage}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handleClickPages.bind(this)}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                nextLabel={Translator.trans("app.pagination.next")}
                                previousLabel={Translator.trans("app.pagination.previous")}
                            />
                        </div>
                    </div>
                </div>
                {formModalNote}
            </div>
        );
    }
}
