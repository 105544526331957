import Routing
    from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Translator from "bazinga-translator";

const routes = require("../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

const $collectionHolder = $('ul#intervention-materials');

export default class Material {

    constructor() {
        this.initForm();
    }

    initForm = () => {
        const $addWorkButton = $('<button type="button" class="offset-md-4 btn btn-outline-secondary add_work_link">' + Translator.trans('mf.pdp.form.intervention.material.new') + '</button>');
        const $newLinkLi = $('<ul></ul>').append($addWorkButton);

        $collectionHolder.append($newLinkLi);

        $addWorkButton.on('click', function (e) {
            Material.addMaterialForm($collectionHolder, $newLinkLi);
        });
        $collectionHolder.find('li').each(function () {
            Material.addDeleteLink($(this));
        });
    };

    static addMaterialForm = ($collectionHolder, $newLinkLi) => {
        const prototype = $collectionHolder.data('prototype');
        const index = $collectionHolder.data('index');
        let newForm = prototype;
        newForm = newForm.replace(/__name__/g, index);
        $collectionHolder.data('index', index + 1);
        const $newFormLi = $('<li></li>').html("<fieldset><legend>" + Translator.trans('mf.pdp.form.intervention.material.new') + "</legend>" + newForm + "</fieldset>");
        $newLinkLi.before($newFormLi);
        App.initSelectPicker();
        App.initDatePicker();
        Material.addDeleteLink($newFormLi);
    };

    static addDeleteLink = ($workFormLi) => {
        var $removeFormButton = $('<button type="button" class="btn btn-link" title="' + Translator.trans('app.action.delete') + '"><i class="text-danger fas fa-times"></i></button>');
        $workFormLi.find('legend').append($removeFormButton);

        $removeFormButton.on('click', function (e) {
            $workFormLi.remove();
        });
    };
}