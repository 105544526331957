import React, {Component} from 'react';
import Translator from 'bazinga-translator';
import ParserHTML from "../../parser";

import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader
} from 'reactstrap'

export default class HierarchyItemCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hierarchy_item: this.props.hierarchyItemData,
            ordering_customer: this.props.orderingCustomerData
        };
    }

    render() {
        return (
            <CardBody>
                <p>
                    <strong>{Translator.trans('mf.pdp.form.tiers.name')} :</strong> {this.state.hierarchy_item.company_name}
                </p>
                <Row>
                    <Col md={6}>
                        <Card>
                            <CardHeader className="bg-light text-dark">
                                {Translator.trans("mf.pdp.form.intervention.tiersAddress")}
                            </CardHeader>
                            <CardBody>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.address.name')} :</strong> {this.state.hierarchy_item.name}
                                </p>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.address.address')} :</strong> {this.state.hierarchy_item.address}
                                </p>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.address.city')} :</strong> {this.state.hierarchy_item.city}
                                </p>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.address.zipCode')} :</strong> {this.state.hierarchy_item.zip_code}
                                </p>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.address.country')} :</strong> {this.state.hierarchy_item.country}
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <CardHeader className="bg-light text-dark">
                                {Translator.trans("mf.pdp.form.intervention.tiersContact")}
                            </CardHeader>
                            <CardBody>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.contact.lastName')} :</strong> {this.state.ordering_customer.last_name}
                                </p>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.contact.firstName')} :</strong> {this.state.ordering_customer.first_name}
                                </p>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.contact.phone')} :</strong> {this.state.ordering_customer.home_phone_number}
                                </p>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.contact.mobile')} :</strong> {this.state.ordering_customer.phone_number}
                                </p>
                                <p>
                                    <strong>{Translator.trans('mf.pdp.form.tiers.contact.email')} :</strong> {this.state.ordering_customer.email}
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </CardBody>
        )
    }
}