import React from "react";
import InputField from "./form-contents/input-field";
import OptionField from "./form-contents/option-field";
import SelectField from "./form-contents/select-field";
import AutocompleteField from "./form-contents/autocomplete-field";
import TextareaField from "./form-contents/textarea-field";

var HtmlToReactParser = require("html-to-react").Parser;
var HtmlToReact = require("html-to-react");

let processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

function isDescendantTableTag(parent, node) {
    const descendants = {
        table: ['colgroup', 'thead', 'tbody'],
        colgroup: ['col'],
        thead: ['tr'],
        tbody: ['tr'],
        tr: ['th', 'td'],
    };

    if (!parent.name || !node.name) {
        return false;
    }

    return (descendants[parent.name] || []).indexOf(node.name) >= 0;
}

export default class ParserHTML {
    parserHTML = new HtmlToReactParser();
    instructions = [
        {
            shouldProcessNode: function (node) {
                return (
                    node.name === "select" ||
                    node.name === "input" ||
                    node.name === "option" ||
                    node.name === "textarea"
                );
            },
            processNode: function (node, children) {
                if (node.name === "select") {
                    return <SelectField {...node} />;
                }

                if (node.name === "textarea") {
                    return <TextareaField {...node} />;
                }

                if (node.name === "option") {
                    return (
                        <OptionField
                            value={node.attribs.value}
                            selected={node.attribs.selected}
                            children={node.children}
                        />
                    );
                }

                if (node.attribs.class != undefined) {
                    if (node.attribs.class.includes("autocomplete")) {
                        return <AutocompleteField {...node.attribs} />;
                    }
                }

                return <InputField {...node.attribs} />;
            }
        },
        {
            shouldProcessNode(node) {
                if (node.type === 'text' && node.parent) {
                    if (node.next) {
                        return isDescendantTableTag(node.parent, node.next);
                    }
                    if (node.prev) {
                        return isDescendantTableTag(node.parent, node.prev);
                    }
                }
                return false;
            },
            processNode() {
                return null;
            }
        },
        {
            shouldProcessNode: function (node) {
                return true;
            },
            processNode: processNodeDefinitions.processDefaultNode
        }
    ];

    addInstruction(instruction) {
        this.instructions.push(instruction);
    }

    isValidNode() {
        return true;
    }

    parse(html) {
        return this.parserHTML.parseWithInstructions(
            html,
            this.isValidNode,
            this.instructions
        );
    }
}
