import Routing
    from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Translator from 'bazinga-translator';
import bsCustomFileInput from "bs-custom-file-input";

const routes = require("../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class UserDocumentForm {

    constructor() {
        $('.js-user-document').on('click', function () {
            const user = $(this).data('user');
            UserDocumentForm.showDocumentList(user);
        });
        $('body').append("<div class=\"modal fade\" id=\"user-document-list-modal\" tabindex=\"-1\" role=\"dialog\"></div>");
    };

    static addDocumentForm = ($collectionHolder, $newLinkLi) => {
        const prototype = $collectionHolder.data('prototype');
        const index = $collectionHolder.data('index');
        let newForm = prototype;
        newForm = newForm.replace(/__name__/g, index);
        $collectionHolder.data('index', index + 1);
        const $newFormLi = $('<li></li>').html("<fieldset><legend>" + Translator.trans('mf.pdp.form.document.new') + "</legend>" + newForm + "</fieldset>");
        $newLinkLi.before($newFormLi);
        bsCustomFileInput.init();
        App.initSelectPicker();
        App.initDatePicker();
        UserDocumentForm.addDocumentFormDeleteLink($newFormLi);
    };

    static initDocumentForm = ($collectionHolder) => {
        const $addDocumentButton = $('<button type="button" class="btn btn-outline-secondary add_document_link">' + Translator.trans('mf.pdp.form.document.add') + '</button>');
        const $newLinkLi = $('<ul class="text-center"></ul>').append($addDocumentButton);

        $collectionHolder.append($newLinkLi);

        $addDocumentButton.on('click', function (e) {
            UserDocumentForm.addDocumentForm($collectionHolder, $newLinkLi);
        });
        $collectionHolder.find('li').each(function () {
            UserDocumentForm.addDocumentFormDeleteLink($(this));
        });

        $('#user-document-form-modal').on('show.bs.modal', function () {
            if (!$collectionHolder.children('li').length) {
                UserDocumentForm.addDocumentForm($collectionHolder, $newLinkLi);
            }
        })

        $('.js-user-document-delete').on('click', function () {
            const document = $(this).data('document');
            const user = $(this).data('user');
            App.modalConfirmDelete(function () {
                UserDocumentForm.deleteDocument(user, document);
            })
        });

        $('.js-user-document-edit').on('click', function () {
            const document = $(this).data('document');
            const user = $(this).data('user');
            UserDocumentForm.editDocument(user, document);
        });
        UserDocumentForm.handleSubmitAjax();
    };

    static addDocumentFormDeleteLink = ($documentFormLi) => {
        var $removeFormButton = $('<button type="button" class="btn btn-link" title="' + Translator.trans('mf.pdp.form.document.remove') + '"><i class="text-danger fas fa-times"></i></button>');
        $documentFormLi.find('legend').append($removeFormButton);

        $removeFormButton.on('click', function (e) {
            $documentFormLi.remove();
        });
    };

    static editDocument = async (user, document) => {
        App.loading($('#user-document-list-modal .modal-body'));
        let url = Routing.generate('user_document_edit', {
            id: user,
            idDocument: document,
        });

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const documentForm = await response.text();

            $('#user-document-list-modal').html(documentForm);
            $('#user-document-list-modal').modal()

            UserDocumentForm.handleSubmitAjax();
            App.initTooltip();
            bsCustomFileInput.init();
            App.initSelectPicker();
            App.initDatePicker();
        } else {
            console.error('Une erreur est survenue, le document n\'a pas été trouvé')
        }
    };

    static deleteDocument = async (user, document) => {
        let url = Routing.generate('user_document_delete', {
            id: user,
            idDocument: document
        });

        let response = await fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response.status == 200) {
            $('#user-document-' + document).remove()
        } else {
            console.error('Une erreur est survenue, le document n\'a pas été supprimé')
        }
    };

    static showDocumentList = async (user) => {
        let url = Routing.generate('user_document_show', {
            id: user,
        });

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response.status == 200) {
            const modal = await response.text();
            $('#user-document-list-modal').html(modal)
            UserDocumentForm.initDocumentForm($('.user-documents'))
            $('#user-document-list-modal').modal();
        } else {
            console.error('Une erreur est survenue, l\'utilisateur n\'a pas été trouvé')
        }
    };

    static handleSubmitAjax = () => {
        $('form[name="user_document"]').on('submit', function (e) {
            e.preventDefault();
            UserDocumentForm.submitForm($(this), 'user_document');
        });
        $('form[name="document_user"]').on('submit', function (e) {
            e.preventDefault();
            UserDocumentForm.submitForm($(this), 'document_user');
        })
    };

    static submitForm = async (form, name) => {
        const post_url = form.attr("action");
        const form_data = new FormData(document.forms[name]);
        App.loading($('#user-document-list-modal .modal-body'));
        let response = await fetch(post_url, {
            method: "POST",
            credentials: 'same-origin',
            body: form_data
        });

        if (response && response.status == 201) {
            let data = await response.json();
            UserDocumentForm.showDocumentList(data.user)
        } else {
            console.error('Une erreur est survenue, l\'utilisateur n\'a pas été trouvé')
        }
    }
}