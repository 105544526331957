import Routing
    from '../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

const routes = require('../../../../../../../js/fos_js_routes.json');

Routing.setRoutingData(routes);

export default class ProjectMember {

    constructor() {
        ProjectMember.initMemberForm();
    }

    static initMemberForm = () => {
        if (!$('#ajax-modal').length) {
            $('body').append("<div class='modal fade' role='dialog' id='ajax-modal'></div>")
        }

        $('.js-add-project-member').on('click', function () {
            const project = $(this).data('project');
            ProjectMember.addMember(project);
        });
        $('.js-edit-project-member').on('click', function () {
            const member = $(this).data('project-member');
            ProjectMember.editMember(member);
        });
        $('.js-delete-project-member').on('click', function () {
            const member = $(this).data('project-member');
            App.modalConfirmDelete(function () {
                ProjectMember.removeMember(member);
            });
        });
    };

    static addMember = async (project) => {
        App.loading($('#ajax-modal .modal-body'));
        let url = Routing.generate('project_member_add', {id: project});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const form = await response.text();
            $('#ajax-modal').html(form);
            App.initAutocomplete();
            $('#ajax-modal').modal();
            ProjectMember.handleSubmitAjax();
        } else {
            console.error('Une erreur est survenue, le tiers n\'a pas été trouvé')
        }
    };

    static editMember = async (member) => {
        App.loading($('#ajax-modal .modal-body'));
        let url = Routing.generate('project_member_edit', {id: member});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const form = await response.text();
            $('#ajax-modal').html(form);
            App.initAutocomplete();
            $('#ajax-modal').modal();
            ProjectMember.handleSubmitAjax();
        } else {
            console.error('Une erreur est survenue, le membre n\'a pas été trouvé')
        }
    };

    static removeMember = async (member) => {
        let url = Routing.generate('project_member_remove', {id: member});

        let response = await fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            $('#project-member-' + member).remove()
        } else {
            console.error('Une erreur est survenue, le contact n\'a pas été supprimé')
        }
    };


    static handleSubmitAjax = () => {
        $('form[name="member"]').on('submit', function (e) {
            e.preventDefault();
            ProjectMember.submitForm($(this), 'member');
        });
    };

    static submitForm = async (form, name) => {
        const post_url = form.attr("action");
        const form_data = new FormData(document.forms[name]);
        App.loading($('#ajax-modal .modal-body'));
        let response = await fetch(post_url, {
            method: "POST",
            credentials: 'same-origin',
            body: form_data
        });

        if (response && response.status == 201 || response.status == 200) {
            if (response.status == 201) {
                let data = await response.json()
                window.location.reload();
            } else if (response.status == 200) {
                const form = await response.text();
                $('#ajax-modal').html(form);
                App.initAutocomplete();
                ProjectMember.handleSubmitAjax();
            }
        } else {
            console.error('Une erreur est survenue, le projet n\'a pas été trouvé')
        }
    }
}
