import Routing
    from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Translator from 'bazinga-translator';
import bsCustomFileInput from "bs-custom-file-input";
import StaffInvolved from "../PreventionPlan/StaffInvolved";

const routes = require("../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class ContactDocumentForm {

    constructor() {
        ContactDocumentForm.initDocumentForm($('ul.documents'), 0);
    };

    static addDocumentForm = ($collectionHolder, $newLinkLi) => {
        const prototype = $collectionHolder.data('prototype');
        const index = $collectionHolder.data('index');
        let newForm = prototype;
        newForm = newForm.replace(/__name__/g, index);
        $collectionHolder.data('index', index + 1);
        const $newFormLi = $('<li></li>').html("<fieldset><legend>" + Translator.trans('mf.pdp.form.document.new') + "</legend>" + newForm + "</fieldset>");
        $newLinkLi.before($newFormLi);
        bsCustomFileInput.init();
        App.initSelectPicker();
        App.initDatePicker();
        ContactDocumentForm.addDocumentFormDeleteLink($newFormLi);
    };

    static initDocumentForm = ($collectionHolder, onPdp) => {
        const $addDocumentButton = $('<button type="button" class="btn btn-outline-secondary add_document_link">' + Translator.trans('mf.pdp.form.document.add') + '</button>');
        const $newLinkLi = $('<ul class="text-center"></ul>').append($addDocumentButton);

        $collectionHolder.append($newLinkLi);

        $addDocumentButton.on('click', function (e) {
            ContactDocumentForm.addDocumentForm($collectionHolder, $newLinkLi);
        });
        $collectionHolder.find('li').each(function () {
            ContactDocumentForm.addDocumentFormDeleteLink($(this));
        });

        if (!onPdp) {
            $('#document-form-modal').on('show.bs.modal', function () {
                if (!$collectionHolder.children('li').length) {
                    ContactDocumentForm.addDocumentForm($collectionHolder, $newLinkLi);
                }
            })
        }

        $('.js-document-delete').on('click', function () {
            const document = $(this).data('document');
            const tiersContact = $(this).data('tiers-contact');
            const contact = $(this).data('contact');
            App.modalConfirmDelete(function () {
                ContactDocumentForm.deleteDocument(tiersContact, contact, document);
            })
        });

        $('.js-document-edit').on('click', function () {
            const document = $(this).data('document');
            const tiersContact = $(this).data('tiers-contact');
            const contact = $(this).data('contact');
            ContactDocumentForm.editDocument(tiersContact, contact, document, onPdp);
        });
        if (onPdp) {
            ContactDocumentForm.handleSubmitAjax();
        }
    };

    static addDocumentFormDeleteLink = ($documentFormLi) => {
        var $removeFormButton = $('<button type="button" class="btn btn-link" title="' + Translator.trans('mf.pdp.form.document.remove') + '"><i class="text-danger fas fa-times"></i></button>');
        $documentFormLi.find('legend').append($removeFormButton);

        $removeFormButton.on('click', function (e) {
            $documentFormLi.remove();
        });
    };

    static editDocument = async (tiersContact, contact, document, onPdp) => {
        if (onPdp) {
            App.loading($('#staff-form-modal .modal-body'));
        }
        let url = Routing.generate('tiers_bundle_tiers_contacts_document_edit', {
            id: tiersContact,
            idContact: contact,
            idDocument: document,
            onPdp: onPdp
        });

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const documentForm = await response.text();
            if (onPdp) {
                $('#staff-form-modal').html(documentForm);
                ContactDocumentForm.handleSubmitAjax();
            } else {
                $('#modal-document-edit').html(documentForm);
                $('#modal-document-edit').modal()
            }
            App.initTooltip();
            bsCustomFileInput.init();
            App.initSelectPicker();
            App.initDatePicker();
        } else {
            console.error('Une erreur est survenue, le document n\'a pas été trouvé')
        }
    };

    static deleteDocument = async (tiersContact, contact, document) => {
        let url = Routing.generate('tiers_bundle_tiers_contacts_document_delete', {
            id: tiersContact,
            idContact: contact,
            idDocument: document
        });

        let response = await fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response.status == 200) {
            $('#document-' + document).remove()
        } else {
            console.error('Une erreur est survenue, le document n\'a pas été supprimé')
        }
    };

    static showDocumentList = async (contact) => {
        let url = Routing.generate('tiers_bundle_contact_document_show', {
            id: contact,
        });

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response.status == 200) {
            const modal = await response.text();
            if ($('#document-list-modal').length) {
                $('#document-list-modal').replaceWith(modal)
            } else {
                $("body").append(modal)
            }
            $('#document-list-modal').modal();
        } else {
            console.error('Une erreur est survenue, le contact n\'a pas été trouvé')
        }
    };

    static handleSubmitAjax = () => {
        $('form[name="document_contact"]').on('submit', function (e) {
            e.preventDefault();
            ContactDocumentForm.submitForm($(this), 'document_contact');
        });
        $('form[name="contact_document"]').on('submit', function (e) {
            e.preventDefault();
            ContactDocumentForm.submitForm($(this), 'contact_document');
        })
    };

    static submitForm = async (form, name) => {
        const post_url = form.attr("action");
        const form_data = new FormData(document.forms[name]);
        App.loading($('#staff-form-modal .modal-body'));
        let response = await fetch(post_url, {
            method: "POST",
            credentials: 'same-origin',
            body: form_data
        });

        if (response && response.status == 201) {
            let data = await response.json();
            StaffInvolved.showDocuments(data.tiers_contact)
        } else {
            console.error('Une erreur est survenue, le contact n\'a pas été trouvé')
        }

    }
}