import React from 'react';
import SaveToDashboard from '../save-to-dashboard';
import Translator from 'bazinga-translator';

require('highcharts/modules/exporting')(Highcharts);

import Routing
    from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const routes = require("../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class VgpBreakdown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            chartOption: [],
            types: [],
            type: 0,
            total: 0,
            data: []
        };

        this.getData = this.getData.bind(this);
    }

    async getData() {
        let params = {
            type: this.state.type
        };

        let url = Routing.generate('vgp_breackdown');
        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        let option = {
            chart: {
                type: "pie"
            },
            title: {
                text: ""
            },
            series: [],
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    showInLegend: true
                }
            },
            yAxis: {
                title: {
                    text: 'Nombre'
                }
            },
        };

        if (response.status === 200) {
            let data = await response.json();

            option.series = data.series;

            this.setState({chartOption: option, types: data.types, total: data.totalControl, data: data.series.data});
        }
    }

    handleSelectType(event) {
        let type = event.target.value;

        this.setState({
            type: type
        }, () => {
            this.getData();
        });
    }

    handleClickReset() {
        this.setState({
            type: '',
        }, () => {
            this.getData();
        });

    }

    componentDidMount() {
        this.getData()
    }

    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-header">
                    <h4>
                        Contrôles par état de vérification
                        <SaveToDashboard
                            component="dashboard-vgp-breakdown"
                            size="6"
                            controller=""
                            script=""
                            title="VGP - Contrôles par état de vérification"
                        />
                    </h4>
                </div>
                <div className="card-body widget">
                    <form>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label"
                                   htmlFor='sourceSelect'>Type</label>
                            <div className='col-md-3'>
                                <select value={this.state.type} id='sourceSelect'
                                        onChange={(event) => this.handleSelectType(event)}
                                        className="form-control-sm form-control">
                                    {
                                        this.state.types.map((type) => {
                                            return (<option key={type.value} value={type.value}>{type.label}</option>)
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-md-2 center">
                                <input className="btn btn-sm btn-default" type="reset"
                                       value={Translator.trans('eobs.dashboard.reset')} id="reset"
                                       name="reset" onClick={() => this.handleClickReset()}/>
                            </div>
                        </div>
                    </form>
                    <div className="widget-chart-container">
                        <HighchartsReact highcharts={Highcharts} options={this.state.chartOption}/>
                        <div className="chart-pie-counter">{this.state.total}</div>
                    </div>
                    <div className="chart-legend">
                        <table>
                            <tbody>
                            {
                                this.state.data.length > 0 ?
                                    this.state.data.map((element, key) => {
                                        return (
                                            <tr key={key}>
                                                <td className="chart-legend-color">
                                                <span data-color="top-sales-color1"
                                                      style={{backgroundColor: element.color}}>
                                                </span>
                                                </td>
                                                <td>{element.name}</td>
                                                <td className="chart-legend-value">{element.y}</td>
                                            </tr>
                                        )
                                    })
                                    :
                                    ''
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}