import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import SaveToDashboard from "../../save-to-dashboard";

require('highcharts/modules/exporting')(Highcharts);
import Routing
    from "../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const routes = require("../../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class AtService extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            chartOption: [],
            dateStart: '',
            dateEnd: '',
        };

        this.getDataForAtService = this.getDataForAtService.bind(this);
    }

    async getDataForAtService() {
        let params = {
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd
        };

        let url = Routing.generate('rh_at_service');
        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        let option = {
            chart: {
                type: "pie"
            },
            title: {
                text: ""
            },
            series: [
                {
                    name: "Nombre d'AT",
                    colorByPoint: true,
                    data: [],
                    dataLabels: {
                        format: '{point.percentage:.2f} %'
                    },
                }],
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    showInLegend: true
                }
            },
            legend: {
                labelFormat: '{name} ({y})'
            },
            yAxis: {
                title: {
                    text: "Nombre"
                }
            },
        };

        if (response.status === 200) {
            let data = await response.json();

            option.series[0].data = data.informations;

            this.setState({chartOption: option});
        }
    }


    handleSelectDateStart(event) {
        let dateStart = event.target.value;

        this.setState({
            dateStart: dateStart
        }, () => {
            this.getDataForAtService();
        });
    }

    handleSelectDateEnd(event) {
        let dateEnd = event.target.value;

        this.setState({
            dateEnd: dateEnd
        }, () => {
            this.getDataForAtService();
        });
    }

    handleClickReset() {
        this.setState({
            dateStart: '',
            dateEnd: ''
        }, () => {
            this.getDataForAtService();
            $(".input-date").datepicker("refresh");
        });

    }

    componentDidMount() {
        this.getDataForAtService();
    }

    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-header ">
                    <h4>
                        Répartition des AT par service
                        <SaveToDashboard
                            component="dashboard-at-service"
                            size="6"
                            controller=""
                            script=""
                            title="RH - Répartition des AT par service"
                        />
                    </h4>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="form-group row">
                            <label className="col-md-4 col-form-label col-form-label-sm">
                                Entre
                            </label>
                            <div className="col-md-8 input-group date" data-date-format="dd/mm/yyyy">
                                <input type="text"
                                       className="input-date form-control form-control-sm"
                                       value={this.state.dateStart}
                                       onChange={() => ''}
                                       onSelect={(event) => this.handleSelectDateStart(event)}
                                />
                            </div>
                        </div>
                        <div className="ml-1 form-group row">
                            <label className="col-md-3 col-form-label col-form-label-sm">
                                et
                            </label>
                            <div className="input-group col-md-9 date" data-date-format="dd/mm/yyyy">
                                <input type="text" className="form-control form-control-sm input-date"
                                       value={this.state.dateEnd}
                                       onChange={() => ''}
                                       onSelect={(event) => this.handleSelectDateEnd(event)}
                                />
                                <div className="input-group-addon">
                                    <span className="glyphicon glyphicon-th"></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 center">
                            <input className="btn btn-sm btn-default" type="reset" value="Vider la recherche" id="reset"
                                   name="reset" onClick={() => this.handleClickReset()}/>
                        </div>
                    </div>
                    <HighchartsReact highcharts={Highcharts} options={this.state.chartOption}/>
                </div>
            </div>
        );
    }
}
