import React from 'react';
import SaveToDashboard from "../../save-to-dashboard";

import Routing
    from "../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const routes = require("../../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class AtInfoText extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            month: 'Mars',
            concerned: 'BU E&S',
            aaa: '1',
            asa: '2',
            atJourney: '1',
            atsa: '1',
        };

        this.getData = this.getData.bind(this);
    }

    async getData() {

    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-body">
                    <SaveToDashboard
                        component="dashboard-at-info-text"
                        size="6"
                        controller=""
                        script=""
                        title="RH - Infos AT"
                    />
                    <p>En <strong>{this.state.month}</strong>, sur la <strong>{this.state.concerned}</strong></p>
                    <div className="row dashboard">
                        <div className="col-md-3">
                            <div className="card card-border-color card-border-color-primary">
                                <div className="card-body row">
                                    <div className="col-md-2">&nbsp;</div>
                                    <div className="col-md-10 text-right">
                                        <span>AAA</span><br/>
                                        <span className="number">{this.state.aaa}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card card-border-color card-border-color-primary">
                                <div className="card-body row">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-10 text-right">
                                        <span>ASA</span><br/>
                                        <span className="number">{this.state.asa}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card card-border-color card-border-color-primary">
                                <div className="card-body row">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-10 text-right">
                                        <span>Accidents de trajet</span><br/>
                                        <span
                                            className="number">{this.state.atJourney}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card card-border-color card-border-color-primary">
                                <div className="card-body row">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-10 text-right">
                                        <span>ATSA</span><br/>
                                        <span className="number">{this.state.atsa}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}