import React from "react";
import {css} from '@emotion/core';
import ParserHTML from "../parser";
import ReactPaginate from "react-paginate";

import Routing
    from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Translator from "bazinga-translator";

const routes = require("../../../../../../../js/fos_js_routes.json");

const translationsConfig = require("../../../../../../../js/translations/fr.json");
Translator.fromJSON(translationsConfig);

const override = css`
    display: block;
    margin: 0 auto;
    border-color: blue;
`;

let parserHTML = new ParserHTML();

export default class ListNote extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            renderHTML: null,
            loadingActivities: false,
            activitiesPerPage: 5,
            activityOffsetPage: 0,
            nbTotalActivityPage: 5,
        };
    }

    async componentDidMount() {
        this.loadActivities();
    }

    loadActivities = async () => {
        this.setState({
            loadingActivities: true
        });

        const params = {
            offset: this.state.activityOffsetPage,
            limit: this.state.activitiesPerPage
        };

        let url = Routing.generate('activity_bundle_activity_list_tiers', {id: this.props.tiersId});

        url = url.concat(
            "?",
            Object.entries(params)
                .map(([key, val]) => `${key}=${val}`)
                .join("&")
        );

        try {
            let response = await fetch(url, {
                method: "GET",
                credentials: "same-origin"
            });

            if (response.status == 200) {
                let datas = await response.json();

                this.setState({
                    renderHTML: parserHTML.parse(datas.template),
                    nbTotalActivityPage: Math.ceil(datas.nbActivities / this.state.activitiesPerPage),
                    loadingActivities: false
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    async handleClickPages(page) {
        let offset = Math.ceil(page.selected * this.state.activitiesPerPage);

        this.setState({
            activityOffsetPage: offset
        }, () => {
            this.loadActivities();
        })
    }

    render() {
        return (
            <div className="card">
                <div className="card-header">
                    <h2> {Translator.trans('activity_bundle.title')}</h2>
                </div>
                <div className="card-body">
                    {this.state.renderHTML}
                </div>

                <div className="card-footer">
                    <div className="row">
                        <div className="col-md-8">
                            <ReactPaginate
                                breakClassName="page-item"
                                breakLabel={
                                    <span className="page-link">
                                      ...
                                    </span>
                                }
                                pageClassName="page-item"
                                previousClassName="page-item"
                                nextClassName="page-item"
                                pageLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                previousLabel={Translator.trans("app.pagination.previous")}
                                nextLabel={Translator.trans("app.pagination.next")}
                                pageCount={this.state.nbTotalActivityPage}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handleClickPages.bind(this)}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}