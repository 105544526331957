import React from 'react';
import ReactPaginate from 'react-paginate';
import SaveToDashboard from '../../save-to-dashboard';

require('highcharts/modules/exporting')(Highcharts);

import Routing
    from "../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const routes = require("../../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class FormationList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formations: [],
            itemPerPage: 5,
            nbTotalItems: 0,
            itemOffsetPage: 0,
            nbTotalItemPage: 5,
            services: [],
            service: 0
        }
    }

    async getData() {
        let params = {
            limit: this.state.itemPerPage,
            offset: this.state.itemOffsetPage,
            service: this.state.service
        };

        let url = Routing.generate('rh_formation_list');
        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        if (response.status == 200) {
            let data = await response.json();

            this.setState({
                services: data.services,
                formations: data.informations.formations,
                nbTotalItems: data.informations.nbTotalItems,
                nbTotalItemPage: Math.ceil(data.informations.nbTotalItems / this.state.itemPerPage)
            });
        }
    }

    async handleClickPages(page) {
        let offset = Math.ceil(page.selected * this.state.itemPerPage);

        this.setState({itemOffsetPage: offset}, () => {
            this.getData();
        });
    }

    handleSelectNbItem(event) {
        this.setState({itemPerPage: event.target.value}, () => {
            this.getData();
        });
    }

    handleClickReset() {
        this.setState({
            service: 0
        }, () => {
            this.getData();
        });

    }

    handleSelectService(event) {
        let service = event.target.value;

        this.setState({
            service: service
        }, () => {
            this.getData();
        });
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-header">
                    <h4>
                        Formations arrivant à échéance
                        <SaveToDashboard
                            component="dashboard-form-list"
                            size="6"
                            controller=""
                            script=""
                            title="RH - Formations arrivant à échéance"
                        />
                    </h4>
                </div>
                <div className="card-body card-table">
                    <form>
                        <div className="form-group row p-2">
                            <label className="col-md-2 col-form-label col-form-label-sm" htmlFor="form-list-service">
                                Service
                            </label>
                            <div className="col-md-3">
                                <select value={this.state.service} id="form-list-service" onChange={(event) => this.handleSelectService(event)}
                                        className="form-control form-control-sm">
                                    {
                                        this.state.services.map((service, index) => {
                                            return (<option key={index} value={service.id}>{service.name}</option>)
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-md-2 center">
                                <input className="btn btn-sm btn-default" type="reset" value="Vider la recherche" id="reset"
                                       name="reset" onClick={() => this.handleClickReset()}/>
                            </div>
                        </div>
                    </form>
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th>
                                        Salarié
                                    </th>
                                    <th>
                                        Formation
                                    </th>
                                    <th>
                                        Date de la session
                                    </th>
                                    <th>
                                        Validité
                                    </th>
                                    <th>
                                        Limite de validité
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.formations.length > 0 ?
                                        this.state.formations.map((formation, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <a href={Routing.generate('rh_employee_view', {id: formation.employeeId})}>{formation.employee}</a>
                                                    </td>
                                                    <td>
                                                        {formation.name}
                                                    </td>
                                                    <td>
                                                        <a href={Routing.generate('rh_session_edit', {id: formation.sessionId})}>{formation.sessionDate}</a>
                                                    </td>
                                                    <td>
                                                        {formation.frequency}
                                                    </td>
                                                    <td>
                                                        <span className={formation.badge}>{formation.deadline}</span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-md-3 col-sm-12">
                            {
                                this.state.formations.length > 0 ? (
                                        <div className="datatable-info">
                                            {this.state.itemOffsetPage + 1} - {this.state.itemOffsetPage + this.state.formations.length} sur {this.state.nbTotalItems} résultats
                                        </div>
                                    ) :
                                    (
                                        <div className="datatable-info">
                                            0 - 0 sur 0 résultats
                                        </div>
                                    )
                            }
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <ReactPaginate
                                breakClassName="page-item"
                                breakLabel={<button className="page-link">...</button>}
                                pageClassName="page-item"
                                previousClassName="page-item"
                                previousLabel="Précédente"
                                nextClassName="page-item"
                                nextLabel="Suivante"
                                pageLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                pageCount={this.state.nbTotalItemPage}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handleClickPages.bind(this)}
                                containerClassName={'pagination justify-content-center'}
                                activeClassName={'active'}
                            />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <select className="pagination-dashboard form-control float-right"
                                    onChange={this.handleSelectNbItem.bind(this)}>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}