import Routing
    from '../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
import ProjectDocument from "./ProjectDocument";
import ProjectComment from "./ProjectComment";

const routes = require('../../../../../../../js/fos_js_routes.json');

Routing.setRoutingData(routes);

export default class ProjectTask {

    constructor() {
        ProjectTask.initTaskForm();
    }

    static initTaskForm = () => {
        if (!$('#ajax-modal').length) {
            $('body').append("<div class='modal fade' role='dialog' id='ajax-modal'></div>")
        }

        $('.js-add-task').on('click', function (e) {
            e.preventDefault();
            const project = $(this).data('project');
            ProjectTask.addTask(project);
        });
        $('.js-add-task-milestone').on('click', function (e) {
            e.preventDefault();
            const project = $(this).data('project');
            ProjectTask.addTaskMilestone(project);
        });
        $('.js-edit-task').on('click', function (e) {
            e.preventDefault();
            const task = $(this).data('task');
            ProjectTask.editTask(task);
        });
        $('.js-edit-task-milestone').on('click', function (e) {
            e.preventDefault();
            const task = $(this).data('task');
            ProjectTask.editTaskMilestone(task);
        });
        $('.js-delete-task').on('click', function (e) {
            e.preventDefault();
            const task = $(this).data('task');
            App.modalConfirmDelete(function () {
                ProjectTask.removeTask(task);
            });
        });
        $('.js-task-done').on('click', function (e) {
            e.preventDefault();
            const task = $(this).data('task');
            ProjectTask.doneTask(task);
        });
        $('.js-task-show').on('click', function (e) {
            e.preventDefault();
            const task = $(this).data('task');
            const project = $(this).data('project');
            ProjectTask.showTask(project, task);
        });
        $('.js-scheduled-task-add').on('click', function (e) {
            e.preventDefault();
            const task = $(this).data('task');
            ProjectTask.scheduledTaskAdd(task);
        });
    };

    static addTask = async (project) => {
        App.loading($('#ajax-modal .modal-body'));
        let url = Routing.generate('project_task_add', {id: project});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const form = await response.text();
            $('#ajax-modal').html(form);
            App.initSelectPicker();
            App.initDatePicker();
            $('#ajax-modal').modal();
            ProjectTask.handleSubmitAjax();
        } else {
            console.error('Une erreur est survenue, le tiers n\'a pas été trouvé')
        }
    };

    static addTaskMilestone = async (project) => {
        App.loading($('#ajax-modal .modal-body'));
        let url = Routing.generate('project_task_milestone_add', {id: project});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const form = await response.text();
            $('#ajax-modal').html(form);
            App.initSelectPicker();
            App.initDatePicker();
            $('#ajax-modal').modal();
            ProjectTask.handleSubmitAjax();
        } else {
            console.error('Une erreur est survenue');
        }
    };

    static editTask = async (task) => {
        App.loading($('#ajax-modal .modal-body'));
        let url = Routing.generate('project_task_edit', {id: task});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const form = await response.text();
            $('#ajax-modal').html(form);
            App.initSelectPicker();
            App.initDatePicker();
            $('#ajax-modal').modal();
            ProjectTask.handleSubmitAjax();
        } else {
            console.error('Une erreur est survenue, le membre n\'a pas été trouvé')
        }
    };

    static editTaskMilestone = async (task) => {
        App.loading($('#ajax-modal .modal-body'));
        let url = Routing.generate('project_task_milestone_edit', {id: task});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const form = await response.text();
            $('#ajax-modal').html(form);
            App.initSelectPicker();
            App.initDatePicker();
            $('#ajax-modal').modal();
            ProjectTask.handleSubmitAjax();
        } else {
            console.error('Une erreur est survenue')
        }
    };

    static removeTask = async (task) => {
        let url = Routing.generate('project_task_remove', {id: task});

        let response = await fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            $('#task-card-' + task).remove()
        } else {
            console.error('Une erreur est survenue, le task n\'a pas été supprimé')
        }
    };

    static doneTask = async (task) => {
        App.loading($('#ajax-modal .modal-body'));
        let url = Routing.generate('project_task_done', {id: task});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const form = await response.text();
            $('#ajax-modal').html(form);
            App.initDatePicker();
            $('#ajax-modal').modal();
            ProjectTask.handleSubmitAjax();
        } else {
            console.error('Une erreur est survenue, la tache n\'a pas été trouvé')
        }
    };

    static scheduledTaskAdd = async (task) => {
        App.loading($('#ajax-modal .modal-body'));
        let url = Routing.generate('project_scheduled_task_add', {id: task});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const form = await response.text();
            $('#ajax-modal').html(form);
            $('#ajax-modal').find('.js-task-show').on('click', function () {
                const task = $(this).data('task');
                const project = $(this).data('project');
                ProjectTask.showTask(project, task);
            });
            App.initSelectPicker();
            App.initDatePicker();
            $('#ajax-modal').modal();
            ProjectTask.handleSubmitAjax();
        } else {
            console.error('Une erreur est survenue, la tache n\'a pas été trouvé')
        }
    };

    static scheduledTaskEdit = async (scheduledTask) => {
        App.loading($('#ajax-modal .modal-body'));
        let url = Routing.generate('project_scheduled_task_edit', {id: scheduledTask});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const form = await response.text();
            $('#ajax-modal').html(form);
            $('#ajax-modal').find('.js-task-show').on('click', function () {
                const task = $(this).data('task');
                const project = $(this).data('project');
                ProjectTask.showTask(project, task);
            });
            App.initSelectPicker();
            App.initDatePicker();
            $('#ajax-modal').modal();
            ProjectTask.handleSubmitAjax();
        } else {
            console.error('Une erreur est survenue, la tache n\'a pas été trouvé')
        }
    };

    static removeScheduledTask = async (scheduledTask) => {
        let url = Routing.generate('project_scheduled_task_remove', {id: scheduledTask});

        let response = await fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            $('#scheduled-task-card-' + scheduledTask).remove()
            return true;
        } else {
            console.error('Une erreur est survenue, la tache n\'a pas été supprimé')
            return false;
        }
    };

    static showTask = async (project, task) => {
        App.loading($('#ajax-modal .modal-body'));
        let url = Routing.generate('project_task_show', {id: project, taskId: task});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const form = await response.text();
            $('#ajax-modal').html(form);
            new ProjectDocument();
            $('#ajax-modal').find('.js-scheduled-task-add').on('click', function () {
                const task = $(this).data('task');
                ProjectTask.scheduledTaskAdd(task);
            });
            $('#ajax-modal').find('.js-scheduled-task-edit').on('click', function () {
                const scheduledTask = $(this).data('scheduled-task');
                ProjectTask.scheduledTaskEdit(scheduledTask);
            });
            $('#ajax-modal').find('.js-scheduled-task-delete').on('click', function () {
                const scheduledTask = $(this).data('scheduled-task');
                App.modalConfirmDelete(function () {
                    ProjectTask.removeScheduledTask(scheduledTask);
                });
            });
            $('#ajax-modal').find('.js-edit-task').on('click', function () {
                const task = $(this).data('task');
                ProjectTask.editTask(task);
            });
            App.initSelectPicker();
            App.initDatePicker();
            $('#ajax-modal').modal();
            $('#ajax-modal').on('shown.bs.modal', function (e) {
                new ProjectComment();
            })
            ProjectTask.handleSubmitAjax();
        } else {
            console.error('Une erreur est survenue, la tache n\'a pas été trouvé')
        }
    };

    static handleSubmitAjax = () => {
        $('form[name="task"]').on('submit', function (e) {
            e.preventDefault();
            ProjectTask.submitForm($(this), 'task');
        });
        $('form[name="milestone"]').on('submit', function (e) {
            e.preventDefault();
            ProjectTask.submitForm($(this), 'milestone');
        });
        $('form[name="task_done"]').on('submit', function (e) {
            e.preventDefault();
            ProjectTask.submitForm($(this), 'task_done');
        });
        $('form[name="scheduled_task"]').on('submit', function (e) {
            e.preventDefault();
            ProjectTask.submitForm($(this), 'scheduled_task');
        });
    };

    static submitForm = async (form, name) => {
        const post_url = form.attr("action");
        const form_data = new FormData(document.forms[name]);
        App.loading($('#ajax-modal .modal-body'));
        let response = await fetch(post_url, {
            method: "POST",
            credentials: 'same-origin',
            body: form_data
        });

        if (response && response.status == 201 || response.status == 200) {
            if (response.status == 201) {
                let data = await response.json()
                window.location.reload();
            } else if (response.status == 200) {
                const form = await response.text();
                $('#ajax-modal').html(form);
                App.initSelectPicker();
                App.initDatePicker();
                ProjectTask.handleSubmitAjax();
            }
        } else {
            console.error('Une erreur est survenue, le projet n\'a pas été trouvé')
        }
    }
}
