import React, {Component} from 'react';
import {css} from '@emotion/core';
import {FadeLoader} from "react-spinners";
import ReactPaginate from 'react-paginate';
import Translator from 'bazinga-translator';
import {Radar} from 'react-chartjs-2'

const routes = require('../../../../../../../js/fos_js_routes.json');
import Routing
    from '../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

Routing.setRoutingData(routes);

const override = css`
    display: block;
    margin: 0 auto;
    border-color: blue;
`;

const colors = [
    '#2f7ed8',
    '#8bbc21',
    '#979fe8',
    '#910000',
    '#1aadce',
    '#492970',
    '#a6c96a',
    '#f28f43',
    '#77a1e5',
    '#2b908f',
    '#c42525',
    '#90ee7e',
    '#f45b5b',
    '#7798BF',
    '#aaeeee',
    '#ff0066',
    '#eeaaee',
    '#55BF3B',
    '#DF5353',
    '#7798BF',
    '#aaeeee'
];

export default class QuizResultMonitoring extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingSpinner: false,
            dateStart: '',
            dateEnd: '',
            users: [],
            selectedUser: [],
            resultsPerPage: 5,
            resultsOffsetPage: 0,
            nbTotalResultsPage: 1,
            results: [],
            quiz: props.quiz,
            chartData: {},
            chartOptions: {
                scale: {
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 10,
                    }
                },
            },
            displayed: []
        };

        this.handleSelectDateStart = this.handleSelectDateStart.bind(this);
        this.handleSelectDateEnd = this.handleSelectDateEnd.bind(this);
        this.handleSelectUser = this.handleSelectUser.bind(this);
        this.handleClickReset = this.handleClickReset.bind(this);
    }

    componentDidMount() {
        this.loadResults();
    }

    async loadResults() {
        let params = {
            limit: this.state.resultsPerPage,
            offset: this.state.resultsOffsetPage,
            id: this.props.quiz,
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd,
            user: this.state.selectedUser,
        };

        let url = Routing.generate('admin_quiz_results', params);

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin'
        });

        if (response.status == 200) {
            let responseData = await response.json();

            let nbPage = Math.ceil(responseData.nbResults / this.state.resultsPerPage);

            let item = responseData.results[0];
            let labels = []
            if (item != undefined) {
                labels = item.noteGroup.map(function (note) {
                    return note.name;
                })
            }

            this.setState({
                users: responseData.users,
                results: responseData.results,
                nbTotalResultsPage: nbPage,
                loadingSpinner: false,
                chartData: {
                    labels: labels,
                    datasets: [...this.state.chartData.datasets]
                }
            });
        }
    }

    handleClickPages(page) {
        let offset = Math.ceil(page.selected * this.state.resultsPerPage);

        this.setState({resultsOffsetPage: offset}, () => {
            this.loadResults();
        });
    }

    handleSelectNbItem(event) {
        this.setState({resultsPerPage: event.target.value}, () => {
            this.loadResults();
        });
    }

    handleSelectDateStart(event) {
        let dateStart = event.target.value;

        this.setState({
            dateStart: dateStart
        }, () => {
            this.loadResults();
        });
    }

    handleSelectDateEnd(event) {
        let dateEnd = event.target.value;

        this.setState({
            dateEnd: dateEnd
        }, () => {
            this.loadResults();
        });
    }

    handleSelectUser(event) {
        let user = event.target.value;

        this.setState({
            selectedUser: user
        }, () => {
            this.loadResults();
        });
    }

    handleClickReset() {
        this.setState({
            dateStart: '',
            dateEnd: '',
            selectedUser: '',
            displayed: []
        }, () => {
            this.loadResults();
            this.refreshGraph();
            $(".input-date").datepicker("refresh");
        });

    }

    isDisplayed(result) {
        let display = this.state.displayed.filter((item) => {
            return item.id === result.id
        })

        return display.length
    }

    toggleQuizDisplayed = (result) => {
        let displayed = [];
        if (this.isDisplayed(result)) {
            displayed = this.state.displayed.filter((item) => {
                return item.id !== result.id
            })
        } else {
            displayed = [...this.state.displayed, result];
        }

        this.setState({
            displayed: displayed,
        }, this.refreshGraph)
    }

    refreshGraph() {
        let data = this.state.displayed.map((result, key) => {
            let notes = result.noteGroup.map((note) => {
                return note.note;
            })
            return {
                label: result.userName + ' - ' + result.date,
                backgroundColor: colors[key] + '3d',
                borderColor: colors[key],
                data: notes
            };
        })

        this.setState({
            chartData: {
                labels: this.state.chartData.labels,
                datasets: data
            }
        })
    }

    render() {
        let listingResults = this.state.results.map((result, index) => {
            return (
                <tr key={result.id}>
                    <td>
                        <a href={result.url}>{result.userName}</a>
                    </td>
                    <td>
                        <a href={result.url}>{result.date}</a>
                    </td>
                    <td>
                        {
                            result.isNoted ?
                                <span className={'badge badge-' + result.noteColor}>{result.note}/10</span>
                                :
                                <span className="badge badge-danger">
                                    <i className="fas fa-times-circle"></i> {Translator.trans('mf.activity_map.quiz.layout.waitingNote')}
                                </span>
                        }
                    </td>
                    <td>
                        <button type="button" className="btn btn-secondary"
                                onClick={this.toggleQuizDisplayed.bind(this, result)}>
                            <i className={this.isDisplayed(result) ? 'fas fa-eye-slash' : 'fas fa-eye'}/>
                        </button>
                    </td>
                </tr>
            )
        });

        return (
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            {Translator.trans('mf.activity_map.quiz.layout.allResults')}
                        </div>

                        <div className="card-body p-0">
                            <div className="form-row form-inline">
                                <div className="col-6 form-group">
                                    <label className="col-md-4 col-form-label col-form-label-sm">
                                        Entre
                                    </label>
                                    <div className="col-md-8">
                                        <input type="text"
                                               className="input-date form-control form-control-sm"
                                               value={this.state.dateStart}
                                               onChange={() => ''}
                                               onSelect={(event) => this.handleSelectDateStart(event)}
                                        />
                                    </div>
                                </div>
                                <div className="col-6 form-group">
                                    <label className="col-md-4 col-form-label col-form-label-sm">
                                        et
                                    </label>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control form-control-sm input-date"
                                               value={this.state.dateEnd}
                                               onChange={() => ''}
                                               onSelect={(event) => this.handleSelectDateEnd(event)}
                                        />
                                    </div>
                                </div>
                                <div className="col-6 form-group">
                                    <label className="col-md-4 col-form-label col-form-label-sm">
                                        Utilisateur
                                    </label>
                                    <select className="col-md-8 form-control form-control-sm"
                                            onChange={(event) => this.handleSelectUser(event)}
                                            value={this.state.selectedUser}>
                                        <option value=""></option>
                                        {
                                            this.state.users.map((user) => {
                                                return (<option key={user.id} value={user.id}>{user.name}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-6 form-group justify-content-center">
                                    <input className="btn btn-sm btn-default" type="reset" value="Vider la recherche"
                                           id="reset"
                                           name="reset" onClick={() => this.handleClickReset()}/>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover table-striped m-0">
                                    <thead>
                                    <tr>
                                        <th>
                                            {Translator.trans('mf.activity_map.quiz.layout.userName')}
                                        </th>
                                        <th>
                                            {Translator.trans('mf.activity_map.quiz.layout.date')}
                                        </th>
                                        <th>
                                            {Translator.trans('mf.activity_map.quiz.layout.note')}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <FadeLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={500}
                                        color={'#004788'}
                                        loading={this.state.loadingSpinner}
                                    />

                                    {listingResults}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="row">
                                <div className="col-md-4">
                                    <ReactPaginate
                                        breakClassName="page-item"
                                        breakLabel={<span className="page-link">...</span>}
                                        pageClassName="page-item"
                                        previousLabel="Précédente"
                                        previousClassName="page-item"
                                        nextClassName="page-item"
                                        nextLabel="Suivante"
                                        pageLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        pageCount={this.state.nbTotalResultsPage}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.handleClickPages.bind(this)}
                                        containerClassName={'pagination'}
                                        activeClassName={'active'}
                                    />
                                </div>
                                <div className="offset-4 col-md-2">
                                    <select className="form-control float-right"
                                            onChange={this.handleSelectNbItem.bind(this)}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <Radar data={this.state.chartData} options={this.state.chartOptions}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}