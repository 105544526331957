import React, {Component} from 'react';
import { connect } from "react-redux";
import {
    Circle,
    CircleMarker,
    ImageOverlay,
    LayerGroup,
    LayersControl,
    Map,
    Marker,
    Polygon,
    Popup
} from "react-leaflet";

import L, {CRS} from 'leaflet';
import {CardBody} from "reactstrap";
import Translator from "bazinga-translator";
import SaveToDashboard from "../../save-to-dashboard";
import WorkorderDate from "./WorkorderDate";

import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl,
    iconUrl,
    shadowUrl
});

const {Overlay} = LayersControl;

const types = [
    'work_order',
    'fire_permit'
];

class CoactivityMap extends Component {

    constructor(props) {
        super(props);

        this.state = {
            date: props.workorderDate,
            type: 'work_order',
            work_orders: [],
            fire_permits: [],
            plan_informations: [],
            plan: null,
            planSelected: ''
        };

        this.handleSelectType = this.handleSelectType.bind(this)
    }

    componentDidMount() {
        this.getData();
    }

    handleSelectPlan(event) {
        let planSelected = event.target.value;

        this.setState({
            planSelected: planSelected
        }, () => {
            this.getData();
        });
    }

    handleSelectType(event) {
        let typeSelected = event.target.value;

        this.setState({
            type: typeSelected
        });
    }

    getData = async () => {

        let params = {
            date: this.state.date,
            plan: this.state.planSelected,
        };

        let url = Routing.generate('pdp_coactivity_map');
        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        if (response.status === 200) {
            let data = await response.json();

            this.setState({
                work_orders: data.work_orders,
                fire_permits: data.fire_permits,
                plan_informations: data.plan_informations,
                plan: data.plan,
            });
        }
    };

    componentDidUpdate() {
        const date = this.props.workorderDate;

        if (date != this.state.date) {
            this.setState(
                {
                    date: date,
                },
                () => this.getData()
            );
        }
    }

    render() {
        let colors = ['#2f7ed8', '#979fe8', '#8bbc21', '#910000', '#1aadce',
            '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a', '#2b908f', '#90ee7e', '#f45b5b', '#7798BF', '#aaeeee', '#ff0066',
            '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'];

        let coactivitiesLeafletZones = [];

        if (this.state.type === 'work_order') {
            coactivitiesLeafletZones = this.state.work_orders.map((work_order, index) => {
                return (
                    <Overlay key={'wo' + work_order.id} checked name={work_order.workOrderNumber}>
                        <LayerGroup>
                            {
                                work_order.zones.map((zone, zoneIndex) => {
                                    let link = Routing.generate('pdp_show_intervention_tiers', {'id': work_order.pdpId});
                                    let popupText = <a href={link}
                                                       target='_blank'>{work_order.workOrderNumber}</a>;

                                    if (zone.type === "circle") {
                                        return (
                                            <Circle key={zone.id} id={zone.id} workOrder={work_order.id}
                                                    type="circle"
                                                    fillColor={colors[index]}
                                                    color={colors[index]}
                                                    center={[zone.data.center.lat, zone.data.center.lng]}
                                                    radius={zone.data.radius}>
                                                <Popup>{popupText}</Popup>
                                            </Circle>
                                        )
                                    } else if (zone.type === "circlemarker") {
                                        return (
                                            <CircleMarker key={zone.id} id={zone.id} workOrder={work_order.id}
                                                          type="circlemarker"
                                                          fillColor={colors[index]}
                                                          color={colors[index]}
                                                          center={[zone.data.center.lat, zone.data.center.lng]}
                                                          radius={zone.data.radius}>
                                                <Popup>{popupText}</Popup>
                                            </CircleMarker>
                                        )
                                    } else if (zone.type === "marker") {
                                        return (
                                            <Marker key={zone.id} id={zone.id} workOrder={work_order.id}
                                                    type="marker"
                                                    position={zone.data.position}
                                            >
                                                <Popup>{popupText}</Popup>
                                            </Marker>
                                        )
                                    } else if (zone.type === "polygon") {
                                        return (
                                            <Polygon key={zone.id} id={zone.id} workOrder={work_order.id}
                                                     type="polygon"
                                                     fillColor={colors[index]}
                                                     color={colors[index]}
                                                     positions={zone.data.points}
                                            >
                                                <Popup>{popupText}</Popup>
                                            </Polygon>
                                        )
                                    }
                                })
                            }
                        </LayerGroup>
                    </Overlay>
                );
            });
        } else if (this.state.type === 'fire_permit') {
            coactivitiesLeafletZones = this.state.fire_permits.map((fire_permit, index) => {
                return (
                    <Overlay key={'fp' + fire_permit.id} checked name={fire_permit.firePermitNumber}>
                        <LayerGroup>
                            {
                                fire_permit.zones.map((zone, zoneIndex) => {
                                    let link = Routing.generate('pdp_show_intervention_tiers', {'id': fire_permit.pdpId});
                                    let popupText = <a href={link}
                                                       target='_blank'>{fire_permit.firePermitNumber}</a>;

                                    if (zone.type === "circle") {
                                        return (
                                            <Circle key={zone.id} id={zone.id}
                                                    type="circle"
                                                    fillColor={colors[index]}
                                                    color={colors[index]}
                                                    center={[zone.data.center.lat, zone.data.center.lng]}
                                                    radius={zone.data.radius}>
                                                <Popup>{popupText}</Popup>
                                            </Circle>
                                        )
                                    } else if (zone.type === "circlemarker") {
                                        return (
                                            <CircleMarker key={zone.id} id={zone.id}
                                                          type="circlemarker"
                                                          fillColor={colors[index]}
                                                          color={colors[index]}
                                                          center={[zone.data.center.lat, zone.data.center.lng]}
                                                          radius={zone.data.radius}>
                                                <Popup>{popupText}</Popup>
                                            </CircleMarker>
                                        )
                                    } else if (zone.type === "marker") {
                                        return (
                                            <Marker key={zone.id} id={zone.id}
                                                    type="marker"
                                                    position={zone.data.position}
                                            >
                                                <Popup>{popupText}</Popup>
                                            </Marker>
                                        )
                                    } else if (zone.type === "polygon") {
                                        return (
                                            <Polygon key={zone.id} id={zone.id}
                                                     type="polygon"
                                                     fillColor={colors[index]}
                                                     color={colors[index]}
                                                     positions={zone.data.points}
                                            >
                                                <Popup>{popupText}</Popup>
                                            </Polygon>
                                        )
                                    }
                                })
                            }
                        </LayerGroup>
                    </Overlay>
                );
            });
        }

        return (
            <div className="card mb-2 mt-2">
                <div className="card-header ">
                    <h4>
                        {Translator.trans("mf.pdp.indicator.coactivity.title")}
                        <SaveToDashboard
                            component="dashboard-pdp-coactivity-map"
                            size="6"
                            controller=""
                            script=""
                            title={"PDP - " + Translator.trans("mf.pdp.indicator.coactivity.title")}
                        />
                    </h4>
                </div>
                <CardBody>
                    <WorkorderDate />

                    <div className="row">
                        {
                            this.state.fire_permits.length ?
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-form-label col-md-3">
                                            {Translator.trans("mf.pdp.indicator.coactivity.types")}
                                        </label>
                                        <div className="col-md-9">
                                            <select value={this.state.type} onChange={this.handleSelectType}
                                                    className="form-control">
                                                {
                                                    types.map((type) => {
                                                        return (
                                                            <option key={type} value={type}>
                                                                {Translator.trans("mf.pdp.indicator.coactivity.type." + type)}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                :
                                ''
                        }
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label className="col-form-label col-md-3">
                                    {Translator.trans("mf.pdp.indicator.coactivity.plan")}
                                </label>
                                <div className="col-md-9">
                                    <select value={this.state.planSelected}
                                            onChange={(event) => this.handleSelectPlan(event)}
                                            className="form-control">
                                        {
                                            this.state.plan_informations.map((plan) => {
                                                return (<option key={plan.id} value={plan.id}>{plan.name}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="map" style={{height: 500 + 'px'}}>
                        {
                            this.state.plan ?
                                <Map center={[200, this.state.plan.width / 2]} zoom={0} maxZoom={5}
                                     doubleClickZoom={false}
                                     crs={CRS.Simple}>
                                    <LayersControl position='topleft'>
                                        {coactivitiesLeafletZones}
                                        <Overlay key={this.state.plan.id} checked name={"Plan " + this.state.plan.name}>
                                            <ImageOverlay
                                                id={1}
                                                bounds={[[0, 0], [400, this.state.plan.width]]}
                                                url={this.state.plan.path}
                                            />
                                        </Overlay>
                                    </LayersControl>
                                </Map>
                                :
                                ''
                        }
                    </div>
                </CardBody>
            </div>
        );
    }

}

export default connect((state) => state.dashboardReducer)(CoactivityMap);
