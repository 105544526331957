import React from 'react'
import reactCSS from 'reactcss'
import {ChromePicker} from 'react-color'

export default class ResourceColorPicker extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            displayColorPicker: false,
            color: props.resource.color,
        };

        this.handleSave = this.handleSave.bind(this)
    }

    handleClick = () => {
        this.setState({displayColorPicker: !this.state.displayColorPicker})
    };

    async handleSave() {
        let response = await fetch(Routing.generate("project_user_color_edit", {
            id: this.props.resource.id,
            color: this.state.color
        }), {
            method: "POST",
            credentials: 'include'
        });

        if (response.status == 200) {
            this.props.onClose();
        }
    }

    handleChange = (color) => {
        this.setState({color: color.hex})
    };

    render() {

        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.state.color,
                    cursor: 'pointer',
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    width: '100%'
                },
                popover: {
                    position: 'absolute',
                    padding: '5px',
                    zIndex: '100',
                },
            },
        });

        return (
            <div className="col-sm-12">
                <div className="d-flex align-items-center" style={styles.swatch}>
                    {this.props.resource.fullName}
                    <div className="ml-auto" style={styles.color} onClick={this.handleClick}/>
                    <button className="btn btn-link btn-sm" onClick={this.handleSave}>
                        <i className="fa fa-check"/>
                    </button>
                </div>
                {this.state.displayColorPicker ? <div style={styles.popover}>
                    <ChromePicker color={this.state.color} onChange={this.handleChange} disableAlpha={true}/>
                </div> : null}
            </div>
        )
    }
}
