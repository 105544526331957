import React, { Component } from "react";

/**
 * Basic component for textarea
 */
export default class TextareaField extends Component {
    constructor(props) {
        super(props);

        let children = this.props.children[0];

        this.state = {
            value: children ? children.data : ""
        };
    }

    onChange = (e) => {
        this.setState({
            value: e.target.value
        });
    };

    render() {
        return (
            <textarea
                id={this.props.attribs.id}
                className={this.props.attribs.class}
                name={this.props.attribs.name}
                value={this.state.value}
                onChange={this.onChange.bind(this)}
            ></textarea>
        );
    }
}
