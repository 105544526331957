import React from 'react';
import SaveToDashboard from '../../save-to-dashboard';

require('highcharts/modules/exporting')(Highcharts);

import Routing
    from "../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const routes = require("../../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class ArRawData extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            company: 0,
            companies: [],
            data: {
                header: {
                    concerned: '',
                    currentDate: '',
                    pastYear: '',
                    targetYear: ''
                },
                year: {
                    total: 0,
                    aaa: 0,
                    asa: 0,
                    tf1: 0,
                    tg: 0,
                    nbDays: 0
                },
                pastYear: {
                    total: 0,
                    aaa: 0,
                    asa: 0,
                    tf1: 0,
                    tg: 0,
                    nbDays: 0
                },
                target: {
                    tf1: 0,
                    tg: 0,
                },
            }
        };

        this.getData = this.getData.bind(this);
    }


    async getData() {
        let params = {
            company: this.state.company,
        };

        let url = Routing.generate('rh_at_raw_data');
        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        if (response.status === 200) {
            let data = await response.json();
            this.setState({
                companies: data.companies,
                data: data.data
            });
        }
    }

    handleCompanySelect(event) {
        let company = event.target.value;

        this.setState({
            company: company
        }, () => {
            this.getData();
        })
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        let companiesSelect = '';

        if (this.state.companies.length) {
            companiesSelect = (
                <select
                    value={this.state.company}
                    id="select-companies"
                    className="form-control"
                    onChange={event => this.handleCompanySelect(event)}>
                    {this.state.companies.map((company, index) => {
                        return (
                            <option
                                key={index}
                                value={company.id}
                                data-content={company.name}>{company.name}</option>
                        );
                    })}
                </select>
            );
        }

        return (
            <div className="card mb-2 mt-2">
                <div className="card-header">
                    <h4>
                        Synthèse
                        <SaveToDashboard
                            component="dashboard-at-raw-data"
                            size="6"
                            controller=""
                            script=""
                            title="RH - Synthèse AT"
                        />
                    </h4>
                </div>
                <div className="card-body card-table">
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th>{companiesSelect}</th>
                                    <th className="text-right">Total Année {this.state.data.header.pastYear}</th>
                                    <th className="text-right">Année {this.state.data.header.currentDate}</th>
                                    <th className="text-right">Objectif {this.state.data.header.targetYear}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Nb total d'accidents</td>
                                    <td className="text-right">{this.state.data.pastYear.total}</td>
                                    <td className="text-right">{this.state.data.year.total}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Accidents avec arrêt</td>
                                    <td className="text-right">{this.state.data.pastYear.aaa}</td>
                                    <td className="text-right">{this.state.data.year.aaa}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Accidents sans arrêt</td>
                                    <td className="text-right">{this.state.data.pastYear.asa}</td>
                                    <td className="text-right">{this.state.data.year.asa}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Taux de frequence TF1</td>
                                    <td className="text-right">{this.state.data.pastYear.tf1}</td>
                                    <td className="text-right">{this.state.data.year.tf1}</td>
                                    <td className="text-right">{this.state.data.target.tf1}</td>
                                </tr>
                                <tr>
                                    <td>Taux de gravité TG</td>
                                    <td className="text-right">{this.state.data.pastYear.tg}</td>
                                    <td className="text-right">{this.state.data.year.tg}</td>
                                    <td className="text-right">{this.state.data.target.tg}</td>
                                </tr>
                                <tr>
                                    <td>Nb de jours d'arrêt</td>
                                    <td className="text-right">{this.state.data.pastYear.nbDays}</td>
                                    <td className="text-right">{this.state.data.year.nbDays}</td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}