import React, {Component} from 'react'
import {Calendar as BigCalendar, momentLocalizer} from 'react-big-calendar'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from 'moment';
import Translator from 'bazinga-translator';

import Routing from "../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const routes = require("../../../../../../js/fos_js_routes.json");

Routing.setRoutingData(routes);

const DnDCalendar = withDragAndDrop(BigCalendar);

moment.locale('fr');
const localizerFormat = momentLocalizer(moment);

const views = {
    MONTH: 'month',
    WEEK: 'week',
    WORK_WEEK: 'work_week',
    DAY: 'day',
    AGENDA: 'agenda'
};

const viewNames = Object.keys(views).map(function (k) {
    return views[k];
});

let defaultView = "work_week";
let workWeekMessage = Translator.trans('mf.core.calendar.work_week');

export default class Calendar extends Component {

    constructor(props) {
        super(props);

        if (props.workWeek) {
            viewNames.splice(viewNames.indexOf('week'), 1);
            workWeekMessage = Translator.trans('mf.core.calendar.week');
        } else {
            viewNames.splice(viewNames.indexOf('work_week'), 1);
            defaultView = "week";
        }

        this.state = {
            events: [],
            resources: [],
            resourcesSelected: [],
            customersSearch: [],
            messages: {
                allDay: Translator.trans('mf.core.calendar.allDay'),
                previous: Translator.trans('mf.core.calendar.previous'),
                next: Translator.trans('mf.core.calendar.next'),
                today: Translator.trans('mf.core.calendar.today'),
                month: Translator.trans('mf.core.calendar.month'),
                week: Translator.trans('mf.core.calendar.week'),
                work_week: workWeekMessage,
                day: Translator.trans('mf.core.calendar.day'),
                agenda: Translator.trans('mf.core.calendar.agenda'),
                date: Translator.trans('mf.core.calendar.date'),
                time: Translator.trans('mf.core.calendar.time'),
                event: Translator.trans('mf.core.calendar.event'),
                noEventsInRange: Translator.trans('mf.core.calendar.noEventsInRange'),
                showMore: total => Translator.trans('mf.core.calendar.moreEvents', {'total': total})
            }
        };

        this.moveEvent = this.moveEvent.bind(this);
        this.eventStyleGetter = this.eventStyleGetter.bind(this);
    }

    async fetchEvents() {
        let response = await fetch(Routing.generate("calendar_events", {
            resources: this.state.resourcesSelected,
        }), {
            method: "GET",
            credentials: 'include'
        });

        if (response.status == 200) {
            let data = await response.json();

            let formattedEvents = [];
            for (let curEvent of data.events) {
                curEvent.start = moment(curEvent.start).toDate();
                curEvent.end = moment(curEvent.end).toDate();
                formattedEvents.push(curEvent);
            }

            this.setState({
                events: formattedEvents
            })
        }
    }

    /**
     * updateEventDates when moving or resizing event
     *
     * @param updatedEvent
     */
    async updateEventDates(updatedEvent) {
        let start = moment(updatedEvent.start);
        let end = moment(updatedEvent.end);
        let response = await fetch(Routing.generate("calendar_update_event_dates", {
                id: updatedEvent.id,
                resource: updatedEvent.resource,
                start: start.format('YYYY-MM-DD HH:mm'),
                end: end.format('YYYY-MM-DD HH:mm')
            }),
            {
                method: "PUT",
                credentials: 'include'
            });
    }

    /**
     *
     * Get customers by search
     *
     * @param event
     */
    async handleSearch(event) {
        let search = event.target.value;

        if (search != '') {
            let response = await fetch(Routing.generate("calendar_event_search_customer", {
                resources: this.state.resourcesSelected,
                search: search
            }), {
                method: "GET",
                credentials: 'include'
            });

            if (response.status == 200) {
                let data = await response.json();

                this.setState({
                    customersSearch: data.customers
                })
            }
        } else {
            this.setState({
                customersSearch: []
            })
        }
    }

    /**
     * Style event
     *
     * @param event
     * @param start
     * @param end
     * @param isSelected
     */
    eventStyleGetter(event, start, end, isSelected) {
        let backgroundColor = event.colorResources;

        let style = {
            backgroundColor: backgroundColor,
            borderRadius: '2px',
            border: '0px',
            display: 'block'
        };

        return {
            style: style
        };
    }

    /**
     * moveEvent
     *
     * @param options.event
     * @param options.start
     * @param options.end
     * @param options.isAllDay: droppedOnAllDaySlot
     */
    moveEvent({event, start, end, isAllDay: droppedOnAllDaySlot}) {
        if (event.resource == 'training') {
            const {events} = this.state;
            const idx = events.indexOf(event);
            let allDay = event.allDay;
            if (!event.allDay && droppedOnAllDaySlot) {
                allDay = true;
            } else if (event.allDay && !droppedOnAllDaySlot) {
                allDay = false;
            }
            const updatedEvent = {...event, start, end, allDay};
            const nextEvents = [...events];
            nextEvents.splice(idx, 1, updatedEvent);
            this.setState({
                events: nextEvents,
            });
            this.updateEventDates(updatedEvent);
        }
    }

    /**
     * Resize event
     *
     * @param options.event
     * @param options.start
     * @param options.end
     */
    resizeEvent = ({event, start, end}) => {
        if (event.resource == 'training') {
            const {events} = this.state;
            const nextEvents = events.map(existingEvent => {
                return existingEvent.id == event.id
                    ? {...existingEvent, start, end}
                    : existingEvent
            });
            this.setState({
                events: nextEvents,
            });
            this.updateEventDates({...event, start, end});
        }
    };


    /**
     * On select event : click to edit
     *
     * @param event
     */
    onSelectEvent = async (event) => {
        let response = await fetch(Routing.generate("calendar_event_modal", {id: event.id, resource: event.resource}), {
            method: "GET",
            credentials: 'include'
        });

        if (response.status == 200) {
            let data = await response.text();

            $('#modalEvent').replaceWith(data);

            App.initAutocomplete();
            App.initSelectPicker();
            App.initDatePicker();
            App.initCustomFileUpload();

            if (event.resource === 'training') {
                initSessionForm();
                let calendar = this;
                $("form[name='session']").on('submit', this.handleSessionSubmit.bind(this))
            }

            $('#modalEvent').modal();

        }
    };

    async handleSessionSubmit(event) {
        event.preventDefault();
        var form = new FormData(event.target);
        var url = event.target.action;
        let response = await fetch(url, {
            body: form,
            method: "POST",
        });

        if (response.status == 200) {
            var alert = "<div style='display: none' class=\"alert alert-success\" role=\"alert\">\n" +
                Translator.trans('mf.core.calendar.sessionUpdated') +
                "</div>";
            $('#session').before($(alert).fadeIn());
            setTimeout(function () {
                $('#modalEvent').modal('hide');
            }, 1500);
            this.fetchEvents();
        }
    }

    /**
     * Event fired when the select resources options change
     *
     * @param event
     */
    handleResourceChange(event) {
        let options = event.target.options;
        let resourcesIds = [];

        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                resourcesIds.push(options[i].value);
            }
        }

        this.setState({
            resourcesSelected: resourcesIds,
        }, () => this.fetchEvents());
    }

    async setResources() {
        let response = await fetch(Routing.generate("calendar_resources"), {
            method: "GET",
            credentials: 'include'
        });

        if (response.status == 200) {
            let data = await response.json();

            let currentResources = data.resources.map(r => {
                return r.id
            });

            this.setState({
                    resources: data.resources,
                    resourcesSelected: currentResources
                }, () => {
                    currentResources.forEach(r => {
                        $('#resource-' + r).attr('selected', 'selected');
                    });

                    $('#select-resources').selectpicker('refresh');
                    this.fetchEvents();
                }
            );
        }
    }

    componentDidMount() {
        this.setResources();
    }

    render() {
        let optionsTemplate = this.state.resources.map(r => (
            <option id={"resource-" + r.id} key={r.id} value={r.id}>{r.name}</option>
        ));


        return (
            <div className="col-md-12">
                <div className="card md-mb-15 card-border-color">
                    <div className="card-header">
                        <h3>{Translator.trans('mf.core.calendar.search')}</h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="select-resources col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <select id="select-resources" multiple={true}
                                        onChange={this.handleResourceChange.bind(this)} className="selectpicker"
                                        data-live-search="true">
                                    {optionsTemplate}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="calendar-container">
                            <DnDCalendar
                                selectable
                                popup
                                resizable
                                localizer={localizerFormat}
                                events={this.state.events}
                                views={viewNames}
                                defaultView={defaultView}
                                min={new Date(2017, 10, 0, 7, 0, 0)}
                                max={new Date(2017, 10, 0, 20, 0, 0)}
                                messages={this.state.messages}
                                eventPropGetter={this.eventStyleGetter}
                                onSelectEvent={this.onSelectEvent}
                                onEventResize={this.resizeEvent}
                                onEventDrop={this.moveEvent}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
