import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import SaveToDashboard from "../save-to-dashboard";

require('highcharts/modules/exporting')(Highcharts);

import Routing
    from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const routes = require("../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class EventsV2seTarget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            chartOption: [],
            dateStart: '',
            dateEnd: '',
        };

        Highcharts.SVGRenderer.prototype.symbols['c-rect'] = function (x, y, w, h) {
            return ['M', x, y + h / 2, 'L', x + w, y + h / 2];
        };

        this.getData = this.getData.bind(this);
    }

    async getData() {
        let params = {
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd
        };

        let url = Routing.generate('rh_at_type');
        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        let option = {
            chart: {
                type: "column"
            },
            title: {
                text: ""
            },
            xAxis: {
                categories: ['Visite collaborateur', 'Visite agence', 'Visite véhicule']
            },
            series: [
                {
                    name: "IDF",
                    data: [3, 4, 2],
                },
                {
                    name: "NORD EST",
                    data: [0, 2, 2],
                },
                {
                    name: "SUD EST",
                    data: [4, 3, 2],
                    drilldown: 'SUD EST'
                },
                {
                    name: "SANTE ET RISQUES PRODUITS",
                    data: [2, 2, 1],
                },
                {
                    name: "OUEST",
                    data: [3, 0, 1],
                },
                {
                    name: "SANTE ET RISQUES PRODUITS",
                    data: [1, 2, 4],
                },
            ],
            drilldown: {
                series: [{
                    id: 'SUD EST',
                    name: 'SUD EST',
                    data: [
                        {name: 'ALDA', data: [0, 0, 0]},
                        {name: 'Etudes & conseil / SSP Rhône-Alpes', data: [1, 1, 1]},
                        {name: 'Languedos Roussillon', data: [1, 0, 0]},
                        {name: 'Mesures Rhône-Alpes', data: [1, 1, 1]},
                        {name: 'PACA Corse', data: [1, 1, 0]},
                    ]
                }]
            },
            legend: {
                labelFormat: '{name}'
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Nombre'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
                    }
                }
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: '',
                    dataLabels: {
                        enabled: true,
                        color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
                    }
                }
            },
        };

        // if (response.status === 200) {
        //     let data = await response.json();
        //
        //     option.series[0].data = data.series;
        //     option.drilldown.series= data.drilldown;
        //
        //     this.setState({chartOption: option});
        // }
        this.setState({chartOption: option});
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-header ">
                    <h4>
                        Visite sécurité
                        <SaveToDashboard
                            component="dashboard-events-v2se-target"
                            size="6"
                            controller=""
                            script=""
                            title="Événement - Suivi des visites sécurité"
                        />
                    </h4>
                </div>
                <div className="card-body">
                    <HighchartsReact highcharts={Highcharts} options={this.state.chartOption}/>
                </div>
            </div>
        );
    }
}