import Routing
    from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Translator from "bazinga-translator";
import bsCustomFileInput from "bs-custom-file-input";

const routes = require("../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

const $collectionHolder = $('ul.document-types');

export default class HierarchyItemForm {

    constructor() {
        HierarchyItemForm.initHierarchyItemForm();
        HierarchyItemForm.initSectorForm();
    };

    static initHierarchyItemForm = () => {
        const $addDocumentButton = $('<button type="button" class="offset-md-4 btn btn-secondary add_document_link">' + Translator.trans('app.action.add') + '</button>');
        const $newLinkLi = $('<ul></ul>').append($addDocumentButton);

        $collectionHolder.append($newLinkLi);
        $collectionHolder.data('index', $collectionHolder.find(':input').length);

        $addDocumentButton.on('click', function (e) {
            HierarchyItemForm.addDocumentForm($collectionHolder, $newLinkLi);
        });
        $collectionHolder.find('li').each(function () {
            HierarchyItemForm.addDocumentFormDeleteLink($(this));
        });

        const $type = $('#hierarchy_item_hierarchyType');

        let isPdp = $type.find(':selected').data('is-pdp');
        if (isPdp) {
            $('.pdp-form').show();
        } else {
            $('.pdp-form').hide();
        }

        $type.change(function () {
            let isPdp = $(this).find(':selected').data('is-pdp');
            if (isPdp) {
                $('.pdp-form').show();
            } else {
                $('.pdp-form').hide();
            }

            const $form = $(this).closest('form');
            let data = {};
            data[$type.attr('name')] = $type.val();
            $.ajax({
                url: $form.attr('action'),
                type: $form.attr('method'),
                data: data,
                success: function (html) {
                    let replace = '';
                    if ($(html).find('#hierarchy_item_parent').attr('type') === 'hidden') {
                        replace = $(html).find('#hierarchy_item_parent');
                    } else {
                        replace = $(html).find('#hierarchy_item_parent').closest('.form-group')
                    }

                    if ($('#hierarchy_item_parent').attr('type') === 'hidden') {
                        $('#hierarchy_item_parent').replaceWith(replace);
                    } else {
                        $('#hierarchy_item_parent').closest('.form-group').replaceWith(replace);
                    }
                    $('.selectpicker').selectpicker('refresh');
                }
            });
        });
    };

    static addDocumentForm = ($collectionHolder, $newLinkLi) => {
        const prototype = $collectionHolder.data('prototype');
        const index = $collectionHolder.data('index');
        let newForm = prototype;
        newForm = newForm.replace(/__name__/g, index);
        $collectionHolder.data('index', index + 1);
        const $newFormLi = $('<li></li>').html("<fieldset><legend>" + Translator.trans('mf.hierarchy_item.form.documentType.new') + "</legend>" + newForm + "</fieldset>");
        $newLinkLi.before($newFormLi);
        bsCustomFileInput.init();
        App.initSelectPicker();
        HierarchyItemForm.addDocumentFormDeleteLink($newFormLi);
    };

    static addDocumentFormDeleteLink = ($documentFormLi) => {
        var $removeFormButton = $('<button type="button" class="btn btn-link" title="' + Translator.trans('app.action.delete') + '"><i class="text-danger fas fa-times"></i></button>');
        $documentFormLi.find('legend').append($removeFormButton);

        $removeFormButton.on('click', function (e) {
            $documentFormLi.remove();
        });
    };

    static initSectorForm = () => {
        $('.js-add-sector').on('click', function () {
            const hierarchyItem = $(this).data('hierarchy-item');
            const parent = $(this).data('parent');
            HierarchyItemForm.addSector(hierarchyItem, parent)
        });
        $('.js-edit-sector').on('click', function () {
            const sector = $(this).data('sector');
            HierarchyItemForm.editSector(sector)
        });
        $('.js-remove-sector').on('click', function () {
            const sector = $(this).data('sector');
            App.modalConfirmDelete(function () {
                HierarchyItemForm.removeSector(sector)
            })
        });
        $('.js-show-sectors').on('click', function () {
            const hierarchyItem = $(this).data('hierarchy-item');
            HierarchyItemForm.showSectors(hierarchyItem)
        });
    };

    static addSector = async (hierarchyItem, parent) => {
        let url = Routing.generate('sector_new', {hierarchy_item: hierarchyItem, parent: parent});
        App.loading($('#sector-modal-body'));
        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const sectorForm = await response.text();
            $('#sector-modal-body').html(sectorForm);
            bsCustomFileInput.init();
            HierarchyItemForm.handleSubmitAjax();
            HierarchyItemForm.initSectorForm();
        } else {
            console.error('Une erreur est survenue, le document n\'a pas été trouvé')
        }
    };

    static editSector = async (sector) => {
        let url = Routing.generate('sector_edit', {id: sector});
        App.loading($('#sector-modal-body'));
        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const sectorForm = await response.text();
            $('#sector-modal-body').html(sectorForm);
            bsCustomFileInput.init();
            HierarchyItemForm.handleSubmitAjax();
            HierarchyItemForm.initSectorForm();
        } else {
            console.error('Une erreur est survenue, le document n\'a pas été trouvé')
        }
    };

    static removeSector = async (sector) => {
        let url = Routing.generate('sector_delete', {id: sector});
        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });
        if (response && response.status == 200) {
            $('#sector-' + sector + '-card').remove()
        } else {
            console.error('Une erreur est survenue, le document n\'a pas été trouvé')
        }
    };

    static showSectors = async (hierarchyItem) => {
        let url = Routing.generate('hierarchy_item_sectors', {
            id: hierarchyItem,
        });

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });
        if (response.status == 200) {
            const modal = await response.text();
            if ($('#sector-modal-body').length) {
                $('#sector-modal-body').html(modal)
            }
            HierarchyItemForm.initSectorForm();
        } else {
            console.error('Une erreur est survenue, l\'agence n\'a pas été trouvé')
        }
    };

    static handleSubmitAjax = () => {
        $('form[name="sector"]').on('submit', function (e) {
            e.preventDefault();
            HierarchyItemForm.submitForm($(this), 'sector');
        });
    };

    static submitForm = async (form, name) => {
        const post_url = form.attr("action");
        const form_data = new FormData(document.forms[name]);
        App.loading($('#sector-modal-body'));
        let response = await fetch(post_url, {
            method: "POST",
            credentials: 'same-origin',
            body: form_data
        });

        if (response && response.status == 201) {
            let data = await response.json();
            HierarchyItemForm.showSectors(data.hierarchyItem)
        } else {
            console.error('Une erreur est survenue, le contact n\'a pas été trouvé')
        }
    };
}