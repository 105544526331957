import React from "react";
import {Map, Marker, Popup, TileLayer} from "react-leaflet";

import L from "leaflet";
import ParserHTML from "../components/parser";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const parserHTML = new ParserHTML();

export default class LeafletMap extends React.Component {
    render() {
        const position = [this.props.latitude, this.props.longitude];
        const popup = this.props.popup;

        return (
            <Map center={position} zoom={this.props.zoom}>
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker draggable={false} position={position}>
                    {popup && <Popup>{parserHTML.parse(popup)}</Popup>}
                </Marker>
            </Map>
        );
    }
}
