import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import SaveToDashboard from "../save-to-dashboard";

require('highcharts/modules/exporting')(Highcharts);
import Routing
    from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const routes = require("../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class EventsOverHierarchyItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            chartOption: [],
            kinds: [],
            kind: '',
        };

        this.getData = this.getData.bind(this);
    }

    async getData() {
        let params = {
            kind: this.state.kind,
        };

        let url = Routing.generate('events_over_hierarchy_item');
        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        let option = {
            chart: {
                type: "column"
            },
            title: {
                text: ""
            },
            series: [],
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    showInLegend: true
                }
            },
            xAxis: {
                categories: []
            },
            yAxis: {
                title: {
                    text: ""
                }
            },
        };

        if (response.status === 200) {
            let data = await response.json();

            option.series = data.series;
            option.xAxis.categories = data.categories;

            this.setState({chartOption: option, kinds: data.eventKind});
        }
    }


    handleSelectKind(event) {
        let kind = event.target.value;

        this.setState({
            kind: kind
        }, () => {
            this.getData();
        });
    }

    handleClickReset() {
        this.setState({
            kind: '',
        }, () => {
            this.getData();
        });

    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-header">
                    <h4>
                        Suivi des événements par site
                        <SaveToDashboard
                            component="dashboard-events-over-hierarchyItem"
                            size="6"
                            controller=""
                            script=""
                            title="Événement - Suivi des événements par site"
                        />
                    </h4>
                </div>
                <div className="card-body">
                    <form>
                        <div className="form-group row">
                            <label className="col-md-2 col-form-label col-form-label-sm"
                                   htmlFor='kindSelect'>Type</label>
                            <div className='col-md-3'>
                                <select value={this.state.kind} id='kindSelect' onChange={(event) => this.handleSelectKind(event)}
                                        className="form-control-sm form-control">
                                    {
                                        this.state.kinds.map((kind, index) => {
                                            return (<option key={index} value={kind.value}>{kind.label}</option>)
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-md-2 center">
                                <input className="btn btn-sm btn-default" type="reset" value="Vider la recherche"
                                       id="reset"
                                       name="reset" onClick={() => this.handleClickReset()}/>
                            </div>
                        </div>
                    </form>
                    <HighchartsReact highcharts={Highcharts} options={this.state.chartOption}/>
                </div>
            </div>
        );
    }
}
