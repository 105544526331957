import Routing
    from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Translator from 'bazinga-translator';
import bsCustomFileInput from "bs-custom-file-input";

const routes = require("../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

const $collectionHolder = $('#survey-answers-forms');

export default class SurveyForm {

    constructor() {
        SurveyForm.initForm();
    };

    static initForm = () => {
        const $addButton = $('<button type="button" class="offset-md-4 btn btn-secondary add_question_link">' + Translator.trans('mf.pdp.survey.layout.buttons.add_answer') + '</button>');
        const $newLinkLi = $('<ul></ul>').append($addButton);
        $collectionHolder.data('index', $collectionHolder.find(':input').length);

        $collectionHolder.append($newLinkLi);
        $addButton.on('click', function (e) {
            SurveyForm.addForm($collectionHolder, $newLinkLi);
        });
        $collectionHolder.find('li').each(function () {
            SurveyForm.addFormDeleteLink($(this));
        });
        SurveyForm.manageAnswerType();
        $("#question_answerType").on('change', function () {
            SurveyForm.manageAnswerType();
        });
    }

    static addForm = ($collectionHolder, $newLinkLi) => {
        const prototype = $collectionHolder.data('prototype');
        const index = $collectionHolder.data('index');
        let newForm = prototype;
        newForm = newForm.replace(/__name__/g, index);
        $collectionHolder.data('index', index + 1);
        const $newFormLi = $('<li></li>').append(newForm);
        $newLinkLi.before($newFormLi);
        SurveyForm.addFormDeleteLink($newFormLi);
    }

    static addFormDeleteLink = ($formLi) => {
        const $removeFormButton = $('<button type="button" class="offset-lg-4  btn btn-primary">' + Translator.trans('mf.pdp.survey.layout.buttons.remove_answer') + '</button>');
        $formLi.append($removeFormButton);
        $removeFormButton.on('click', function (e) {
            $formLi.remove();
        });
    }

    static manageAnswerType = () => {
        if ($("#question_answerType").val() == "FREE") {
            $('#answers-panel').hide('fade');
        } else {
            $('#answers-panel').show('fade');
        }

        if ($("#question_answerType").val() == "MULTIPLE") {
            $('#question_displayLabel').parents().eq(1).show('fade');
        } else {
            $('#question_displayLabel').parents().eq(1).hide('fade');
        }
    }
}