import React, {Component} from 'react';
import {css} from '@emotion/core';
import {FadeLoader} from "react-spinners";
import ReactPaginate from 'react-paginate';
import Translator from 'bazinga-translator';

const routes = require('../../../../../../../js/fos_js_routes.json');
import Routing from '../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

Routing.setRoutingData(routes);

const override = css`
    display: block;
    margin: 0 auto;
    border-color: blue;
`;

export default class QuizResultList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingSpinner: false,
            resultsPerPage: 5,
            resultsOffsetPage: 0,
            nbTotalResultsPage: 1,
            results: [],
            quiz: props.quiz
        };
    }

    componentDidMount() {
        this.loadResults();
    }

    async loadResults() {

        this.setState({
            loadingSpinner: true
        });

        let params = {
            limit: this.state.resultsPerPage,
            offset: this.state.resultsOffsetPage,
            id: this.props.quiz
        };

        let url = Routing.generate('admin_quiz_results', params);

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin'
        });

        if (response.status == 200) {
            let responseData = await response.json();

            let nbPage = Math.ceil(responseData.nbResults / this.state.resultsPerPage);

            this.setState({
                results: responseData.results,
                nbTotalResultsPage: nbPage,
                loadingSpinner: false
            });
        }
    }

    handleClickPages(page) {
        let offset = Math.ceil(page.selected * this.state.resultsPerPage);

        this.setState({resultsOffsetPage: offset}, () => {
            this.loadResults();
        });
    }

    handleSelectNbItem(event) {
        this.setState({resultsPerPage: event.target.value}, () => {
            this.loadResults();
        });
    }

    render() {
        let listingResults = this.state.results.map((result, index) => {
            return (
                <tr key={index}>
                    <td>
                        <a href={result.url}>{result.userName}</a>
                    </td>
                    <td>
                        <a href={result.url}>{result.date}</a>
                    </td>
                    <td>
                        {
                            result.isNoted ?
                                <span className={'badge badge-' + result.noteColor}>{result.note}/10</span>
                                :
                                <span className="badge badge-danger">
                                    <i className="fas fa-times-circle"></i> {Translator.trans('mf.activity_map.quiz.layout.waitingNote')}
                                </span>

                        }
                    </td>
                </tr>
            )
        });

        return (
            <div className="card mb-4">

                <div className="card-header">
                    {Translator.trans('mf.activity_map.quiz.layout.allResults')}
                </div>

                <div className="card-body p-0">
                    <div className="table-responsive">
                        <table className="table table-hover table-striped m-0">
                            <thead>
                            <tr>
                                <th>
                                    {Translator.trans('mf.activity_map.quiz.layout.userName')}
                                </th>
                                <th>
                                    {Translator.trans('mf.activity_map.quiz.layout.date')}
                                </th>
                                <th>
                                    {Translator.trans('mf.activity_map.quiz.layout.note')}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <FadeLoader
                                css={override}
                                sizeUnit={"px"}
                                size={500}
                                color={'#004788'}
                                loading={this.state.loadingSpinner}
                            />

                            {listingResults}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-md-4">
                            <ReactPaginate
                                breakClassName="page-item"
                                breakLabel={<span className="page-link">...</span>}
                                pageClassName="page-item"
                                previousLabel="Précédente"
                                previousClassName="page-item"
                                nextClassName="page-item"
                                nextLabel="Suivante"
                                pageLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                pageCount={this.state.nbTotalResultsPage}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handleClickPages.bind(this)}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                            />
                        </div>
                        <div className="offset-4 col-md-2">
                            <select className="form-control float-right" onChange={this.handleSelectNbItem.bind(this)}>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}