import React, {Component} from 'react'
import {Calendar as BigCalendar, momentLocalizer} from 'react-big-calendar'
import ResourceColorPicker from "./ResourceColorPicker";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from 'moment';
import Translator from 'bazinga-translator';
import Routing  from "../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import ProjectTask from "../ProjectTask";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ParserHTML from "../../parser";
import ConfirmationModal from "../../ConfirmationModal";

let parserHTML = new ParserHTML();

const routes = require("../../../../../../../../js/fos_js_routes.json");

Routing.setRoutingData(routes);

const DnDCalendar = withDragAndDrop(BigCalendar);

moment.locale('fr');
const localizerFormat = momentLocalizer(moment);

const views = {
    MONTH: 'month',
    WEEK: 'week',
    WORK_WEEK: 'work_week',
    DAY: 'day',
    AGENDA: 'agenda'
};

const viewNames = Object.keys(views).map(function (k) {
    return views[k];
});

let defaultView = "work_week";
let workWeekMessage = Translator.trans('mf.core.calendar.work_week');

export default class ProjectCalendar extends Component {
    constructor(props) {
        super(props);

        if (props.workWeek) {
            viewNames.splice(viewNames.indexOf('week'), 1);
            workWeekMessage = Translator.trans('mf.core.calendar.week');
        } else {
            viewNames.splice(viewNames.indexOf('work_week'), 1);
            defaultView = "week";
        }


        this.state = {
            displayColorPicker: false,
            range: {
                start: moment().subtract(7, 'days').toDate(),
                end: moment().add(7, 'days').toDate()
            },
            tasks: [],
            task: null,
            resources: [],
            projects: [],
            resourcesSelected: ['all'],
            projectsSelected: ['all'],
            customersSearch: [],
            messages: {
                allDay: Translator.trans('mf.core.calendar.allDay'),
                previous: Translator.trans('mf.core.calendar.previous'),
                next: Translator.trans('mf.core.calendar.next'),
                today: Translator.trans('mf.core.calendar.today'),
                month: Translator.trans('mf.core.calendar.month'),
                week: Translator.trans('mf.core.calendar.week'),
                work_week: workWeekMessage,
                day: Translator.trans('mf.core.calendar.day'),
                agenda: Translator.trans('mf.core.calendar.agenda'),
                date: Translator.trans('mf.core.calendar.date'),
                time: Translator.trans('mf.core.calendar.time'),
                event: Translator.trans('mf.core.calendar.event'),
                noEventsInRange: Translator.trans('mf.core.calendar.noEventsInRange'),
                showMore: total => Translator.trans('mf.core.calendar.moreEvents', {'total': total})
            },
            workAccidentModalForm: null,
            workAccidentModalIsOpen: false,
            workAccidentMode: false
        };

        this.moveTask = this.moveTask.bind(this);
        this.taskStyleGetter = this.taskStyleGetter.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    fetchTasks = async () => {

        let startDate;
        let endDate;
        if (this.state.range.start) {
            startDate = this.state.range.start;
        } else {
            startDate = this.state.range[0];
        }

        if (this.state.range.end) {
            endDate = this.state.range.end;
        } else {
            endDate = this.state.range[this.state.range.length - 1];
        }

        let response = await fetch(Routing.generate("project_calendar_tasks", {
            resources: this.state.resourcesSelected,
            start: moment(startDate).format('YYYY-MM-DD HH:mm'),
            end: moment(endDate).format('YYYY-MM-DD HH:mm'),
            projects: this.state.projectsSelected
        }), {
            method: "GET",
            credentials: 'include'
        });

        if (response.status == 200) {
            let data = await response.json();

            let formattedTasks = [];
            for (let curTask of data.tasks) {
                curTask.start = moment(curTask.start).toDate();
                curTask.end = moment(curTask.end).toDate();
                formattedTasks.push(curTask);
            }

            this.setState({
                tasks: formattedTasks,
                resources: data.resources,
                projects: data.projects,
            }, () => {
                if (this.state.resourcesSelected.includes('all')) {
                    const allResources = this.state.resources.map(item => {
                        return item.id;
                    })
                    this.setState({
                        resourcesSelected: allResources
                    }, () => {
                        $('.selectpicker').selectpicker('refresh');
                    })
                } 

                if (this.state.projectsSelected.includes('all')) {
                    const allProjects = this.state.projects.map(item => {
                        return item.id;
                    })
                    this.setState({
                        projectsSelected: allProjects
                    }, () => {
                        $('.selectpicker').selectpicker('refresh');
                    })
                } 

                $('.selectpicker').selectpicker('refresh');
            })
        }
    }

    /**
     * updateTaskDates when moving or resizing task
     *
     * @param updatedTask
     */
    async updateTaskDates(updatedTask) {
        let start = moment(updatedTask.start);
        let end = moment(updatedTask.end);
        let response = await fetch(Routing.generate(updatedTask.isWorkAccident ? "project_calendar_work_accident_update_dates" : "project_task_update_dates", {
                id: updatedTask.id,
                start: start.format('YYYY-MM-DD HH:mm'),
                end: end.format('YYYY-MM-DD HH:mm')
            }),
            {
                method: "POST",
                credentials: 'include'
            });

        if (response.status == 200) {
            return true;
        } else if (response.status == 403) {
            App.modalAlert('Vous n\'avez pas les autorisations nécessaires')
            this.fetchTasks();
            return false;
        } else if(response.status == 401) {
            const error = await response.json();

            App.modalAlert(Translator.trans(error.message));
            this.fetchTasks();
        }
    }

    /**
     * Style task
     *
     * @param task
     * @param start
     * @param end
     * @param isSelected
     */
    taskStyleGetter(task, start, end, isSelected) {
        let backgroundColor = task.colorResources;

        let style = {
            backgroundColor: backgroundColor,
            borderRadius: '3px',
            border: '1px solid',
            borderColor: 'white',
            display: 'block'
        };

        return {
            style: style,
        };
    }

    /**
     * moveTask
     *
     * @param options.event
     * @param options.start
     * @param options.end
     * @param options.isAllDay: droppedOnAllDaySlot
     */
    moveTask({event, start, end, isAllDay: droppedOnAllDaySlot}) {
        const {tasks} = this.state;
        const idx = tasks.indexOf(event);
        let allDay = event.allDay;
        if (!event.allDay && droppedOnAllDaySlot) {
            allDay = true;
        } else if (event.allDay && droppedOnAllDaySlot === undefined) {
            allDay = false;
        }
        const updatedTask = {...event, start, end, allDay};
        const nextTasks = [...tasks];
        nextTasks.splice(idx, 1, updatedTask);

        const updated = this.updateTaskDates(updatedTask)
        if (updated) {
            this.setState({
                tasks: nextTasks,
            });
            return true;
        } else {
            return false;
        }
    }

    /**
     * Resize task
     *
     * @param options.task
     * @param options.start
     * @param options.end
     */
    resizeTask = ({event, start, end}) => {
        const {tasks} = this.state;
        const nextTasks = tasks.map(existingTask => {
            return existingTask.id == event.id
                ? {...existingTask, start, end}
                : existingTask
        });

        if (this.updateTaskDates({...event, start, end})) {
            this.setState({
                tasks: nextTasks,
            });
            return true;
        } else {
            return false;
        }
    };

    /**
     * On select task : click to edit
     *
     * @param task
     */
    onSelectTask = async (task) => {
        const isWorkAccident = task.isWorkAccident;

        let url = Routing.generate("project_calendar_edit_task", {id: task.id});

        if(isWorkAccident) {
            url = Routing.generate("project_calendar_work_accident_edit", {id: task.id}); 
        }

        const response = await fetch(url, {
            method: "GET",
            credentials: 'include'
        });

        if (response.status == 200) {
            const data = await response.text();

            if(isWorkAccident) {
                this.handleWorkAccidentModal(true, data, task);
            } else {
                $('#modalTask').html(data);
                this.initFormField($('#modalTask'));
                $('#modalTask').modal();
            }
        }
    };

    /**
     * On select task : click to edit
     *
     * @param task
     */
    onSelectSlot = async ({start, end}) => {
        const workAccidentMode = this.state.workAccidentMode;

        const response = await fetch(Routing.generate(workAccidentMode ? "project_calendar_work_accident_add" : "project_calendar_add_task", {
            start: moment(start).format('YYYY-MM-DD HH:mm'),
            end: moment(end).format('YYYY-MM-DD HH:mm')
        }), {
            method: "GET",
            credentials: 'include'
        });

        if (response.status == 200) {
            const data = await response.text();

            if(workAccidentMode) {
                this.handleWorkAccidentModal(true, data, null);
            } else {
                $('#modalTask').html(data);
                this.initFormField($('#modalTask'));
                $('#modalTask').modal();
            }
        }
    };

    submitWorkAccident = async () => {
        const task = this.state.task;

        let url = Routing.generate('project_calendar_work_accident_add');

        if(task != null) {
            url = Routing.generate('project_calendar_work_accident_edit', {id: task.id});
        }

        const response = await fetch(url, {
            method: "POST",
            body: new FormData(document.getElementById("work_accident_calendar")),
            credentials: "same-origin"
        });

        if (response.status == 201) {
            this.handleWorkAccidentModal(false, null, null);
            this.fetchTasks();
        } else {
            const data = await response.text();

            this.handleWorkAccidentModal(true, data, task);
        }
    }

    deleteWorkAccident = async () => {
        const task = this.state.task;
        const response = await fetch(Routing.generate('project_calendar_work_accident_delete', {id: task.id}), {
            method: "GET",
            credentials: "same-origin"
        });

        if (response.status == 201) {
            this.handleWorkAccidentModal(false, null, null);
            this.fetchTasks();
        }
    }

    async handleSubmit(event) {
        $('#modalTask').unbind('hidden.bs.modal')
        event.preventDefault();
        var form = new FormData(event.target);
        var url = event.target.action;
        let response = await fetch(url, {
            body: form,
            method: "POST",
        });

        if (response.status == 201) {
            $('#modalTask').modal('hide');
            this.fetchTasks();
        } else if (response.status == 403) {
            $('#modalTask').modal('hide');
            App.modalAlert('Vous n\'avez pas les autorisations nécessaires')
        } else {
            const data = await response.text();

            $('#modalTask').html(data);
            this.initFormField($('#modalTask'));
            $('#modalTask').modal();
        }
    }

    initFormField($modal) {
        App.initAutocomplete();
        App.initSelectPicker();
        App.initDatePicker();
        $modal.find('form').on('submit', this.handleSubmit)
        $modal.unbind('hidden.bs.modal')
        $modal.on('hidden.bs.modal', () => this.fetchTasks())
        $modal.find('.js-scheduled-task-delete').on('click', function () {
            const scheduledTask = $(this).data('scheduled-task');
            App.modalConfirmDelete(async () => {
                if (await ProjectTask.removeScheduledTask(scheduledTask)) {
                    $modal.modal('hide');
                }
            });
        });

        const $project = $modal.find('#calendar_scheduled_task_project');
        $project.change(function () {
            const $form = $(this).closest('form');
            let data = {};
            data[$project.attr('name')] = $project.val();
            $.ajax({
                url: $form.attr('action'),
                type: $form.attr('method'),
                data: data,
                success: function (html) {
                    $modal.find('#calendar_scheduled_task_task').closest('.form-group').replaceWith(
                        $(html).find('#calendar_scheduled_task_task').closest('.form-group')
                    );
                    $modal.find('#calendar_scheduled_task_member').closest('.form-group').replaceWith(
                        $(html).find('#calendar_scheduled_task_member').closest('.form-group')
                    );
                    $('.selectpicker').selectpicker('refresh');
                }
            });
        });
    }

    /**
     * Task fired when the select resources options change
     *
     * @param event
     */
    handleResourceChange(event) {
        let options = event.target.options;
        let resourcesIds = [];

        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                resourcesIds.push(options[i].value);
            }
        }

        this.setState({
            resourcesSelected: resourcesIds,
        }, () => this.fetchTasks());
    }


    /**
     * @param event
     */
    handleProjectChange(event) {
        let options = event.target.options;
        let ids = [];

        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                ids.push(options[i].value);
            }
        }

        this.setState({
            projectsSelected: ids,
        }, () => this.fetchTasks());
    }

    handleColorClick = () => {
        this.setState({displayColorPicker: !this.state.displayColorPicker})
    };

    handleRangeChange = (range) => {
        this.setState({range: range}, () => this.fetchTasks());
    };

    handleWorkAccidentModal = (value, form, task) => {
        this.setState({
            workAccidentModalForm: form != null ? parserHTML.parse(form) : null,
            workAccidentModalIsOpen: value,
            task: task,
        });
    }

    componentDidMount() {
        this.fetchTasks();
    }

    render() {
        let optionsTemplate = this.state.resources.map(r => (
            <option id={"resource-" + r.id} key={r.id} value={r.id}>{r.fullName}</option>
        ));

        let projectOptions = this.state.projects.map(p => (
            <option id={"project-" + p.id} key={p.id} value={p.id}>{p.label}</option>
        ));

        let resourcesColorPicker = this.state.resources.map(r => (
            <ResourceColorPicker
                id={"color-resource-" + r.id}
                key={"color-resource-" + r.id}
                resource={r}
                onClose={() => {
                    this.fetchTasks();
                }}
            />
        ))

        return (
            <div className="col-md-12">
                <div className="card md-mb-15 card-border-color">
                    <div className="card-header">
                        <h4>
                            {Translator.trans('mf.core.calendar.search')}
                            <button className="tools btn btn-link" onClick={this.handleColorClick}>
                                <span className="icon fas fa-cog"></span>
                            </button>
                        </h4>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="select-resources col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <select id="select-resources" multiple={true}
                                        onChange={this.handleResourceChange.bind(this)} className="selectpicker"
                                        data-live-search="true" data-actions-box="true"
                                        value={this.state.resourcesSelected}>
                                    {optionsTemplate}
                                </select>
                            </div>
                            <div className="select-projects col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <select id="select-projects" multiple={true}
                                        onChange={this.handleProjectChange.bind(this)} className="selectpicker"
                                        data-live-search="true" data-actions-box="true"
                                        value={this.state.projectsSelected}>
                                    {projectOptions}
                                </select>
                            </div>
                            {this.state.displayColorPicker ?
                                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                    {resourcesColorPicker}
                                </div> : null
                            }
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="text-center">
                            <label>
                                Ajouter une période d'indisponibilité
                                <input type="checkbox" checked={this.state.workAccidentMode}  onChange={(event) => this.setState({ workAccidentMode: event.target.checked})} />
                            </label>
                        </div>

                        <div className="calendar-container">
                            <DnDCalendar
                                selectable
                                popup
                                resizable
                                localizer={localizerFormat}
                                events={this.state.tasks}
                                min={new Date(2010, 10, 0, 7, 0, 0)}
                                max={new Date(2040, 10, 0, 20, 0, 0)}
                                views={viewNames}
                                defaultView={defaultView}
                                messages={this.state.messages}
                                eventPropGetter={this.taskStyleGetter}
                                onSelectEvent={this.onSelectTask}
                                onSelectSlot={this.onSelectSlot}
                                onEventResize={this.resizeTask}
                                onEventDrop={this.moveTask}
                                onRangeChange={this.handleRangeChange}
                                tooltipAccessor={(event) => event.title + " - " + event.assignedTo}
                            />
                        </div>
                    </div>
                </div>

                <div id="modalTask" className="modal fade"></div>

                <Modal size="lg" isOpen={this.state.workAccidentModalIsOpen} toggle={this.handleWorkAccidentModal.bind(this, false, null, null)}>
                    <ModalHeader toggle={this.handleWorkAccidentModal.bind(this, false, null, null)}>Arrêt de travail</ModalHeader>
                    <ModalBody>
                        {this.state.workAccidentModalForm}
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-primary" onClick={this.submitWorkAccident.bind(this)}>
                            Valider
                        </button>
                        <ConfirmationModal
                            action={this.deleteWorkAccident.bind(this)}
                            className="btn btn-danger"
                            message="Êtes-vous sûr de vouloir supprimer ?"
                            modalTitle="Supprimer"
                            text="Supprimer"
                            title="Supprimer"
                        />
                        <button className="btn btn-default" onClick={this.handleWorkAccidentModal.bind(this, false, null, null)}>
                            Annuler
                        </button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
