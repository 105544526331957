import React, {Component} from "react";
import {connect} from "react-redux";

/**
 * Basic component for select field
 */
class SelectField extends Component {
    constructor(props) {
        super(props);

        let multiple = props.attribs.multiple === "multiple" ? true : false;
        let value = multiple ? [] : props.attribs.value;

        props.children.forEach(c => {
            if (c.name === "optgroup") {
                c.children.forEach(c2 => {
                    if (c2.attribs.selected === "selected") {
                        if (multiple) {
                            value.push(c2.attribs.value);
                        } else {
                            value = c2.attribs.value;
                        }
                    }
                })
            } else {
                if (c.attribs.selected === "selected") {
                    if (multiple) {
                        value.push(c.attribs.value);
                    } else {
                        value = c.attribs.value;
                    }
                }
            }
        });


        let name = this.props.attribs.name;
        let latest = name.split("[");
        let field = latest[latest.length - 1];

        field = field.slice(0, field.length - 1);

        this.state = {
            multiple: multiple,
            value: value,
            field: field,
            name: name
        };
    }

    componentDidMount() {
        App.initSelectPicker();
        this.dispatch();
    }

    componentDidUpdate(prevProps) {
        if (this.props.inputsToClear.length > 0) {
            if (this.props.inputsToClear.includes(this.props.attribs.id)) {
                this.setState({value: this.state.multiple ? [] : ''})
            }
            this.dispatch();
            this.props.dispatch({type: 'setInputEmpty', value: []})
            $('#' + this.props.attribs.id).selectpicker('refresh')
        }
    }

    dispatch() {
        let setterReducer = "set" + this.state.field.charAt(0).toUpperCase() + this.state.field.slice(1);
        this.props.dispatch({type: setterReducer, value: this.state.value});
    }

    onChange = (e) => {
        this.setState(
            {
                value: this.state.multiple ? Array.from(e.target.selectedOptions, option => option.value) : e.target.value
            },
            () => {
                let setterReducer = "set" + this.state.field.charAt(0).toUpperCase() + this.state.field.slice(1);
                this.props.dispatch({type: setterReducer, value: this.state.value});
            }
        );
    };

    render() {
        let options = this.props.children.map((c, index) => {
            if (c.name === 'optgroup') {
                return (
                    <optgroup label={c.attribs.label} key={index}>
                        {
                            c.children.map((c2, index2) => {
                                return (
                                    <option key={index + index2}
                                            value={c2.attribs.value}
                                            data-icon={c2.attribs["data-icon"]}
                                            data-content={c2.attribs["data-content"]}>
                                        {c2.children.length > 0 ? c2.children[0].data : ""}
                                    </option>
                                );
                            })
                        }
                    </optgroup>
                )
            } else {
                return (
                    <option key={index}
                            value={c.attribs.value}
                            data-icon={c.attribs["data-icon"]}
                            data-content={c.attribs["data-content"]}>
                        {c.children.length > 0 ? c.children[0].data : ""}
                    </option>
                );
            }
        });

        return (
            <select
                value={this.state.value}
                id={this.props.attribs.id}
                name={this.props.attribs.name}
                className={this.props.attribs.class}
                data-live-search={this.props.attribs["data-live-search"]}
                data-actions-box={this.props.attribs["data-actions-box"]}
                required={this.props.attribs.required}
                multiple={this.state.multiple ? "multiple" : ""}
                onChange={this.onChange.bind(this)}
            >
                {options}
            </select>
        );
    }
}

export default connect(state => state.formReducer)(SelectField);
