import React from 'react';
import SaveToDashboard from '../../save-to-dashboard';

require('highcharts/modules/exporting')(Highcharts);

import Routing
    from "../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const routes = require("../../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class FormationState extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            chartOption: [],
            services: [],
            service: 0
        };

        this.getData = this.getData.bind(this);
    }

    async getData() {

        let params = {
            service: this.state.service
        };

        let url = Routing.generate('rh_formation_state');
        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        let option = {
            chart: {
                type: "pie"
            },
            title: {
                text: ""
            },
            series: [
                {
                    name: "Nombre de formation",
                    data: [],
                    dataLabels: {
                        format: '{point.percentage:.2f} %'
                    },
                }],
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    showInLegend: true
                }
            },
            legend: {
                labelFormat: '{name} ({y})'
            },
            yAxis: {
                title: {
                    text: "Nombre"
                }
            },
        };


        if (response.status === 200) {
            let data = await response.json();
            this.setState({services: data.services});

            option.series[0].data = data.informations;

            this.setState({chartOption: option});
        }
    }

    handleClickReset() {
        this.setState({
            service: 0
        }, () => {
            this.getData();
        });

    }

    handleSelectService(event) {
        let service = event.target.value;

        this.setState({
            service: service
        }, () => {
            this.getData();
        });
    }


    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-header ">
                    <h4>
                        État des formations
                        <SaveToDashboard
                            component="dashboard-form-state"
                            size="6"
                            controller=""
                            script=""
                            title="RH - État des formations"
                        />
                    </h4>
                </div>
                <div className="card-body">
                    <form>
                        <div className="form-group row">
                            <label className="col-md-2 col-form-label" htmlFor="form-state-service">
                                Service
                            </label>
                            <div className="col-sm-3">
                                <select value={this.state.service} id="form-state-service" onChange={(event) => this.handleSelectService(event)} className="form-control form-control-sm">
                                    {
                                        this.state.services.map((service,index) => {
                                            return (<option key={index} value={service.id}>{service.name}</option>)
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-md-2 center">
                                <input className="btn btn-sm btn-default" type="reset" value="Vider la recherche" id="reset"
                                       name="reset" onClick={() => this.handleClickReset()}/>
                            </div>
                        </div>
                    </form>
                    <HighchartsReact highcharts={Highcharts} options={this.state.chartOption}/>
                </div>
            </div>
        );
    }
}