import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import ProjectCalendar from "./Calendar/ProjectCalendar";
import Gantt from "./gantt";
import ProjectComment from "./ProjectComment";
import ProjectDocument from "./ProjectDocument";
import ProjectMember from "./ProjectMember";
import ProjectTask from "./ProjectTask";
import FormStore from "../../stores/FormStore";

export default class ProjectManager {
    constructor() {
        const gantt = document.getElementById("project-gantt");
        if (gantt) {
            render(<Gantt project={gantt.dataset.project} />, gantt);
        }

        const calendar = document.getElementById("project-calendar");
        if (calendar) {
            render(
                <Provider store={FormStore}>
                    <ProjectCalendar workWeek="1" />
                </Provider>,
                calendar
            );
        }

        if ($("#project-page").length) {
            new ProjectMember();
            new ProjectDocument();
            new ProjectTask();
        }
        if ($("#project-comment-list").length) {
            new ProjectComment();
        }
    }
}
