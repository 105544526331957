import React from "react";

export default class FlashNotification extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            notifications: []
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            notifications: props.notifications
        });
    }

    handleRemoveNotification(index) {
        let notifications = [...this.state.notifications];
        notifications.splice(index, 1);

        this.setState({
           notifications: notifications
        });
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    {
                        this.state.notifications.map((notification, index) => {
                            return (
                                <div key={ index } className={ notification.type === "success" ? "alert alert-success" : "alert alert-danger" } role="alert">
                                    { notification.message }
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.handleRemoveNotification.bind(this, index)}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}