import Routing
    from '../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
import SimpleBar from "simplebar";

const routes = require('../../../../../../../js/fos_js_routes.json');

Routing.setRoutingData(routes);

export default class ProjectComment {

    constructor() {
        ProjectComment.initCommentForm();
    }

    static initCommentForm = () => {

        $('form[name="comment"]').unbind('submit')
        $('.js-add-project-comment').unbind('click')
        $('.js-delete-project-comment').unbind('click')
        $('.js-delete-project-comment').unbind('click')

        if (!$('#ajax-modal').length) {
            $('body').append("<div class='modal fade' role='dialog' id='ajax-modal'></div>")
        }
        new SimpleBar(document.getElementById('project-comment-list'), {autoHide: false});
        $('form[name="comment"]').on('submit', function (e) {
            e.preventDefault();
            ProjectComment.addComment($(this), 'comment');
        });
        $('.js-add-project-comment').on('click', function (e) {
            e.preventDefault();
            const project = $(this).data('project');
            const task = $(this).data('task');
            $('#comment-submit').prop('disabled', true);
            ProjectComment.addComment(project, task);
        });
        $('.js-edit-project-comment').on('click', function (e) {
            e.preventDefault();
            const comment = $(this).data('project-comment');
            ProjectComment.editComment(comment);
        });
        $('.js-delete-project-comment').on('click', function (e) {
            e.preventDefault();
            const comment = $(this).data('project-comment');
            App.modalConfirmDelete(function () {
                ProjectComment.removeComment(comment);
            });
        });
    };

    static addComment = async (form, name) => {
        const post_url = form.attr("action");
        const form_data = new FormData(document.forms[name]);
        $('#comment_text').val('');
        let response = await fetch(post_url, {
            method: "POST",
            credentials: 'same-origin',
            body: form_data
        });
        if (response && response.status == 200) {
            const comment = await response.text();
            $('#comment-submit').prop('disabled', false);
            if ($('#project-comment-list').find('.simplebar-content').length) {
                $('#project-comment-list').find('.simplebar-content').prepend(comment);
            } else {
                $('#project-comment-list').prepend(comment);
            }
            ProjectComment.initCommentForm();
        } else {
            console.error('Une erreur est survenue, le projet n\'a pas été trouvé')
        }
    };


    static editComment = async (comment) => {
        App.loading($('#ajax-modal .modal-body'));
        let url = Routing.generate('project_comment_edit', {id: comment});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const form = await response.text();
            $('#ajax-modal').html(form);
            App.initCustomFileUpload();
            $('#ajax-modal').modal();
            ProjectComment.handleSubmitAjax();
        } else {
            console.error('Une erreur est survenue, le membre n\'a pas été trouvé')
        }
    };

    static removeComment = async (comment) => {
        let url = Routing.generate('project_comment_remove', {id: comment});

        let response = await fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            $('#project-comment-' + comment).remove()
        } else {
            console.error('Une erreur est survenue, le document n\'a pas été supprimé')
        }
    };


    static handleSubmitAjax = () => {
        $('form[name="comment_edit"]').on('submit', function (e) {
            e.preventDefault();
            ProjectComment.submitForm($(this), 'comment_edit');
        });
    };

    static submitForm = async (form, name) => {
        const post_url = form.attr("action");
        const form_data = new FormData(document.forms[name]);
        App.loading($('#ajax-modal .modal-body'));
        let response = await fetch(post_url, {
            method: "POST",
            credentials: 'same-origin',
            body: form_data
        });

        if (response && response.status == 201 || response.status == 200) {
            if (response.status == 201) {
                let data = await response.json()
                window.location.reload();
            } else if (response.status == 200) {
                const form = await response.text();
                $('#ajax-modal').html(form);
                ProjectComment.handleSubmitAjax();
            }
        } else {
            console.error('Une erreur est survenue, le projet n\'a pas été trouvé')
        }
    }
}
