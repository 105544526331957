import React, {Component} from 'react';
import Routing
    from '../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

import {Row, Col, FormGroup, Button, Label, Input, ListGroup, ListGroupItem} from 'reactstrap'
import ReactPaginate from "react-paginate";
import Translator from "bazinga-translator";

const routes = require('../../../../../../../../js/fos_js_routes.json');
Routing.setRoutingData(routes);

export default class CompanySearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: '',
            page: 0,
            nbItems: 0,
            tiers: [],
            displayCreate: false,
        }

        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.handleSearchClick = this.handleSearchClick.bind(this)
        this.handleCreate = this.handleCreate.bind(this)
    }

    handleSearchChange(e) {
        this.setState({search: e.target.value});
    }

    handleSearchClick(e) {
        this.setState({displayCreate: true}, () => {
            this.searchTiers()
        });
    }

    handleSelect = (tiers) => {
        this.props.onHandleSelect(tiers.id)
    }

    handleCreate = () => {
        this.props.onHandleCreate(true)
    }

    searchTiers = async () => {
        let url = Routing.generate('pdp_tiers_search', {searchName: this.state.search, page: this.state.page + 1});
        let response = await fetch(url, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response.status === 200) {
            let responseData = await response.json();

            this.setState({tiers: JSON.parse(responseData.tiers), nbItems: responseData.nbItems, page: 0})
        }
    }

    handleClickPages(page) {
        this.setState({page: page.selected}, () => {
            this.searchTiers();
        });
    }

    render() {
        let span = <span className="page-link">...</span>;
        return <>
            <FormGroup row>
                <Label sm={2} for="search">Nom</Label>
                <Col sm={7}>
                    <Input id="search" type="text" value={this.state.search} onChange={this.handleSearchChange}/>
                </Col>
                <Col sm={3}>
                    <Button onClick={this.handleSearchClick}>Rechercher</Button>
                </Col>
            </FormGroup>
            {
                this.state.displayCreate === true ?
                    <>
                        <ListGroup striped>
                            {
                                this.state.tiers.length ?
                                    this.state.tiers.map((tiers) => {
                                        return <ListGroupItem tag="button" key={tiers.id} action
                                                              onClick={this.handleSelect.bind(this, tiers)}>
                                            {tiers.name}
                                        </ListGroupItem>
                                    })
                                    :
                                    <ListGroupItem>Aucun résultat</ListGroupItem>
                            }
                        </ListGroup>
                        <Row className="mt-3">
                            <Col sm={{size: 4, offset: 2}} className="text-center">
                                <ReactPaginate
                                    breakClassName="page-item"
                                    breakLabel={span}
                                    pageClassName="page-item"
                                    previousClassName="page-item"
                                    previousLabel={Translator.trans("app.pagination.previous")}
                                    nextLabel={Translator.trans("app.pagination.next")}
                                    nextClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    pageCount={this.state.nbItems / 10}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handleClickPages.bind(this)}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                /></Col>
                            <Col sm={6} className="text-center">
                                <Button
                                    onClick={this.handleCreate}>{Translator.trans('mf.pdp.form.intervention.tiers_new')}</Button>
                            </Col>
                        </Row>
                    </>
                    :
                    ''
            }
        </>
    }
}