import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import SaveToDashboard from "../../save-to-dashboard";

require('highcharts/modules/exporting')(Highcharts);
import Routing
    from "../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const routes = require("../../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);


//Work interruption breakdown
export default class AtWiBreakdown extends React.Component {

    constructor(props) {
        super(props);

        let currentYear = new Date().getFullYear();

        this.state = {
            services: [],
            years: [
                {
                    id: 1,
                    year: currentYear
                },
                {
                    id: 2,
                    year: currentYear - 1
                },
                {
                    id: 3,
                    year: currentYear - 2
                }
            ],
            segmentations: [
                {
                    id: 1,
                    value: 'month',
                    label: "Mensuelle"
                },
                {
                    id: 2,
                    value: 'year',
                    label: "Annuelle"
                }
            ],
            types: [
                {
                    id: 1,
                    value: 'normal',
                    label: 'Empilé'
                },
                {
                    id: 2,
                    value: 'percent',
                    label: 'Pourcentage'
                },
                {
                    id: 3,
                    value: '',
                    label: 'Simple'
                }
            ],
            chartOption: [],
            segmentation: 'month',
            stacking: 'normal',
            year: '',
            service: 0,
        };

        this.getDataForMonth = this.getDataForMonth.bind(this);
    }

    async getDataForMonth() {

        let params = {
            service: this.state.service,
            segmentation: this.state.segmentation,
            year: this.state.year,
        };

        let url = Routing.generate('rh_at_wi_breakdown');
        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        let option = {
            chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: [
                    'Janvier', 'Février',
                    'Mars', 'Avril',
                    'Mai', 'Juin',
                    'Juillet', 'Août',
                    'Septembre', 'Octobre',
                    'Novembre', 'Décembre']
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Nombre d\'AT'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
                    }
                }
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: '',
                    dataLabels: {
                        enabled: true,
                        color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
                    }
                }
            },
            series: []
        };


        if (response.status === 200) {
            let data = await response.json();

            this.setState({services: data.services});

            option.series = data.informations;

            option.plotOptions.column.stacking = this.state.stacking;

            if (this.state.stacking == 'percent') {
                option.series = option.series.filter(function (item) {
                    item.dataLabels = {format: ''};
                    item.dataLabels.format = '{point.percentage:.2f} %';
                    return item;
                });
            } else {
                option.series = option.series.filter(function (item) {
                    item.dataLabels = '';
                    return item;
                });
            }

            if (this.state.segmentation == 'month') {
                option.xAxis.categories = [
                    'Janvier', 'Février',
                    'Mars', 'Avril',
                    'Mai', 'Juin',
                    'Juillet', 'Août',
                    'Septembre', 'Octobre',
                    'Novembre', 'Décembre']
            } else {
                option.xAxis.categories = data.years;
            }

            this.setState({chartOption: option});
        }

    }

    handleSelectType(event) {
        let type = event.target.value;

        this.setState({
            stacking: type
        }, () => {
            this.getDataForMonth();
        });
    }


    handleSelectYear(event) {
        let year = event.target.value;

        this.setState({
            year: year
        }, () => {
            this.getDataForMonth();
        });
    }


    handleSelectSegmentation(event) {
        let segmentation = event.target.value;

        this.setState({
            segmentation: segmentation
        }, () => {
            this.getDataForMonth();
        });
    }

    handleSelectService(event) {
        let service = event.target.value;

        this.setState({
            service: service
        }, () => {
            this.getDataForMonth();
        });
    }

    handleClickReset() {
        this.setState({
            year: new Date().getFullYear(),
            stacking: 'normal',
            segmentation: 'month',
            service: 0
        }, () => {
            this.getDataForMonth();
        });

    }

    componentDidMount() {
        this.getDataForMonth();
    }

    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-header ">
                    <h4>
                        Répartition accidents avec arrêt / accident sans arrêt
                        <SaveToDashboard
                            component="dashboard-at-wi-breakdown"
                            size="12"
                            controller=""
                            script=""
                            title="RH - Répartition AAA/ASA"
                        />
                    </h4>
                </div>
                <div className="card-body">
                    <div className="row justify-content-around">
                        <div className="form-group">
                            <label className="col-form-label-sm col-form-label">
                                Service
                            </label>
                            <select value={this.state.service} onChange={(event) => this.handleSelectService(event)}
                                    className="form-control form-control-sm">
                                {
                                    this.state.services.map((service, index) => {
                                        return (<option key={index} value={service.id}>{service.name}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="ml-1 form-group">
                            <label className="col-form-label-sm col-form-label">
                                Ségmentation

                            </label>
                            <select value={this.state.segmentation} onChange={(event) => this.handleSelectSegmentation(event)}
                                    className="form-control form-control-sm">
                                {
                                    this.state.segmentations.map(segmentation => {
                                        return (<option key={segmentation.id} value={segmentation.value}>{segmentation.label}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="ml-1 form-group">
                            <label className="col-form-label-sm col-form-label">
                                Année
                            </label>
                            <select value={this.state.year} onChange={(event) => this.handleSelectYear(event)}
                                    className="form-control form-control-sm">
                                {
                                    this.state.years.map(year => {
                                        return (<option key={year.id} value={year.year}>{year.year}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="ml-1 form-group">
                            <label className="col-form-label-sm col-form-label">
                                Type de graphique
                            </label>
                            <select value={this.state.stacking} onChange={(event) => this.handleSelectType(event)}
                                    className="form-control form-control-sm">
                                {
                                    this.state.types.map(type => {
                                        return (<option key={type.id} value={type.value}>{type.label}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="mb-1">
                            <input className="btn btn-sm btn-default" type="reset" value="Vider la recherche" id="reset"
                                   name="reset" onClick={() => this.handleClickReset()}/>
                        </div>
                    </div>
                    <HighchartsReact highcharts={Highcharts} options={this.state.chartOption}/>
                </div>
            </div>
        );
    }
}