import React from 'react';
import { connect } from "react-redux";
import Routing
    from "../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import SaveToDashboard from "../../save-to-dashboard";
import Translator from "bazinga-translator";
import ReactPaginate from "react-paginate";
import WorkorderDate from "./WorkorderDate";

const routes = require("../../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

class PdpFirePermit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            date: props.workorderDate,
            fire_permits: [],
            itemPerPage: 5,
            nbTotalItems: 0,
            itemOffsetPage: 0,
            nbTotalItemPage: 0
        };
    }

    getData = async () => {
        let params = {
            date: this.state.date,
            limit: this.state.itemPerPage,
            offset: this.state.itemOffsetPage,
        };

        let url = Routing.generate('pdp_fire_permit_list');
        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        if (response.status === 200) {
            let data = await response.json();

            this.setState({
                fire_permits: data.informations.fire_permits,
                nbTotalItems: data.informations.nbTotalItems,
                nbTotalItemPage: Math.ceil(data.informations.nbTotalItems / this.state.itemPerPage)
            });
        }
    };

    handleClickPages = async (page) => {
        let offset = Math.ceil(page.selected * this.state.itemPerPage);

        this.setState({itemOffsetPage: offset}, () => {
            this.getData();
        });
    };

    handleSelectNbItem = (event) => {
        this.setState({itemPerPage: event.target.value, itemOffsetPage: 0}, () => {
            this.getData();
        });
    };

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate() {
        const date = this.props.workorderDate;

        if (date != this.state.date) {
            this.setState(
                {
                    date: date,
                },
                () => this.getData()
            );
        }
    }

    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-header ">
                    <h4>
                        {Translator.trans("mf.pdp.indicator.fire_permits.title")}
                        <SaveToDashboard
                            component="dashboard-pdp-fire-permits"
                            size="6"
                            controller=""
                            script=""
                            title={"PDP - " + Translator.trans("mf.pdp.indicator.fire_permits.title")}
                        />
                    </h4>
                </div>
                <div className="card-body card-table table-responsive">
                    <WorkorderDate />
                    
                    <table className="table table-hover table-striped">
                        <thead>
                        <tr>
                            <th>{Translator.trans('mf.pdp.indicator.fire_permits.number')}</th>
                            <th>{Translator.trans('mf.pdp.indicator.fire_permits.sector')}</th>
                            <th>{Translator.trans('mf.pdp.indicator.fire_permits.start_date')}</th>
                            <th>{Translator.trans('mf.pdp.indicator.fire_permits.end_date')}</th>
                            <th><i className="fas fa-hourglass-start"/></th>
                            <th><i className="fas fa-hourglass-half"/></th>
                            <th><i className="fas fa-hourglass-end"/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.fire_permits.length > 0 ?
                                this.state.fire_permits.map((firePermit, index) => {
                                    let link = Routing.generate('pdp_show_intervention_fire_permit', {'id': firePermit.pdpId});
                                    return (
                                        <tr key={firePermit.id}>
                                            <td>
                                                <a target="_blank" href={link}>
                                                    {firePermit.firePermitNumber}
                                                </a>
                                            </td>
                                            <td>
                                                <a target="_blank" href={link}>
                                                    {firePermit.sector}
                                                </a>
                                            </td>
                                            <td>
                                                <a target="_blank" href={link}>
                                                    {firePermit.startDate}
                                                </a>
                                            </td>
                                            <td>
                                                <a target="_blank" href={link}>
                                                    {firePermit.endDate}
                                                </a>
                                            </td>
                                            <td>
                                                <a target="_blank" href={link}>
                                                    {
                                                        parseInt(firePermit.firstStepSignatures) >= 1 ?
                                                            <i className="fas fa-check-circle text-success"/>
                                                            :
                                                            <i className="fas fa-times-circle text-danger"/>
                                                    }
                                                </a>
                                            </td>
                                            <td>
                                                <a target="_blank" href={link}>
                                                    {
                                                        parseInt(firePermit.secondStepSignatures) >= 1 ?
                                                            <i className="fas fa-check-circle text-success"/>
                                                            :
                                                            <i className="fas fa-times-circle text-danger"/>
                                                    }
                                                </a>
                                            </td>
                                            <td>
                                                <a target="_blank" href={link}>
                                                    {
                                                        parseInt(firePermit.thirdStepSignatures) >= 1 ?
                                                            <i className="fas fa-check-circle text-success"/>
                                                            :
                                                            <i className="fas fa-times-circle text-danger"/>
                                                    }
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan={7}>
                                    </td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-md-3 col-sm-12">
                            {
                                this.state.fire_permits.length > 0 ? (
                                        <div className="datatable-info">
                                            {this.state.itemOffsetPage + 1} - {this.state.itemOffsetPage + this.state.fire_permits.length} sur {this.state.nbTotalItems} résultats
                                        </div>
                                    ) :
                                    (
                                        <div className="datatable-info">
                                            0 - 0 sur 0 résultats
                                        </div>
                                    )
                            }
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <ReactPaginate
                                breakClassName="page-item"
                                breakLabel={<button className="page-link">...</button>}
                                pageClassName="page-item"
                                previousClassName="page-item"
                                previousLabel="Précédente"
                                nextClassName="page-item"
                                nextLabel="Suivante"
                                pageLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                pageCount={this.state.nbTotalItemPage}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handleClickPages}
                                containerClassName={'pagination justify-content-center'}
                                activeClassName={'active'}
                            />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <select className="pagination-dashboard form-control float-right"
                                    onChange={this.handleSelectNbItem}>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state) => state.dashboardReducer)(PdpFirePermit);
