const routes = require('../../../../../../../js/fos_js_routes.json');
import Routing
    from '../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

Routing.setRoutingData(routes);

export default class AccessManager {

    constructor() {
        this.initAccessForm()
    }

    initAccessForm() {
        $('select').on('change', function () {
            AccessManager.saveAccess($(this));
        });
    }

    static saveAccess = async (select) => {
        const url = Routing.generate('access_edit');
        const form = document.forms[select.data('form')];
        const data = new FormData(form);
        try {
            let response = await fetch(url, {
                method: "POST",
                body: data,
                credentials: "same-origin"
            });

            if (response.status == 200) {
                let result = await response.json();
            }
        } catch (error) {
            console.log(error);
        }
    }
}