import React from 'react';
import ReactPaginate from 'react-paginate';
import SaveToDashboard from '../../save-to-dashboard';

require('highcharts/modules/exporting')(Highcharts);

import Routing
    from "../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const routes = require("../../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class AtEmployee extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ats: [],
            atPerPage: 5,
            nbTotalAts: 0,
            atOffsetPage: 0,
            nbTotalAtPage: 5,
        }
    }

    async getAtInformationsForDashboard() {
        let params = {
            limit: this.state.atPerPage,
            offset: this.state.atOffsetPage
        };

        let url = Routing.generate('rh_at_employees');
        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        if (response.status == 200) {
            let data = await response.json();

            this.setState({
                ats: data.informations.ats,
                nbTotalAts: data.informations.nbTotalAts,
                nbTotalAtPage: Math.ceil(data.informations.nbTotalAts / this.state.atPerPage)
            });
        }
    }

    async handleClickPages(page) {
        let offset = Math.ceil(page.selected * this.state.atPerPage);

        this.setState({atOffsetPage: offset}, () => {
            this.getAtInformationsForDashboard();
        });
    }

    handleSelectNbItem(event) {
        this.setState({atPerPage: event.target.value}, () => {
            this.getAtInformationsForDashboard();
        });
    }

    componentDidMount() {
        this.getAtInformationsForDashboard();
    }

    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-header">
                    <h4>
                        Salariés arrêtés
                        <SaveToDashboard
                            component="dashboard-at-employee"
                            size="6"
                            controller=""
                            script=""
                            title="RH - Salariés arrêtés"
                        />
                    </h4>
                </div>
                <div className="card-body card-table">
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th>
                                        Matricule
                                    </th>
                                    <th>
                                        Salarié
                                    </th>
                                    <th>
                                        Jours d'arrêt
                                    </th>
                                    <th>
                                        Début
                                    </th>
                                    <th>
                                        Fin
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.ats.length > 0 ?
                                        this.state.ats.map((at, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {at.matricule}
                                                    </td>
                                                    <td>
                                                        <a href={Routing.generate('rh_employee_view', {id: at.employeeId})}>{at.employee}</a>
                                                    </td>
                                                    <td>
                                                        {at.nbInterruptionDays}
                                                    </td>
                                                    <td>
                                                        {at.startDate}
                                                    </td>
                                                    <td>
                                                        {at.endDate}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-md-3 col-sm-12">
                            {
                                this.state.ats.length > 0 ? (
                                        <div className="datatable-info">
                                            {this.state.atOffsetPage + 1} - {this.state.atOffsetPage + this.state.ats.length} sur {this.state.nbTotalAts} résultats
                                        </div>
                                    ) :
                                    (
                                        <div className="datatable-info">
                                            0 - 0 sur 0 résultats
                                        </div>
                                    )
                            }
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <ReactPaginate
                                breakClassName="page-item"
                                breakLabel={<button className="page-link">...</button>}
                                pageClassName="page-item"
                                previousClassName="page-item"
                                previousLabel="Précédente"
                                nextClassName="page-item"
                                nextLabel="Suivante"
                                pageLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                pageCount={this.state.nbTotalAtPage}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handleClickPages.bind(this)}
                                containerClassName={'pagination justify-content-center'}
                                activeClassName={'active'}
                            />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <select className="pagination-dashboard form-control form-control-sm float-right"
                                    onChange={this.handleSelectNbItem.bind(this)}>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}