import React from 'react';
import ReactPaginate from 'react-paginate';
import SaveToDashboard from '../save-to-dashboard';
import Translator from 'bazinga-translator';

import Routing
    from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const routes = require("../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class ResourceList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            resources: [],
            itemPerPage: 5,
            nbTotalItems: 0,
            itemOffsetPage: 0,
            nbTotalItemPage: 5,
        }
    }

    async getData() {
        let params = {
            limit: this.state.itemPerPage,
            offset: this.state.itemOffsetPage,
        };

        let url = Routing.generate('resource_list');
        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        if (response.status == 200) {
            let data = await response.json();

            this.setState({
                resources: data.informations.resources,
                nbTotalItems: data.informations.nbTotalItems,
                nbTotalItemPage: Math.ceil(data.informations.nbTotalItems / this.state.itemPerPage)
            });
        }
    }

    async handleClickPages(page) {
        let offset = Math.ceil(page.selected * this.state.itemPerPage);

        this.setState({itemOffsetPage: offset}, () => {
            this.getData();
        });
    }

    handleSelectNbItem(event) {
        this.setState({itemPerPage: event.target.value}, () => {
            this.getData();
        });
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-header">
                    <h4>
                        {Translator.trans('eobs.dashboard.activityMap.indicator.resource-list.title')}
                        <SaveToDashboard
                            component="dashboard-resource-list"
                            size="6"
                            controller=""
                            script=""
                            title={'Carte d\'activité - ' + Translator.trans('eobs.dashboard.activityMap.indicator.resource-list.title')}
                        />
                    </h4>
                </div>
                <div className="card-body card-table">
                    <table className="table table-hover table-striped">
                        <thead>
                        <tr>
                            <th>
                                {Translator.trans('eobs.dashboard.activityMap.indicator.resource-list.resource-date')}
                            </th>
                            <th>
                                {Translator.trans('eobs.dashboard.activityMap.indicator.resource-list.resource-title')}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.resources.length > 0 ?
                                this.state.resources.map((resource, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <a href={Routing.generate('mf_activity_map_resource_show', {id: resource.id})}>
                                                    {resource.date}&nbsp;
                                                    {
                                                        resource.new ?
                                                           <span className='badge badge-success'>new</span>
                                                            :
                                                            ""
                                                    }
                                                </a>
                                            </td>
                                            <td>
                                                <a href={Routing.generate('mf_activity_map_resource_show', {id: resource.id})}>{resource.title}</a>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td>
                                    </td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-md-3 col-sm-12">
                            {
                                this.state.resources.length > 0 ? (
                                        <div className="datatable-info">
                                            {this.state.itemOffsetPage + 1} - {this.state.itemOffsetPage + this.state.resources.length} sur {this.state.nbTotalItems} résultats
                                        </div>
                                    ) :
                                    (
                                        <div className="datatable-info">
                                            0 - 0 sur 0 résultats
                                        </div>
                                    )
                            }
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <ReactPaginate
                                breakClassName="page-item"
                                breakLabel={<button className="page-link">...</button>}
                                pageClassName="page-item"
                                previousClassName="page-item"
                                previousLabel="Précédente"
                                nextClassName="page-item"
                                nextLabel="Suivante"
                                pageLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                pageCount={this.state.nbTotalItemPage}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handleClickPages.bind(this)}
                                containerClassName={'pagination justify-content-center'}
                                activeClassName={'active'}
                            />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <select className="pagination-dashboard form-control float-right"
                                    onChange={this.handleSelectNbItem.bind(this)}>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}