import {InterventionForm} from "./es6/components/PreventionPlan/InterventionForm";
import Routing from '../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.js';

import bootbox from 'bootbox';
import bsCustomFileInput from 'bs-custom-file-input'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr'

const routes = require('../../../../js/fos_js_routes.json');

Routing.setRoutingData(routes);

// require jQuery normally
const $ = require('jquery');
global.$ = global.jQuery = $;

const Highcharts = require('highcharts');
global.Highcharts = Highcharts;

global.Routing = Routing;

const moment = require('moment');
global.moment = moment;

var App = {
    /***********************
     * Init element / Libs
     **********************/
    initFormTableSubmit: function () {
        $('.form-table-submit').on('click', function (e) {
            e.preventDefault();
            $button = $(this);

            if ($button.hasClass('form-table-group-toggle')) {
                $('.group-addCustomer-layout').toggle();
                return;
            }

            if ($('.' + $button.attr('data-check') + ':checked').length == 0) {
                modalAlert('Veuillez effectuer une sélection');
            } else {
                $formTable = $("#" + $button.attr('data-form'));
                $formTable.attr('action', $button.attr('data-action'));

                if ($button.hasClass('form-table-delete')) {
                    modalConfirmDelete(function () {
                        $formTable.submit();
                    });
                } else {
                    $formTable.submit();
                }
            }
        });
    },

    initActionMultiple: function () {
        $(".action-multiple").each(function () {
            $(this).on("click", function (event) {
                event.preventDefault();
                var form = $(this).parents("form").attr("action", $(this).attr("href"));
                App.modalConfirmAction(function () {
                    form.submit();
                });
            });
        });
    },

    initCheckAll: function () {
        $('.check-all').change(function () {
            var isChecked = $(this).is(':checked');

            $('.' + $(this).data('target')).each(function () {
                $(this).prop('checked', isChecked);
            });
        });
    },

    initAutocomplete: function () {
        $('.autocomplete').each(function () {
            var field = $(this);
            var _id = field.data('target');
            var list = $('#' + _id + '_list');
            var originValue = field.val();


            if (false == field.data('required')) {
                field.removeAttr('required');
            }

            list.on({
                'click.item-autocomplete': function (e) {
                    $(this).closest('li').fadeOut(300, function () {
                        $(this).remove();
                    });
                    e.preventDefault();
                    e.stopPropagation();
                }
            }, 'a');

            field.on('change', function (e) {
                if ('' == field.val()) {
                    $('#' + _id).val('');
                }
            });

            field.autocomplete({
                deferRequestBy: field.data('delay'),
                lookupLimit: field.data('max-items'),
                minChars: field.data('min-chars'),
                serviceUrl: field.data('url'),
                onSelect: function (suggestion) {
                    if (false == field.data('multiple')) {
                        $('#' + field.data('target')).val(suggestion.data);
                        originValue = suggestion.value;
                    } else {
                        var formName = field.closest('form').prop('name');

                        if (list.length) {
                            var li = list.find('#' + _id + '_' + suggestion.data);

                            if (!li.length) {
                                var name = (formName ? _id.replace(formName + '_', '') : _id).split('_');

                                if (formName) {
                                    name.unshift(formName);
                                }

                                name = (name.length > 1 ? name.shift() + '[' + name.join('][') + ']' : name.join()) + '[]';

                                li = $($('#' + _id).data('prototype'));
                                li.data('value', suggestion.data)
                                    .find('input:hidden').val(suggestion.data).attr('id', _id + '_' + suggestion.data).attr('name', name).end()
                                    .find('.autocomplete-item').text(suggestion.value).end()
                                    .appendTo(list)
                                ;
                            }

                            field.val('');
                        }
                    }
                    if (field.data('callback')) {
                        App[field.data("callback")]();
                    }
                }
            });
        });
    },

    initDatePicker: function () {
        var datepickerIcons = {
            time: 'fas fa-clock',
            date: 'fas fa-calendar',
            up: 'fas fa-chevron-up',
            down: 'fas fa-chevron-down',
            previous: 'fas fa-chevron-left',
            next: 'fas fa-chevron-right',
            today: 'fas fa-crosshairs',
            clear: 'fas fa-trash',
            close: 'fas fa-remove',
            orientation: "bottom" // <-- orientation en bas
        };
        // $('.datepicker').datepicker(datepickerOptions);
        // $('.input-date').datepicker(datepickerOptions);

        $('.datetimepicker').datetimepicker({
            locale: 'fr',
            icons: datepickerIcons
        });

        $('.timepicker').datetimepicker({
            locale: 'fr',
            format: 'LT',
            icons: datepickerIcons
        });

        $('.datepicker').datetimepicker({
            locale: 'fr',
            format: 'L',
            icons: datepickerIcons
        });

        $('.input-date').datepicker({
            format: "dd/mm/yyyy",
            locale: 'fr',
        });

        $(".datepickerOnlyYear").datepicker({
            format: " yyyy", // Notice the Extra space at the beginning
            viewMode: "years",
            minViewMode: "years",
            orientation: "bottom" // <-- orientation en bas
        });

        $(".datepicker, .datetimepicker, .timepicker, .input-date").on("dp.change", function (e) {
            const inputStart = $(this).data('linked-start');
            const inputEnd = $(this).data('linked-end');
            const interval = $(this).data('interval');
            let date = e.date;

            if (inputStart) {
                $(inputStart).data('DateTimePicker').maxDate(date);
                if (interval) {
                    let date2 = date.clone().subtract(interval, 'days');
                    $(inputStart).data('DateTimePicker').minDate(date2);
                }
            }
            if (inputEnd) {
                $(inputEnd).data('DateTimePicker').minDate(date);
                if (interval) {
                    let date2 = date.clone().add(interval, 'days');
                    $(inputEnd).data('DateTimePicker').maxDate(date2);
                }
            }
        })
    },

    initSideBar: function () {
        $('.navbar-sidenav [data-toggle="tooltip"]').tooltip({
            template: '<div class="tooltip navbar-sidenav-tooltip" role="tooltip" style="pointer-events: none;"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
        });

        // Toggle the side navigation
        $("#sidenavToggler").click(function (e) {
            e.preventDefault();
            $("body").toggleClass("sidenav-toggled");
            $(".navbar-sidenav .nav-link-collapse").addClass("collapsed");
            $(".navbar-sidenav .sidenav-second-level, .navbar-sidenav .sidenav-third-level").removeClass("show");
        });

        // Force the toggled class to be removed when a collapsible nav link is clicked
        $(".navbar-sidenav .nav-link-collapse").click(function (e) {
            e.preventDefault();
            $("body").removeClass("sidenav-toggled");
        });
    },

    initCheckBox: function () {
        $('.check-all').change(function () {
            $checkAll = $(this);
            $('.' + $checkAll.attr('data-check')).prop('checked', $checkAll.prop('checked'));
        });
    },
    initBootBoxModal: function () {
        $('[data-confirm="confirm-delete"]').each(function (index, item) {

            $(item).on('click', function () {
                App.modalConfirmDelete(function () {
                    App.redirect($(item).attr('data-href'));
                });
            });
        });

        $('[data-confirm="confirm-archive"]').each(function (index, item) {

            $(item).on('click', function () {
                App.modalConfirmArchive(function () {
                    App.redirect($(item).attr('data-href'));
                });
            });
        });

        $('[data-confirm="confirm"]').each(function (index, item) {

            $(item).on('click', function () {
                App.modalConfirmAction(function () {
                    App.redirect($(item).attr('data-href'));
                });
            });
        });
    },

    /***********************
     * Custom Bootbox Modal functions
     **********************/

    redirect: function (url) {
        window.location.href = url;
    },

    modalConfirm: function (params) {
        var defaultParams = {
            title: 'Confirmation',
            message: 'Êtes-vous sûr ?',
            buttons: {
                cancel: {
                    label: 'Non'
                },
                confirm: {
                    label: 'Oui'
                }
            },
            callback: function (result) {
            }
        };
        if (undefined == params) {
            params = defaultParams;
        }
        bootbox.confirm(params);
    },

    modalAlert: function (message, title) {
        if (undefined == title) {
            title = 'Attention';
        }

        bootbox.alert({
            message: message,
            title: title
        });
    },

    modalConfirmDelete: function (callback) {
        var params = {
            title: "Supprimer",
            message: "Êtes-vous sûr de vouloir supprimer ?",
            buttons: {
                cancel: {
                    label: '<i class="fa fa-times"></i> Annuler'
                },
                confirm: {
                    label: '<i class="fa fa-check"></i> Oui, supprimer'
                }
            },
            callback: function (result) {
                if (result == true) {
                    callback();
                }
            }
        };
        this.modalConfirm(params);
    },

    modalConfirmArchive: function (callback) {
        var params = {
            title: "Archiver",
            message: "Êtes-vous sûr de vouloir archiver ?",
            backdrop: true,
            buttons: {
                cancel: {
                    label: '<i class="fa fa-times"></i> Annuler'
                },
                confirm: {
                    label: '<i class="fa fa-check"></i> Oui, archiver'
                }
            },
            callback: function (result) {
                if (result == true) {
                    callback();
                }
            }
        };
        this.modalConfirm(params);
    },

    modalConfirmAction: function (callback) {
        var params = {
            title: 'Confirmation',
            message: 'Êtes-vous sûr ?',
            buttons: {
                cancel: {
                    label: 'Non'
                },
                confirm: {
                    label: 'Oui'
                }
            },
            callback: function (result) {
                if (result == true) {
                    callback();
                }
            }
        };
        this.modalConfirm(params);
    },

    initSelectPicker: function () {
        $('.selectpicker').selectpicker({
            dropdownAlignRight: 'auto',
        });

        $('.selectpicker').each(function (item) {
            var idCommun = $(this).attr('id') + '_dropdown';
            $(this).parent().attr('id', idCommun);

            var button = $(this).parent().find('.dropdown-toggle');
            button.data('boundary', idCommun);
        });
    },

    initCustomFileUpload: function () {
        bsCustomFileInput.init()
    },

    initSelectCompany: function () {
        $('#layout-select-company-event').on('change', function () {
            window.location.href = Routing.generate('mf_company_set', {company: $(this).val()});
        });
    },

    initTextAreaResize: function () {
        jQuery.each(jQuery('textarea[data-autoresize]'), function () {
            var offset = this.offsetHeight - this.clientHeight;

            var resizeTextarea = function (el) {
                jQuery(el).css('height', 'auto').css('height', el.scrollHeight + offset);
            };
            jQuery(this).on('keyup input', function () {
                resizeTextarea(this);
            }).removeAttr('data-autoresize');
        });
    },


    initCalendarLegend: function () {
        $('.btn-calendar-legend').on('click', function () {
            if ($(this).hasClass('btn-success')) {
                $('.wrapper-calendar').removeClass('col-md-9').addClass('col-md-12');
                $('.wrapper-legend').removeClass('col-md-3').addClass('col-md-0 d-none');
                $(this).removeClass('btn-success').addClass('btn-primary');
            } else {
                $('.wrapper-calendar').removeClass('col-md-12').addClass('col-md-9');
                $('.wrapper-legend').removeClass('col-md-0 d-none').addClass('col-md-3');
                $(this).removeClass('btn-primary').addClass('btn-success');
            }
        });
    },

    initLexicon: function () {
        $('#addTermDocuments').on('click', function (e) {
            e.preventDefault();
            $('#lexical_term_addDocuments').val(true);
            $(this).parents('form').submit();
        });
        $('#addTermEmbed').on('click', function (e) {
            e.preventDefault();
            $('#lexical_term_addEmbed').val(true);
            $(this).parents('form').submit();
        });
        $('.toggleTermModal').on('click', function () {
            var termId = $(this).data('term');
            var modalId = $('#modalTerm' + termId);
            if (modalId.length) {
                modalId.modal('toggle')
            } else {
                var url = Routing.generate('lexical_term_get', {
                    'id': termId
                });
                $.ajax({
                    url: url,
                    data: {'termId': termId},
                    success: function (data) {
                        $('body').append(data);
                        $('#modalTerm' + this.data.termId).modal('toggle');
                    },
                    method: "GET",
                    processData: false,  // indique à jQuery de ne pas traiter les données
                    contentType: false   // indique à jQuery de ne pas configurer le contentType
                });
            }
        })
    },

    initQuiz: function () {
        $('.quizCollapse').on('click', function () {
            var quizNote = $('#quizNote' + $(this).data('quiz'));
            var quizResults = $('#quizResults' + $(this).data('quiz'));
            var quizResources = $('#quizResources' + $(this).data('quiz'));

            var target = $('#' + $(this).data('target') + $(this).data('quiz'));
            if (target.is(':hidden')) {
                quizResults.hide();
                quizNote.hide();
                quizResources.hide();
                target.slideToggle();
            } else {
                quizNote.slideToggle();
                target.hide();
            }
        });
        $('.toggleQuestion').on('click', function () {
            App.validateQuestion($(this));
        });
    },

    validateQuestion: function (question) {
        var current = question.data('question-current');
        var next = question.data('question-next');
        var isSubmit = false;
        if (question.hasClass('validateQuiz')) {
            isSubmit = true;
        }

        var checkboxs = $("input[name^='quiz_validation[results][" + current + "][answers]']").filter(':checked');
        var comments = $("textarea[name='quiz_validation[results][" + current + "][comment]']");


        if (checkboxs.length || comments.val() != "") {
            $("input[name^='quiz_validation[results][" + current + "][answers]']").each(function () {
                $(this).children('.invalid-feedback').hide();
            });

            if (isSubmit) {
                question.parents('form').submit();
            } else {
                $('#' + current).slideToggle();
                $('#' + next).slideToggle();
            }
        } else {
            if ($("input[name^='quiz_validation[results][" + current + "][answers]']").length) {
                $("input[name^='quiz_validation[results][" + current + "][answers]']").each(function () {
                    $(this).addClass('is-invalid');
                });
            } else {
                comments.addClass('is-invalid')
            }

            if (!$("#quiz_validation_results_" + current + "_answers").children('.invalid-feedback').length) {
                var error = "<div class=\"invalid-feedback\" style='display: block'>Une réponse est nécessaire</div>";
                $("#quiz_validation_results_" + current + "_answers").append(error);
            } else {
                $("#quiz_validation_results_" + current + "_answers").children('.invalid-feedback').show()
            }
        }
    },

    autocompleteInterventionTiers: function () {
        InterventionForm.loadInterventionTiers();
    },

    initCkeditor: function () {
        $('.editor').each(function () {
            ClassicEditor
                .create(document.getElementById($(this).attr('id')), {
                    language: 'fr',
                    toolbar: [
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        'blockQuote',
                        'undo',
                        'redo'
                    ],
                })
                .catch(error => {
                    console.error(error);
                });
        });
    },

    initTooltip: function () {
        $('.tooltip').remove();
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover();
    },

    loading: function (parent) {
        let height = parent.height();
        parent.html("<div style='height: " + height + "px' class='col-md-12 d-flex justify-content-center align-items-center'><div class=\"spinner-grow text-secondary\" role=\"status\"><span class=\"sr-only\">Loading...</span></div></div>");
    },

    scrollTo: function (target) {
        $('html, body').stop().animate({
            scrollTop: ($(target).offset().top)
        }, 200);
    },

    initHierarchyField: function () {
        $('.hierarchy-field select').on('change', function () {
            if ($(this).data('root-item') !== undefined) {
                const url = Routing.generate('hierarchy_filter_refresh', {
                    rootItem: $(this).data('root-item'),
                    restricted: $(this).data('restricted'),
                    roleAttribute: $(this).data('role-attribute')
                })

                let data = new FormData();
                const thisName = $(this).data('hierarchy-type');
                $('option:selected', this).each(function () {
                    data.append(thisName + '[]', $(this).val())
                })

                $.ajax({
                    url: url,
                    data: data,
                    success: function (data) {
                        $('.hierarchy-field select').each(function () {
                            const refreshBy = $(this).data('refresh-by');
                            const itemName = $(this).data('hierarchy-type');
                            if (refreshBy == thisName) {
                                const replace = $(data).find('#hierarchy_' + itemName).html();
                                $(this).html(replace);
                                $(this).selectpicker('refresh');
                            }
                        });
                    },
                    method: "POST",
                    processData: false,
                    contentType: false
                });
            }
        })
    }
};

// Export APP functions
global.App = App;

$(document).ready(function () {
    $(".current_ancestor").children('ul').collapse();
    App.initActionMultiple();
    App.initAutocomplete();
    App.initCheckAll();
    App.initDatePicker();
    App.initTooltip();
    App.initCheckBox();
    App.initBootBoxModal();
    App.initSideBar();
    App.initFormTableSubmit();
    App.initSelectPicker();
    App.initCustomFileUpload();
    App.initSelectCompany();
    App.initTextAreaResize();
    App.initCalendarLegend();
    App.initLexicon();
    App.initCkeditor();
    App.initHierarchyField();
});
/***********************
 * ScrollTop
 **********************/

// Scroll to top button appear
$(document).scroll(function () {
    var scrollDistance = $(this).scrollTop();
    if (scrollDistance > 100) {
        $('.scroll-to-top').fadeIn();
    } else {
        $('.scroll-to-top').fadeOut();
    }
});
// Smooth scrolling using jQuery easing
$(document).on('click', 'a.scroll-to-top', function (event) {
    var $anchor = $(this);
    App.scrollTo($anchor.attr('href'));
    event.preventDefault();
});