import React from 'react';
import Routing
    from "../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Highcharts from "highcharts";
import SaveToDashboard from "../../save-to-dashboard";
import HighchartsReact from "highcharts-react-official";
import Translator from "bazinga-translator";

require('highcharts/modules/exporting')(Highcharts);

const routes = require("../../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class PdpWorkType extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            chartOption: [],
            services: [],
            service: 0
        };

        this.getData = this.getData.bind(this);
    }

    getData = async () => {

        let option = {
            chart: {
                type: "pie"
            },
            title: {
                text: ""
            },
            series: [
                {
                    name: Translator.trans("mf.pdp.indicator.work_type.series_name"),
                    data: [],
                    dataLabels: {
                        format: '{point.percentage:.2f} %'
                    },
                }],
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    showInLegend: true
                }
            },
            legend: {
                labelFormat: '{name} ({y})'
            },
            yAxis: {
                title: {
                    text: Translator.trans("mf.pdp.indicator.work_type.y_label")
                }
            },
        };

        let url = Routing.generate('pdp_dashboard_work_type');

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        if (response.status == 200) {
            let data = await response.json();
            option.series[0].data = data.series;
            this.setState({
                chartOption: option,
            });
        }
    };

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-header ">
                    <h4>
                        {Translator.trans("mf.pdp.indicator.work_type.title")}
                        <SaveToDashboard
                            component="dashboard-pdp-work-type"
                            size="6"
                            controller=""
                            script=""
                            title={"PDP - " + Translator.trans("mf.pdp.indicator.work_type.title")}
                        />
                    </h4>
                </div>
                <div className="card-body">
                    <HighchartsReact highcharts={Highcharts} options={this.state.chartOption}/>
                </div>
            </div>
        );
    }
}