import React, {Component} from 'react';
import ReactPaginate from 'react-paginate';
import Translator from 'bazinga-translator';
import ParserHTML from "./../parser";
import {
    Circle,
    CircleMarker,
    FeatureGroup,
    ImageOverlay,
    LayerGroup,
    LayersControl,
    Map,
    Marker,
    Polygon,
    Popup
} from "react-leaflet";
import {EditControl} from "react-leaflet-draw"
import SignaturePad from "react-signature-canvas";

import L, {CRS} from 'leaflet';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Col,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    Table,
    UncontrolledTooltip,
    UncontrolledCollapse, FormGroup, Label
} from 'reactstrap'
import Routing
    from '../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
import drawLocales from "leaflet-draw-locales";
import {connect} from "react-redux";

L.drawLocal = drawLocales('fr');

import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl,
    iconUrl,
    shadowUrl
});


const routes = require('../../../../../../../js/fos_js_routes.json');

Routing.setRoutingData(routes);

let parserHTML = new ParserHTML();

const defaultWorkOrder = {
    id: '',
    date: '',
    start: '',
    end: '',
    startDate: '',
    endDate: '',
    zones: [],
    coactivities: [],
    plan: {width: 0, name: '', path: ''}
};
const {Overlay} = LayersControl;

class WorkOrder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingSpinner: false,
            resultsPerPage: 5,
            nbTotalResults: 0,
            resultsOffsetPage: 0,
            nbTotalResultsPage: 5,
            results: [],
            coactivities: [],
            sectorCoactivities: [],
            intervention: props.intervention,
            action: "",
            workOrder: defaultWorkOrder,
            workOrderModalContent: "",
            showWorkModal: false,
            showCoactivityModal: false,
            showSignatureModal: false,
            signatureType: null,
            signValid: true,
            submitted: false
        };

        this.selectWorkOrder = this.selectWorkOrder.bind(this);
        this.addWorkOrder = this.addWorkOrder.bind(this);
        this.submitWorkOrder = this.submitWorkOrder.bind(this);
    }

    sigCanvas = {};
    sigName = "";

    componentDidMount = () => {
        this.loadResults();
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.startDate !== null &&
            this.props.endDate !== null &&
            (prevProps.sector !== this.props.sector ||
                prevProps.startDate !== this.props.startDate ||
                prevProps.endDate !== this.props.endDate)) {
            this.findSectorCoactivities();
        }
    };

    async loadResults() {
        this.setState({
            loadingSpinner: true,
            submitted: false,
        });

        let params = {
            limit: this.state.resultsPerPage,
            offset: this.state.resultsOffsetPage,
        };

        let url = Routing.generate('mf_work_order_list_by_intervention', {'id': this.state.intervention});
        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        if (response.status === 200) {
            let data = await response.json();

            if (data.informations.workOrders[0]) {
                defaultWorkOrder.plan = data.informations.workOrders[0].plan
            }

            this.setState({
                loadingSpinner: false,
                results: data.informations.workOrders,
                defaultWorkOrder: defaultWorkOrder,
                nbTotalResults: data.informations.nbTotalItems,
                nbTotalResultsPage: Math.ceil(data.informations.nbTotalItems / this.state.resultsPerPage)
            });
        }
    }

    findSectorCoactivities = async () => {
        try {
            const url = Routing.generate('mf_work_order_sector_coactivities', {
                sector: this.props.sector,
                workOrder: this.state.workOrder.id,
                startDate: this.props.startDate,
                endDate: this.props.endDate
            });
            let response = await fetch(url, {
                method: "GET",
                credentials: "same-origin",
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            });

            if (response.status == 200) {
                let data = await response.json();

                this.setState({sectorCoactivities: data.sector_coactivities}, function () {
                    $('#work_order_sector option').each(function () {
                        let id = $(this).val();
                        let sector = data.coactivities_count.filter(function (sector) {
                            return sector.id == id;
                        });
                        let count = 0;
                        if (sector[0]) {
                            count = sector[0].count;
                        }
                        if ($(this).text() !== "") {
                            let badgeColor = "success";
                            if (count > 0) {
                                badgeColor = "danger"
                            }
                            $(this).attr('data-content', $(this).text() + " <span class='badge badge-pill badge-" + badgeColor + "'>" + count + "</span>");
                        }
                    });
                    $(".selectpicker").selectpicker("refresh");
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    handleClickPages(page) {
        let offset = Math.ceil(page.selected * this.state.resultsPerPage);

        this.setState({resultsOffsetPage: offset}, () => {
            this.loadResults();
        });
    }

    handleSelectNbItem(event) {
        this.setState({resultsPerPage: event.target.value}, () => {
            this.loadResults();
        });
    }

    selectWorkOrder = async workOrder => {

        let response = await fetch(Routing.generate('mf_work_order_get_zones', {'id': workOrder.id}),
            {
                method: "GET",
                credentials: "same-origin",
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            }
        );

        if (response.status === 200) {
            let data = await response.json();
            workOrder.zones = data.current;
            workOrder.coactivities = data.coactivities;
            workOrder.plan = data.plan;
        }

        this.setState({workOrder: workOrder}, function () {
            App.scrollTo('#map');
        });
    };

    submitWorkOrder = async workOrder => {
        this.setState({
            submitted: true
        });
        let form = document.getElementById("work_order_form");

        let url = Routing.generate("mf_work_order_modal_add", {
            id: this.state.intervention
        });

        if (this.state.action === "edit") {
            url = Routing.generate("mf_work_order_modal_edit", {
                id: this.state.workOrder.id
            });
        }

        $('#work_order_contactSignature').val(this.state.workOrder.contactSignature);
        $('#work_order_contactSignatureName').val(this.state.workOrder.contactSignatureName);
        $('#work_order_contactSignatureDate').val(this.state.workOrder.contactSignatureDate);
        $('#work_order_orderingCustomerSignature').val(this.state.workOrder.orderingCustomerSignature);
        $('#work_order_orderingCustomerSignatureName').val(this.state.workOrder.orderingCustomerSignatureName);
        $('#work_order_orderingCustomerSignatureDate').val(this.state.workOrder.orderingCustomerSignatureDate);
        $('#work_order_contactEndSignature').val(this.state.workOrder.contactEndSignature);
        $('#work_order_contactEndSignatureName').val(this.state.workOrder.contactEndSignatureName);
        $('#work_order_contactEndSignatureDate').val(this.state.workOrder.contactEndSignatureDate);
        $('#work_order_orderingEndCustomerSignature').val(this.state.workOrder.orderingEndCustomerSignature);
        $('#work_order_orderingEndCustomerSignatureName').val(this.state.workOrder.orderingEndCustomerSignatureName);
        $('#work_order_orderingEndCustomerSignatureDate').val(this.state.workOrder.orderingEndCustomerSignatureDate);

        try {
            let response = await fetch(url, {
                method: "POST",
                body: new FormData(form),
                credentials: "same-origin",
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            });

            if (response.status === 201) {
                let data = await response.json();

                if (data.result) {
                    this.loadResults();
                }

                this.closeWorkModal();
            } else {
                let form = await response.text();
                this.initWorkOrderForm(form, this.state.action, this.state.workOrder);
            }
        } catch (error) {
            console.error("Error submit workOrder");
            this.loadResults();
        }
    };

    editWorkOrder = async workOrder => {
        try {
            let response = await fetch(
                Routing.generate("mf_work_order_modal_edit", {
                    id: workOrder.id
                }),
                {
                    method: "GET",
                    credentials: "include"
                }
            );

            if (response.status === 200) {
                let form = await response.text();

                this.initWorkOrderForm(form, "edit", workOrder);
            }
        } catch (error) {
            console.error("Error edit work order");
        }
    };

    addWorkOrder = async () => {
        try {
            let response = await fetch(
                Routing.generate("mf_work_order_modal_add", {
                    id: this.state.intervention
                }),
                {
                    method: "GET",
                    credentials: "include"
                }
            );

            if (response.status === 200) {
                let form = await response.text();

                this.initWorkOrderForm(form, "add");
            }
        } catch (error) {
            console.error("Error add work order");
        }
    };

    showCoactivity = async workOrder => {
        this.setState({coactivities: workOrder.coactivities}, () => {
            this.setState(
                {
                    showCoactivityModal: true
                });
        });
    };

    closeCoactivityModal() {
        this.setState(
            {
                showCoactivityModal: false
            }
        );
    }

    initWorkOrderForm = (form, action, workOrder = defaultWorkOrder) => {
        this.setState({workOrderModalContent: null, submitted: false}, () => {
            this.setState(
                {
                    action: action,
                    workOrder: workOrder,
                    workOrderModalContent: parserHTML.parse(form),
                    showWorkModal: true
                },
                () => {
                    this.initInput();
                }
            );
        });
    };

    initInput() {
        App.initDatePicker();
        App.initSelectPicker();
        App.initCustomFileUpload();
    }

    closeWorkModal() {
        this.setState(
            {
                workOrder: defaultWorkOrder,
                showWorkModal: false
            }
        );
    }

    showSignatureModal(type) {
        this.setState(
            {
                signatureType: type,
                showSignatureModal: true
            });
    };

    closeSignatureModal = () => {
        this.setState(
            {
                signatureType: null,
                showSignatureModal: false
            }
        );
    };

    saveSignature = () => {
        let signatureImage = this.sigCanvas.getTrimmedCanvas().toDataURL("image/png");
        let signatureName = this.sigName.value;

        if (signatureName == "") {
            this.setState({
                signValid: false
            });

            return;
        } else {
            this.setState({
                signValid: true
            });
        }

        let workOrder = this.state.workOrder;
        if (this.state.signatureType === "contact") {
            workOrder.contactSignature = signatureImage;
            workOrder.contactSignatureName = signatureName;
            workOrder.contactSignatureDate = moment().format("DD/MM/YYYY HH:mm");
        } else if (this.state.signatureType === "orderingCustomer") {
            workOrder.orderingCustomerSignature = signatureImage;
            workOrder.orderingCustomerSignatureName = signatureName;
            workOrder.orderingCustomerSignatureDate = moment().format("DD/MM/YYYY HH:mm");
        } else if (this.state.signatureType === "contactEnd") {
            workOrder.contactEndSignature = signatureImage;
            workOrder.contactEndSignatureName = signatureName;
            workOrder.contactEndSignatureDate = moment().format("DD/MM/YYYY HH:mm");
        } else if (this.state.signatureType === "orderingEndCustomer") {
            workOrder.orderingEndCustomerSignature = signatureImage;
            workOrder.orderingEndCustomerSignatureName = signatureName;
            workOrder.orderingEndCustomerSignatureDate = moment().format("DD/MM/YYYY HH:mm");
        }
        this.setState({
            workOrder: workOrder
        });
        this.closeSignatureModal();
    };

    clearSignature = () => {
        this.sigCanvas.clear();
    };

    onLayerEdit = async e => {
        let layers = e.layers;
        layers.eachLayer(async function (layer) {
            let type = layer.options.type,
                id = layer.options.id,
                data = WorkOrder.getLayerData(type, layer);

            try {
                let response = await fetch(Routing.generate('mf_work_order_leaflet_zone_edit', {'id': id}),
                    {
                        method: "POST",
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({type: type, data: data}),
                        credentials: "include"
                    }
                );
            } catch (error) {
                console.error(error);
                console.error("Error on leaflet zone edit");
            }
        });
    };

    onLayerCreate = async e => {
        if (this.state.workOrder.id) {
            let type = e.layerType,
                layer = e.layer,
                data = WorkOrder.getLayerData(type, layer);

            try {
                let response = await fetch(Routing.generate('mf_work_order_leaflet_zone_add', {'id': this.state.workOrder.id}),
                    {
                        method: "POST",
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({type: type, data: data}),
                        credentials: "include"
                    }
                );

                if (response.status === 200) {
                    let zone = await response.json();
                    this.state.workOrder.zones.push(zone);
                    this.setState({workOrder: this.state.workOrder});
                    layer.remove();
                }
            } catch (error) {
                console.error(error);
                console.error("Error on leaflet zone add");
            }
        } else {
            e.layer.remove();
        }
    };

    onLayerDelete = async e => {
        let layers = e.layers;
        let workOrder = this.state.workOrder;
        layers.eachLayer(async function (layer) {
            try {
                if (workOrder.id === layer.options.workOrder) {
                    let response = await fetch(Routing.generate('mf_work_order_leaflet_zone_delete', {'id': layer.options.id}),
                        {
                            method: "GET",
                            credentials: "include"
                        }
                    );
                    if (response.status === 200) {

                        workOrder.zones = workOrder.zones.filter(function (zone) {
                            return layer.options.id !== zone.id;
                        });
                    }
                }
            } catch (error) {
                console.error(error);
                console.error("Error on leaflet zone delete");
            }
        });
        this.setState({workOrder: workOrder});
    };

    static getLayerData = (type, layer) => {
        let data = [];
        if (type === "circle" || type === "circlemarker") {
            let radius = layer.getRadius();
            let center = layer.getLatLng();

            data = {'radius': radius, 'center': center}
        } else if (type === "marker") {
            let position = layer.getLatLng();

            data = {'position': position}
        } else if (type === "polygon") {
            let points = layer.getLatLngs();

            data = {'points': points}
        }
        return data;
    };

    render() {
        let listingResults = <tr className="text-center">
            <td colSpan={5}><Spinner type="grow" color="secondary"/></td>
        </tr>;
        if (!this.state.loadingSpinner) {
            listingResults = this.state.results.map((result, index) => {

                let pdfLink = Routing.generate('pdp_work_order_pdf', {'id': result.id});

                let coactivities = <td className="text-center">
                    <Button color="link" onClick={this.showCoactivity.bind(this, result)}
                            className={"messaging-notify " + (result.coactivities.length ? 'invalid' : 'valid')}
                            data-badge={result.coactivities.length}>
                        <i id={"tooltip-coactivity" + index}
                           className={"fas fa-user-friends"}/>
                        <UncontrolledTooltip target={"tooltip-coactivity" + index}>
                            {Translator.trans('mf.pdp.form.workOrder.show_coactivity')}
                        </UncontrolledTooltip>
                    </Button>
                </td>;

                return (
                    <tr key={index}>
                        <td>
                            {result.date}
                        </td>
                        <td>
                            {result.start} {Translator.trans('mf.pdp.form.workOrder.to')} {result.end}
                        </td>
                        <td>
                            {result.sector}
                        </td>
                        {coactivities}
                        <td className="text-right" width="20%">
                            <Button onClick={this.editWorkOrder.bind(this, result)} id={"tooltip-edit" + index}>
                                <i className="fas fa-pen"/>
                            </Button>
                            <UncontrolledTooltip
                                target={"tooltip-edit" + index}>{Translator.trans('mf.pdp.form.workOrder.edit')}</UncontrolledTooltip>
                            <Button onClick={this.selectWorkOrder.bind(this, result)}
                                    id={"tooltip-to_map" + index}
                                    className={result === this.state.workOrder ? 'active' : ''}>
                                <i className="fas fa-map-marked"/>
                            </Button>
                            <UncontrolledTooltip
                                target={"tooltip-to_map" + index}>{Translator.trans('mf.pdp.form.workOrder.to_map')}</UncontrolledTooltip>
                            <a className="btn btn-secondary" target="_blank" href={pdfLink} id={"tooltip-pdf" + index}>
                                <i className="fas fa-file-pdf"/>
                            </a>
                            <UncontrolledTooltip
                                target={"tooltip-pdf" + index}>{Translator.trans('mf.pdp.form.workOrder.pdf')}</UncontrolledTooltip>
                        </td>
                    </tr>
                )
            });
        }

        let workModal = (
            <Modal
                onOpened={this.initInput}
                size="xl"
                isOpen={this.state.showWorkModal}
                toggle={this.closeWorkModal.bind(this)}
            >
                <ModalHeader
                    toggle={this.closeWorkModal.bind(this)}>{Translator.trans('mf.pdp.form.intervention.workOrder')}</ModalHeader>
                <ModalBody>
                    {this.state.workOrderModalContent}
                    <div className="row">
                        <div className="col-md-12">
                            <Card>
                                <CardHeader className="text-center">
                                    <Button color="primary" id="toggler">
                                        {this.state.sectorCoactivities.length} coactivités <i
                                        className="fas fa-eye"></i>
                                    </Button>
                                </CardHeader>
                                <UncontrolledCollapse toggler="#toggler">
                                    <CardBody className="card-table table-responsive">
                                        <table className={"table table-striped table-hover"}>
                                            <thead>
                                            <tr>
                                                <th>{Translator.trans('mf.pdp.form.workOrder.coactivity.pdp_number')}</th>
                                                <th>{Translator.trans('mf.pdp.form.workOrder.coactivity.subject')}</th>
                                                <th>{Translator.trans('mf.pdp.form.workOrder.coactivity.ordering_customer')}</th>
                                                <th>{Translator.trans('mf.pdp.form.workOrder.sector')}</th>
                                                <th>{Translator.trans('mf.pdp.form.workOrder.coactivity.nature')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.sectorCoactivities.length ?
                                                    this.state.sectorCoactivities.map((coactivity) => {
                                                        let link = Routing.generate('pdp_show_intervention_tiers', {'id': coactivity.pdpId});
                                                        return (
                                                            <tr key={coactivity.id}>
                                                                <td>
                                                                    <a target="_blank" href={link}>
                                                                        {coactivity.pdpNumber}
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    <a target="_blank" href={link}>
                                                                        {coactivity.subject}
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    <a target="_blank" href={link}>
                                                                        {coactivity.orderingCustomer}
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    <a target="_blank" href={link}>
                                                                        {coactivity.sector}
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    <a target="_blank" href={link}>
                                                                        {coactivity.nature}
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }) :
                                                    <tr key={1}>
                                                        <td colSpan={5}>{Translator.trans('mf.pdp.form.workOrder.coactivity.empty')}</td>
                                                    </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </CardBody>
                                </UncontrolledCollapse>
                            </Card>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Card>
                                <CardHeader>{Translator.trans('mf.pdp.form.workOrder.signature.start')}</CardHeader>
                                <CardBody className="row">
                                    <div className="col-lg-6 text-center">
                                        {
                                            this.state.workOrder.orderingCustomerSignature ?
                                                <figure className="figure">
                                                    <Button
                                                        className="pt-0 float-right"
                                                        color="link"
                                                        onClick={this.showSignatureModal.bind(this, 'orderingCustomer')}>
                                                        <i className="fas fa-pen-alt"/>
                                                    </Button>
                                                    <img src={this.state.workOrder.orderingCustomerSignature}
                                                         style={{maxHeight: 100 + 'px'}}
                                                         className="figure-img img-fluid rounded" alt="..."/>
                                                    <figcaption className="figure-caption text-center">
                                                        {this.state.workOrder.orderingCustomerSignatureName}<br/>({this.state.workOrder.orderingCustomerSignatureDate})
                                                    </figcaption>
                                                </figure>
                                                :
                                                <Button
                                                    onClick={this.showSignatureModal.bind(this, 'orderingCustomer')}>
                                                    {Translator.trans('mf.pdp.form.workOrder.signature.ordering_customer_sign')}
                                                    &nbsp;<i className="fas fa-pen-alt"/>
                                                </Button>
                                        }
                                    </div>
                                    <div className="col-lg-6 text-center">
                                        {
                                            this.state.workOrder.contactSignature ?
                                                <figure className="figure">
                                                    <Button
                                                        className="pt-0 float-right"
                                                        color="link"
                                                        onClick={this.showSignatureModal.bind(this, 'contact')}>
                                                        <i className="fas fa-pen-alt"/>
                                                    </Button>
                                                    <img src={this.state.workOrder.contactSignature}
                                                         style={{maxHeight: 100 + 'px'}}
                                                         className="figure-img img-fluid rounded" alt="..."/>
                                                    <figcaption className="figure-caption text-center">
                                                        {this.state.workOrder.contactSignatureName}<br/>({this.state.workOrder.contactSignatureDate})
                                                    </figcaption>
                                                </figure>
                                                :
                                                <Button onClick={this.showSignatureModal.bind(this, 'contact')}>
                                                    {Translator.trans('mf.pdp.form.workOrder.signature.contact_sign')}
                                                    &nbsp;<i className="fas fa-pen-alt"/>
                                                </Button>
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-md-6">
                            <Card>
                                <CardHeader>{Translator.trans('mf.pdp.form.workOrder.signature.end')}</CardHeader>
                                <CardBody className="row">
                                    <div className="col-lg-6 text-center">
                                        {
                                            this.state.workOrder.orderingEndCustomerSignature ?
                                                <figure className="figure">
                                                    <Button
                                                        className="pt-0 float-right"
                                                        color="link"
                                                        onClick={this.showSignatureModal.bind(this, 'orderingEndCustomer')}>
                                                        <i className="fas fa-pen-alt"/>
                                                    </Button>
                                                    <img src={this.state.workOrder.orderingEndCustomerSignature}
                                                         style={{maxHeight: 100 + 'px'}}
                                                         className="figure-img img-fluid rounded" alt="..."/>
                                                    <figcaption className="figure-caption text-center">
                                                        {this.state.workOrder.orderingEndCustomerSignatureName}<br/>({this.state.workOrder.orderingEndCustomerSignatureDate})
                                                    </figcaption>
                                                </figure>
                                                :
                                                <Button
                                                    onClick={this.showSignatureModal.bind(this, 'orderingEndCustomer')}>
                                                    {Translator.trans('mf.pdp.form.workOrder.signature.ordering_customer_sign')}
                                                    &nbsp;<i className="fas fa-pen-alt"/>
                                                </Button>
                                        }
                                    </div>
                                    <div className="col-lg-6 text-center">
                                        {
                                            this.state.workOrder.contactEndSignature ?
                                                <figure className="figure">
                                                    <Button
                                                        className="pt-0 float-right"
                                                        color="link"
                                                        onClick={this.showSignatureModal.bind(this, 'contactEnd')}>
                                                        <i className="fas fa-pen-alt"/>
                                                    </Button>
                                                    <img src={this.state.workOrder.contactEndSignature}
                                                         style={{maxHeight: 100 + 'px'}}
                                                         className="figure-img img-fluid rounded" alt="..."/>
                                                    <figcaption className="figure-caption text-center">
                                                        {this.state.workOrder.contactEndSignatureName}<br/>({this.state.workOrder.contactEndSignatureDate})
                                                    </figcaption>
                                                </figure>
                                                :
                                                <Button onClick={this.showSignatureModal.bind(this, 'contactEnd')}>
                                                    {Translator.trans('mf.pdp.form.workOrder.signature.contact_sign')}
                                                    &nbsp;<i className="fas fa-pen-alt"/>
                                                </Button>
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary"
                            onClick={this.closeWorkModal.bind(this)}>{Translator.trans('app.form.cancel')}</Button>
                    <Button color="secondary"
                            disabled={this.state.submitted}
                            onClick={this.submitWorkOrder}>
                        {
                            this.state.submitted ?
                                <Spinner size="sm" type="grow" color="light" className="mr-1"/>
                                :
                                ''
                        }
                        {Translator.trans('app.form.submit')}
                    </Button>
                </ModalFooter>
            </Modal>
        );
        let coactivityModal = (
            <Modal
                size="xl"
                isOpen={this.state.showCoactivityModal}
                toggle={this.closeCoactivityModal.bind(this)}
            >
                <ModalHeader
                    toggle={this.closeCoactivityModal.bind(this)}>{Translator.trans('mf.pdp.form.workOrder.coactivity.title')}</ModalHeader>
                <ModalBody className={"table-responsive"}>
                    <table className={"table table-striped table-hover"}>
                        <thead>
                        <tr>
                            <th>{Translator.trans('mf.pdp.form.workOrder.coactivity.pdp_number')}</th>
                            <th>{Translator.trans('mf.pdp.form.workOrder.coactivity.subject')}</th>
                            <th>{Translator.trans('mf.pdp.form.workOrder.coactivity.ordering_customer')}</th>
                            <th>{Translator.trans('mf.pdp.form.workOrder.sector')}</th>
                            <th>{Translator.trans('mf.pdp.form.workOrder.coactivity.nature')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.coactivities.length ?
                                this.state.coactivities.map((coactivity, index) => {
                                    let link = Routing.generate('pdp_show_intervention_tiers', {'id': coactivity.pdpId});
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <a target="_blank" href={link}>
                                                    {coactivity.pdpNumber}
                                                </a>
                                            </td>
                                            <td>
                                                <a target="_blank" href={link}>
                                                    {coactivity.subject}
                                                </a>
                                            </td>
                                            <td>
                                                <a target="_blank" href={link}>
                                                    {coactivity.orderingCustomer}
                                                </a>
                                            </td>
                                            <td>
                                                <a target="_blank" href={link}>
                                                    {coactivity.sector}
                                                </a>
                                            </td>
                                            <td>
                                                <a target="_blank" href={link}>
                                                    {coactivity.nature}
                                                </a>
                                            </td>
                                        </tr>
                                    );
                                }) :
                                <tr key={1}>
                                    <td colSpan={5}>{Translator.trans('mf.pdp.form.workOrder.coactivity.empty')}</td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </ModalBody>
            </Modal>
        );

        let signatureModal = (
            <Modal isOpen={this.state.showSignatureModal} toggle={this.closeSignatureModal}>
                <ModalHeader toggle={this.closeSignatureModal}>
                    {
                        this.state.signatureType === "contact" || this.state.signatureType === "contactEnd" ?
                            Translator.trans('mf.pdp.form.workOrder.signature.contact_sign')
                            :
                            Translator.trans('mf.pdp.form.workOrder.signature.ordering_customer_sign')
                    }
                </ModalHeader>
                <ModalBody>
                    <SignaturePad
                        ref={(ref) => {
                            this.sigCanvas = ref
                        }}
                        canvasProps={{
                            className: "signature-canvas"
                        }}
                    />
                    <FormGroup row>
                        <Label for="name" sm={2}>Nom</Label>
                        <Col sm={10}>
                            <Input innerRef={el => this.sigName = el}
                                   id="name"
                                   type="text"
                                   invalid={this.state.signValid !== null && !this.state.signValid}/>
                        </Col>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary"
                            onClick={this.closeSignatureModal}>{Translator.trans('app.form.cancel')}
                    </Button>
                    <Button color="primary"
                            onClick={this.clearSignature}>{Translator.trans('app.form.clear')}
                    </Button>
                    <Button color="secondary"
                            onClick={this.saveSignature}>{Translator.trans('app.form.submit')}
                    </Button>
                </ModalFooter>
            </Modal>
        );

        let currentLeafletZones = (
            <FeatureGroup>
                <EditControl
                    position='topright'
                    onEdited={this.onLayerEdit}
                    onCreated={this.onLayerCreate}
                    onDeleted={this.onLayerDelete}
                    draw={{
                        circlemarker: false,
                        polyline: false,
                        rectangle: false
                    }}
                />
                {this.state.workOrder.zones.map((zone, index) => {
                    if (zone.type === "circle") {
                        return (
                            <Circle key={index} id={zone.id} workOrder={this.state.workOrder.id}
                                    type="circle"
                                    center={[zone.data.center.lat, zone.data.center.lng]}
                                    radius={zone.data.radius}/>
                        )
                    } else if (zone.type === "circlemarker") {
                        return (
                            <Circle key={index} id={zone.id} workOrder={this.state.workOrder.id}
                                    type="circlemarker"
                                    center={[zone.data.center.lat, zone.data.center.lng]}
                                    radius={zone.data.radius}/>
                        )
                    } else if (zone.type === "marker") {
                        return (
                            <Marker key={index} id={zone.id} workOrder={this.state.workOrder.id}
                                    type="marker"
                                    position={zone.data.position}
                            />
                        )
                    } else if (zone.type === "polygon") {
                        return (
                            <Polygon key={index} id={zone.id} workOrder={this.state.workOrder.id}
                                     type="polygon"
                                     positions={zone.data.points}
                            />
                        )
                    }
                })};
            </FeatureGroup>
        );

        let coactivitiesLeafletZones = this.state.workOrder.coactivities.map((coactivity) => {

            return (
                <Overlay key={coactivity.id} checked name={coactivity.pdpNumber + ' - ' + coactivity.date}>
                    <LayerGroup>
                        {
                            coactivity.zones.map((zone) => {
                                let link = Routing.generate('pdp_show_intervention_tiers', {'id': coactivity.pdpId});
                                let popupText = <a href={link}
                                                   target='_blank'>{coactivity.pdpNumber + ' - ' + coactivity.date}</a>;

                                if (zone.type === "circle") {
                                    return (
                                        <Circle key={zone.id} id={zone.id} workOrder={coactivity.id}
                                                type="circle"
                                                fillColor="red"
                                                color="red"
                                                center={[zone.data.center.lat, zone.data.center.lng]}
                                                radius={zone.data.radius}>
                                            <Popup>{popupText}</Popup>
                                        </Circle>
                                    )
                                } else if (zone.type === "circlemarker") {
                                    return (
                                        <CircleMarker key={zone.id} id={zone.id} workOrder={coactivity.id}
                                                      type="circlemarker"
                                                      fillColor="red"
                                                      color="red"
                                                      center={[zone.data.center.lat, zone.data.center.lng]}
                                                      radius={zone.data.radius}>
                                            <Popup>{popupText}</Popup>
                                        </CircleMarker>
                                    )
                                } else if (zone.type === "marker") {
                                    return (
                                        <Marker key={zone.id} id={zone.id} workOrder={coactivity.id}
                                                type="marker"
                                                position={zone.data.position}
                                        >
                                            <Popup>{popupText}</Popup>
                                        </Marker>
                                    )
                                } else if (zone.type === "polygon") {
                                    return (
                                        <Polygon key={zone.id} id={zone.id} workOrder={coactivity.id}
                                                 type="polygon"
                                                 fillColor="red"
                                                 color="red"
                                                 positions={zone.data.points}
                                        >
                                            <Popup>{popupText}</Popup>
                                        </Polygon>
                                    )
                                }
                            })
                        }
                    </LayerGroup>
                </Overlay>
            );
        });

        return (
            <Row>
                <Col xl='6'>
                    <Card className="card-table table-responsive">
                        <Table hover striped>
                            <thead>
                            <tr>
                                <th>{Translator.trans('mf.pdp.form.workOrder.day')}</th>
                                <th>{Translator.trans('mf.pdp.form.workOrder.hours')}</th>
                                <th>{Translator.trans('mf.pdp.form.workOrder.sector_short')}</th>
                                <th className="text-center">{Translator.trans('mf.pdp.form.workOrder.coactivity.title')}</th>
                                <th className="text-right">
                                    {
                                        this.props.granted ?
                                            <>
                                                <Button outline onClick={this.addWorkOrder} id="tooltip-add">
                                                    <i className="fas fa-plus"></i>
                                                </Button>
                                                <UncontrolledTooltip
                                                    target="tooltip-add">{Translator.trans('mf.pdp.form.workOrder.add')}</UncontrolledTooltip>
                                            </>
                                            :
                                            ''
                                    }
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {listingResults}
                            </tbody>
                        </Table>
                        <CardFooter>
                            <Row>
                                <Col md={3} sm={12}>
                                    {
                                        this.state.results.length > 0 ? (
                                                <div className="datatable-info">
                                                    {this.state.resultsOffsetPage + 1} - {this.state.resultsOffsetPage + this.state.results.length} {Translator.trans('app.pagination.on')} {this.state.nbTotalResults} {Translator.trans('app.pagination.result')}
                                                </div>
                                            ) :
                                            (
                                                <div className="datatable-info">
                                                    0 -
                                                    0 {Translator.trans('app.pagination.on')} 0 {Translator.trans('app.pagination.result')}
                                                </div>
                                            )
                                    }
                                </Col>
                                <Col md={6} sm={12}>
                                    <ReactPaginate
                                        breakClassName="page-item"
                                        breakLabel={<span className="page-link">...</span>}
                                        pageClassName="page-item"
                                        previousLabel={Translator.trans('app.pagination.previous')}
                                        previousClassName="page-item"
                                        nextClassName="page-item"
                                        nextLabel={Translator.trans('app.pagination.next')}
                                        pageLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        pageCount={this.state.nbTotalResultsPage}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.handleClickPages.bind(this)}
                                        containerClassName={'pagination'}
                                        activeClassName={'active'}
                                    />
                                </Col>
                                <Col md={3} sm={12}>
                                    <Input type="select" className="float-right"
                                           onChange={this.handleSelectNbItem.bind(this)}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </Input>
                                </Col>
                            </Row>
                        </CardFooter>
                    </Card>
                </Col>
                {workModal}
                {coactivityModal}
                {signatureModal}
                <Col id="map" xl="6">
                    <Card style={{height: 500 + 'px'}}>
                        <Map center={[200, this.state.workOrder.plan.width / 2]} zoom={0} maxZoom={5}
                             doubleClickZoom={false}
                             crs={CRS.Simple}>
                            {currentLeafletZones}
                            <LayersControl position='topleft'>
                                {coactivitiesLeafletZones}
                                <Overlay key={this.state.workOrder.id} checked
                                         name={"Plan " + this.state.workOrder.plan.name}>
                                    <ImageOverlay
                                        id={1}
                                        bounds={[[0, 0], [400, this.state.workOrder.plan.width]]}
                                        url={this.state.workOrder.plan.path}
                                    />
                                </Overlay>
                            </LayersControl>
                        </Map>
                    </Card>
                </Col>
            </Row>
        )
    }
}

export default connect(state => state.formReducer)(WorkOrder);
