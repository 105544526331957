import React, {Component} from 'react';
import {connect} from "react-redux";
import Translator from 'bazinga-translator';
import ParserHTML from "../../parser";

import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner} from 'reactstrap'

import TiersCard from "./TiersCard";
import HierarchyItemCard from "./HierarchyItemCard";
import CompanySearch from "./CompanySearch"
import Routing
    from '../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

const routes = require('../../../../../../../../js/fos_js_routes.json');


Routing.setRoutingData(routes);

let parserHTML = new ParserHTML();

const defaultTiersData = {
    tiers: {
        id: "",
        name: "",
        substitutes: []
    },
    tiers_address: {
        address: {
            address: "",
            city: "",
            country: "",
            name: "",
            zip_code: "",
        }
    },
    tiers_contact: {
        contact: {
            email: "",
            first_name: "",
            last_name: "",
            mobile: "",
            phone: "",
        }
    },
};

class InterventionCompaniesForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            intervention: {
                id: this.props.intervention,
                ordering_customer: {},
                hierarchy_item: {},
                tiers: []
            },
            tiersId: null,
            tiersType: null,
            loadingItems: false,
            loadingTiersForm: false,
            refreshTiersForm: false,
            formModalTiers: null,
            resetForm: false,
            searchTiers: false
        };
    }

    /**
     * @returns {Promise<void>}
     */
    componentDidMount = async () => {
        this.loadIntervention();
    };

    componentDidUpdate = async (prevProps) => {
        if (this.props.tiersSearch !== null && prevProps.autocompleteTiers !== this.props.autocompleteTiers && this.props.autocompleteTiers === true) {
            this.getTiersForm(this.props.tiersSearch);
        }
        if (prevProps.addressSearch !== this.props.addressSearch) {
            this.getTiersAddress();
        }
        if (prevProps.contactsSearch !== this.props.contactsSearch) {
            this.getTiersContact();
        }
    };

    /**
     * Init autocomplete and selectpicker
     */
    initInput = () => {
        $('.selectpicker').selectpicker('refresh');
        const resetBtn = document.getElementById('reset-form');
        const searchBtn = document.getElementById('search-btn');
        const newAddressBtn = document.getElementById('new-address-button');
        const newContactBtn = document.getElementById('new-contact-button');
        if (resetBtn) {
            resetBtn.onclick = () => {
                this.resetTiers()
            };
        }
        if (searchBtn) {
            searchBtn.onclick = () => {
                this.setState({searchTiers: true})
            };
        }
        if (newAddressBtn) {
            newAddressBtn.onclick = () => {
                this.props.dispatch({type: 'setInputEmpty', value: ['intervention_tiers_addressSearch']})
            };
        }
        if (newContactBtn) {
            newContactBtn.onclick = () => {
                this.props.dispatch({type: 'setInputEmpty', value: ['intervention_tiers_contactsSearch']})
            };
        }
    };

    /**
     * @returns {Promise<void>}
     */
    loadIntervention = async () => {
        this.setState({
            loadingItems: true,
        });

        let url = Routing.generate('pdp_get_intervention', {id: this.state.intervention.id});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response.status == 200) {
            let responseData = await response.json();
            let intervention = JSON.parse(responseData);

            this.setState({
                loadingItems: false,
                intervention: intervention
            });
        }
    };

    /**
     * @param tiersId
     * @param tiersData
     * @returns {Promise<void>}
     */
    editTiers = async (tiersId, tiersData) => {
        if (tiersData.tiers.id !== "") {
            this.setState({
                tiersId: tiersId,
            }, () => {
                this.showTiersForm();
            });
        } else {
            this.setState({
                tiersId: tiersId,
                searchTiers: true,
                showModalTiers: true,
            });
        }
    };

    /**
     * @returns {Promise<void>}
     */
    resetTiers = async () => {
        this.setState({
            searchTiers: false,
            resetForm: true,
        }, () => {
            this.showTiersForm();
        });
    };

    /**
     * @returns {Promise<void>}
     */
    addSubcontractor = async () => {
        this.setState({
            tiersId: null,
            searchTiers: true,
            showModalTiers: true,
        });
    };

    /**
     * @param id
     * @returns {Promise<void>}
     */
    removeSubcontractor = async (id) => {
        let url = Routing.generate('pdp_tiers_remove', {id: this.state.intervention.id, tiersId: id});

        try {
            let response = await fetch(url, {
                method: "GET",
                credentials: "same-origin",
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            });

            if (response.status == 200) {
                let responseData = await response.json();
                let intervention = JSON.parse(responseData);

                this.setState({
                    intervention: intervention,
                })
            }
        } catch (error) {
            console.error(error)
        }
    };

    /**
     * @param form
     * @param readonly
     */
    initTiersForm = (form, readonly) => {
        this.setState(
            {
                formModalTiers: parserHTML.parse(form),
                showModalTiers: true,
                loadingTiersForm: false,
                resetForm: false
            }, () => {
                this.initInput();
                if (readonly === true){
                    this.props.dispatch({
                        type: 'setInputReadonly', inputsId: [
                            'intervention_tiers_tiersContact_contact_lastName',
                            'intervention_tiers_tiersContact_contact_firstName',
                            'intervention_tiers_tiersContact_contact_email',
                        ],
                        value: false
                    })
                }
            }
        );
    };

    /**
     * @returns {Promise<void>}
     */
    showTiersForm = async () => {
        this.setState({
            loadingTiersForm: true,
        });

        try {
            let url = '';

            if (this.state.resetForm) {
                url = Routing.generate('pdp_tiers_reset', {
                    id: this.state.intervention.id
                });
            } else if (this.state.tiersId == null) {
                url = Routing.generate('pdp_tiers_add', {id: this.state.intervention.id});
            } else {
                url = Routing.generate('pdp_tiers_edit', {id: this.state.intervention.id, tiersId: this.state.tiersId});
            }

            let response = await fetch(url, {
                method: "GET",
                credentials: "same-origin",
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            });

            if (response.status == 200) {
                let form = await response.text();
                this.initTiersForm(form, true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    handleTiersSelect = (tiers) => {
        this.setState({searchTiers: false}, () => {
            this.getTiersForm(tiers);
        })
    }

    /**
     * @returns {Promise<void>}
     */
    getTiersForm = async (tiersId) => {

        this.setState({
            loadingTiersForm: true,
        });

        try {
            let url = '';

            url = Routing.generate('pdp_tiers_get', {
                id: this.state.intervention.id,
                tiersId: tiersId,
                interventionTiersId: this.state.tiersId
            });

            let response = await fetch(url, {
                method: "GET",
                credentials: "same-origin",
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            });

            if (response.status == 200) {
                let form = await response.text();
                this.initTiersForm(form, true);
                this.props.dispatch({type: 'setTiersSearch', autocompleteTiers: false});
            }
        } catch (error) {
            console.error(error);
        }
    };

    getTiersAddress = async () => {
        try {
            let address = defaultTiersData.tiers_address.address;

            if (this.props.addressSearch !== undefined && this.props.addressSearch !== '') {
                let url = Routing.generate('pdp_tiers_address_get', {
                    id: this.state.intervention.id,
                    addressId: this.props.addressSearch,
                });

                let response = await fetch(url, {
                    method: "GET",
                    credentials: "same-origin",
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                });

                if (response.status == 201) {
                    let data = await response.json();
                    address = JSON.parse(data);
                    this.props.dispatch({
                        type: 'setInputValue',
                        inputId: 'intervention_tiers_tiersAddress_address_name',
                        value: address.name
                    });
                    this.props.dispatch({
                        type: 'setInputValue',
                        inputId: 'intervention_tiers_tiersAddress_address_address',
                        value: address.address
                    });
                    this.props.dispatch({
                        type: 'setInputValue',
                        inputId: 'intervention_tiers_tiersAddress_address_zipCode',
                        value: address.zip_code
                    });
                    this.props.dispatch({
                        type: 'setInputValue',
                        inputId: 'intervention_tiers_tiersAddress_address_city',
                        value: address.city
                    });
                    this.props.dispatch({
                        type: 'setInputValue',
                        inputId: 'intervention_tiers_tiersAddress_address_country',
                        value: address.country
                    });
                }
            } else {
                this.props.dispatch({
                    type: 'setInputEmpty', value: [
                        'intervention_tiers_tiersAddress_address_name',
                        'intervention_tiers_tiersAddress_address_address',
                        'intervention_tiers_tiersAddress_address_zipCode',
                        'intervention_tiers_tiersAddress_address_city',
                        'intervention_tiers_tiersAddress_address_country',
                    ]
                })
            }

        } catch (error) {
            console.error(error);
        }
    };

    getTiersContact = async () => {
        try {
            let contact = defaultTiersData.tiers_contact.contact;
            if (this.props.contactsSearch !== undefined && this.props.contactsSearch !== '') {
                let url = Routing.generate('pdp_tiers_contact_get', {
                    id: this.state.intervention.id,
                    contactId: this.props.contactsSearch,
                });

                let response = await fetch(url, {
                    method: "GET",
                    credentials: "same-origin",
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                });

                if (response.status == 201) {
                    let data = await response.json();
                    contact = JSON.parse(data);
                    if (contact.last_name) {
                        this.props.dispatch({
                            type: 'setInputValue',
                            inputId: 'intervention_tiers_tiersContact_contact_lastName',
                            value: contact.last_name
                        });
                        this.props.dispatch({
                            type: 'setInputReadonly', inputsId: ['intervention_tiers_tiersContact_contact_lastName',],
                            value: true
                        })
                    } else {
                        this.props.dispatch({
                            type: 'setInputEmpty', value: ['intervention_tiers_tiersContact_contact_lastName',]
                        })
                        this.props.dispatch({
                            type: 'setInputReadonly', inputsId: ['intervention_tiers_tiersContact_contact_lastName',],
                            value: false
                        })
                    }

                    if (contact.first_name) {
                        this.props.dispatch({
                            type: 'setInputValue',
                            inputId: 'intervention_tiers_tiersContact_contact_firstName',
                            value: contact.first_name
                        });
                        this.props.dispatch({
                            type: 'setInputReadonly', inputsId: ['intervention_tiers_tiersContact_contact_firstName',],
                            value: true
                        })
                    } else {
                        this.props.dispatch({
                            type: 'setInputEmpty', value: ['intervention_tiers_tiersContact_contact_firstName',]
                        })
                        this.props.dispatch({
                            type: 'setInputReadonly', inputsId: ['intervention_tiers_tiersContact_contact_firstName',],
                            value: false
                        })
                    }

                    if (contact.phone) {
                        this.props.dispatch({
                            type: 'setInputValue',
                            inputId: 'intervention_tiers_tiersContact_contact_phone',
                            value: contact.phone
                        });
                    } else {
                        this.props.dispatch({
                            type: 'setInputEmpty', value: ['intervention_tiers_tiersContact_contact_phone',]
                        })
                    }
                    if (contact.mobile) {
                        this.props.dispatch({
                            type: 'setInputValue',
                            inputId: 'intervention_tiers_tiersContact_contact_mobile',
                            value: contact.mobile
                        });
                    } else {
                        this.props.dispatch({
                            type: 'setInputEmpty', value: ['intervention_tiers_tiersContact_contact_mobile',]
                        })
                    }
                    this.props.dispatch({
                        type: 'setInputValue',
                        inputId: 'intervention_tiers_tiersContact_contact_email',
                        value: contact.email
                    });
                    if (contact.email) {
                        this.props.dispatch({
                            type: 'setInputValue',
                            inputId: 'intervention_tiers_tiersContact_contact_email',
                            value: contact.email
                        });
                        this.props.dispatch({
                            type: 'setInputReadonly', inputsId: [
                                'intervention_tiers_tiersContact_contact_email',
                            ],
                            value: true
                        })
                    } else {
                        this.props.dispatch({
                            type: 'setInputEmpty', value: ['intervention_tiers_tiersContact_contact_email',]
                        })
                        this.props.dispatch({
                            type: 'setInputReadonly', inputsId: [
                                'intervention_tiers_tiersContact_contact_email',
                            ],
                            value: false
                        })
                    }
                }
            } else {
                this.props.dispatch({
                    type: 'setInputReadonly', inputsId: [
                        'intervention_tiers_tiersContact_contact_lastName',
                        'intervention_tiers_tiersContact_contact_firstName',
                        'intervention_tiers_tiersContact_contact_email',
                    ],
                    value: false
                })
                this.props.dispatch({
                    type: 'setInputEmpty', value: [
                        'intervention_tiers_tiersContact_contact_lastName',
                        'intervention_tiers_tiersContact_contact_firstName',
                        'intervention_tiers_tiersContact_contact_phone',
                        'intervention_tiers_tiersContact_contact_mobile',
                        'intervention_tiers_tiersContact_contact_email',
                    ]
                })
            }
        } catch (error) {
            console.error(error);
        }
    };

    closeModalTiersForm = () => {
        this.setState(
            {
                showModalTiers: false,
                searchTiers: false
            }
        );
    };

    /**
     * @returns {Promise<void>}
     */
    submitTiersForm = async () => {
        this.setState({
            loadingItems: true
        });

        let form = document.getElementById("pdp_tiers_form");
        try {
            let url = '';

            if (this.state.tiersId == null) {
                url = Routing.generate('pdp_tiers_add', {id: this.state.intervention.id});
            } else {
                url = Routing.generate('pdp_tiers_edit', {id: this.state.intervention.id, tiersId: this.state.tiersId});
            }


            let response = await fetch(url, {
                method: "POST",
                body: new FormData(form),
                credentials: "same-origin",
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            });

            if (response.status == 201) {

                let responseData = await response.json();
                let intervention = JSON.parse(responseData);

                this.setState({
                    intervention: intervention,
                    loadingItems: false
                });

                $("#errorsCard").parent('.card').remove();

                this.closeModalTiersForm();
            } else {
                this.setState({
                    loadingItems: false
                });
                let form = await response.text();
                this.initTiersForm(form, false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    render() {

        let formTiersModal = (
            <Modal
                size="xl"
                isOpen={this.state.showModalTiers}
                toggle={this.closeModalTiersForm}
                onOpened={this.initInput}
            >
                <ModalHeader toggle={this.closeModalTiersForm}>
                    {Translator.trans('mf.pdp.form.tiers.title.modal')}
                </ModalHeader>
                <ModalBody>
                    {
                        this.state.loadingTiersForm === true ?
                            (
                                <div className="d-flex align-items-center justify-content-center"
                                     style={{height: 400 + 'px'}}>
                                    <Spinner type="grow" color="secondary"/>
                                </div>
                            )
                            :
                            this.state.searchTiers === true ?
                                <CompanySearch onHandleSelect={this.handleTiersSelect}
                                               onHandleCreate={this.resetTiers}/>
                                :
                                this.state.formModalTiers
                    }
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={this.submitTiersForm}
                    >
                        {Translator.trans("app.form.submit")}
                    </Button>
                    <Button
                        color="secondary"
                        onClick={this.closeModalTiersForm}
                    >
                        {Translator.trans("app.form.cancel")}
                    </Button>
                </ModalFooter>
            </Modal>
        );

        let companies = <Col md={12} className="d-flex align-items-center justify-content-center"
                             style={{height: 500 + 'px'}}>
            <Spinner type="grow" color="secondary"/>
        </Col>;
        let subcontractors = '';
        if (!this.state.loadingItems) {
            companies = [];
            if (!this.state.intervention.is_external) {
                companies.push(
                    <Col md={6} sm={12} key={0}>
                        <Card>
                            <CardHeader>
                                <h3>{Translator.trans('mf.pdp.form.tiers.title.user_company')}</h3>
                            </CardHeader>
                            <HierarchyItemCard hierarchyItemData={this.state.intervention.hierarchy_item}
                                        orderingCustomerData={this.state.intervention.ordering_customer}/>
                        </Card>
                    </Col>
                );

                const externalTiers = this.state.intervention.tiers.find(tiers => tiers.type === "exterior");
                const externalTiersData = externalTiers && externalTiers.data ? JSON.parse(externalTiers.data) : defaultTiersData;

                companies.push(
                    <Col md={6} sm={12} key={1}>
                        <Card>
                            <CardHeader>
                                {
                                    externalTiers && this.props.granted ?
                                        <Button color="link" className="tools"
                                                onClick={this.editTiers.bind(this, externalTiers.id, externalTiersData)}>
                                            <i className="icon fas fa-pencil-alt"></i>
                                        </Button>
                                        :
                                        ''
                                }
                                <h3>{Translator.trans('mf.pdp.form.tiers.title.external_company')}</h3>
                            </CardHeader>
                            <TiersCard tiersData={externalTiersData}/>
                        </Card>
                    </Col>
                );
            } else {
                const userTiers = this.state.intervention.tiers.find(tiers => tiers.type === "user");
                const userTiersData = userTiers && userTiers.data ? JSON.parse(userTiers.data) : defaultTiersData;
                companies.push(
                    <Col md={6} sm={12} key={0}>
                        <Card>
                            <CardHeader>
                                {
                                    userTiers && this.props.granted ?
                                        <Button color="link" className="tools"
                                                onClick={this.editTiers.bind(this, userTiers.id, userTiersData)}>
                                            <i className="icon fas fa-pencil-alt"></i>
                                        </Button>
                                        :
                                        ''
                                }
                                <h3>{Translator.trans('mf.pdp.form.tiers.title.user_company')}</h3>
                            </CardHeader>
                            <TiersCard tiersData={userTiersData}/>
                        </Card>
                    </Col>
                );

                companies.push(
                    <Col md={6} sm={12} key={1}>
                        <Card>
                            <CardHeader>
                                <h3>{Translator.trans('mf.pdp.form.tiers.title.external_company')}</h3>
                            </CardHeader>
                            <HierarchyItemCard hierarchyItemData={this.state.intervention.hierarchy_item}
                                        orderingCustomerData={this.state.intervention.ordering_customer}/>
                        </Card>
                    </Col>
                );
            }

            subcontractors = this.state.intervention.tiers.map((tiers, index) => {
                if (tiers.type === "subcontractor") {

                    const tiersData = JSON.parse(tiers.data);

                    return (
                        <Col md={6} sm={12} key={index}>
                            <Card>
                                <CardHeader>
                                    {
                                        this.props.granted ?
                                            <Button color="link" className="tools"
                                                    onClick={this.editTiers.bind(this, tiers.id, tiersData)}>
                                                <i className="icon fas fa-pencil-alt"></i>
                                            </Button>
                                            :
                                            ''
                                    }
                                    {
                                        this.props.granted ?
                                            <Button color="link" className="tools"
                                                    onClick={this.removeSubcontractor.bind(this, tiers.id)}>
                                                <i className="icon fas fa-trash"></i>
                                            </Button>
                                            :
                                            ''
                                    }
                                    <h3>{Translator.trans('mf.pdp.form.tiers.title.subcontractor')}</h3>
                                </CardHeader>
                                <TiersCard tiersData={tiersData}/>
                            </Card>
                        </Col>
                    );
                }
            });
        }

        return (
            <Row>
                {companies}
                {subcontractors}
                {
                    this.props.granted ?
                        <Col md={12} className="text-center mt-3">
                            <Button color="outline-secondary" onClick={this.addSubcontractor.bind(this)}>
                                {Translator.trans('mf.pdp.form.tiers.actions.add_subcontractor')}
                            </Button>
                        </Col>
                        :
                        ''
                }
                {formTiersModal}
            </Row>
        )
    }
}


export default connect(state => state.formReducer)(InterventionCompaniesForm);