import React, { Component } from "react";

/**
 * Basic component for option field
 */
export default class OptionField extends Component {
	render() {
		let text =
			this.props.children.length > 0 ? this.props.children[0].data : "";

		return (
			<option value={this.props.value} selected={this.props.selected}>
				{text}
			</option>
		);
	}
}
