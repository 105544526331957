import Routing
    from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const routes = require("../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class PreventionPlanManager {

    constructor() {
        PreventionPlanManager.initHistory();
    };

    static initHistory() {
        $('.pdp-history-modal-btn').on("click", function (e) {
            e.preventDefault();
            PreventionPlanManager.getHistory($(this));
        })
    }

    static getHistory = async (item) => {
        let url = Routing.generate('pdp_history', {id: item.data('intervention')});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response && response.status == 200) {
            const history = await response.text();
            if (!$('#pdp-history-modal').length) {
                $("body").append("<div class='modal fade' role='dialog' id='pdp-history-modal'></div>")
            }
            $('#pdp-history-modal').html(history);
            $('#pdp-history-modal').modal()
        } else {
            console.error('Une erreur est survenue, le PDP n\'a pas été trouvé')
        }
    };
}