import React, {Component} from "react";
import {connect} from "react-redux";

/**
 * Basic component for input field
 */
class InputField extends Component {
    constructor(props) {
        super(props);
        let name = this.props.name;
        let latest = name.split("[");
        let field = latest[latest.length - 1];

        field = field.slice(0, field.length - 1);

        this.state = {
            value: this.props.value || "",
            field: field,
            checked: this.props.checked,
            readonly: false
        };
    }


    componentDidMount() {
        this.dispatch();
    }

    componentDidUpdate(prevProps) {
        if (this.props.inputsToClear.length > 0) {
            if (this.props.inputsToClear.includes(this.props.id)) {
                this.setState({value: ''})
            }
            this.props.dispatch({type: 'setInputEmpty', value: []})
        }
        if (this.props.inputsToReadonly.length > 0) {
            if (this.props.inputsToReadonly.includes(this.props.id)) {
                this.setState({readonly: this.props.readonlyState})
            }
            this.props.dispatch({type: 'setInputReadonly', inputsId: [], value: false})
        }
        if (this.props.inputToChange === this.props.id && (this.props.inputValue !== this.state.value && this.props.inputValue !== prevProps.inputValue)) {
            this.setState({value: this.props.inputValue})
        }
    }

    onEvent = (e) => {
        this.setState({
            value: e.target.value,
            checked: e.target.checked
        }, this.dispatch);
    };

    dispatch() {
        let setterReducer = "set" + this.state.field.charAt(0).toUpperCase() + this.state.field.slice(1);
        this.props.dispatch({type: setterReducer, value: this.state.value});
    }

    render() {
        return (
            <input
                type={this.props.type}
                id={this.props.id}
                name={this.props.name}
                placeholder={this.props.placeholder}
                className={this.props.class}
                value={this.state.value}
                onChange={this.onEvent.bind(this)}
                onBlur={this.onEvent.bind(this)}
                readOnly={this.state.readonly}
                disabled={false}
                style={this.props.style}
                redirectroute={this.props.redirectroute}
                data-prototype={this.props["data-prototype"]}
                required={this.props.required}
                checked={this.state.checked}
                data-linked-start={this.props["data-linked-start"]}
                data-linked-end={this.props["data-linked-end"]}
                data-interval={this.props["data-interval"]}
            />
        );
    }
}

export default connect(state => state.formReducer)(InputField);