import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import SaveToDashboard from "../../save-to-dashboard";

require('highcharts/modules/exporting')(Highcharts);

import Routing
    from "../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const routes = require("../../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class AtFrequencyBreakdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            chartOption: [],
            dateStart: '',
            dateEnd: '',
        };

        Highcharts.SVGRenderer.prototype.symbols['c-rect'] = function (x, y, w, h) {
            return ['M', x, y + h / 2, 'L', x + w, y + h / 2];
        };

        this.getData = this.getData.bind(this);
    }

    async getData() {
        let params = {
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd
        };

        let url = Routing.generate('rh_at_type');
        url = url.concat('?', Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'));

        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        let option = {
            chart: {
                type: "column"
            },
            title: {
                text: ""
            },
            xAxis: {
                type: 'category'
            },
            series: [{
                name: "Taux de fréquence",
                data: [
                    {name: 'CTE', y: 33.09},
                    {name: 'ENVIRONNEMENT', y: 18.28, drilldown: 'ENVIRONNEMENT'},
                    {name: 'FORMATION', y: 10.19},
                    {name: 'POWER SERVICE', y: 8},
                    {name: 'EQUIPEMENTS', y: 7.94},
                    {name: 'CONSTRUCTION', y: 7.52},
                    {name: 'GESTION', y: 0},
                    {name: 'SECURITE', y: 0}],
            }],
            drilldown: {
                series: [{
                    id: 'ENVIRONNEMENT',
                    name: 'ENVIRONNEMENT',
                    data: [
                        {
                            name: 'IDF',
                            y: 12.5,
                        },
                        {
                            name: 'NORD EST',
                            y: 25.4,
                        },
                        {
                            name: 'SUD EST',
                            y: 18.4,
                            drilldown: 'SUD EST'
                        },
                        {
                            name: 'SANTE ET RISQUES PRODUITS',
                            y: 16.4,
                        },
                        {
                            name: 'OUEST',
                            y: 15.8,
                        },
                        {
                            name: 'SANTE ET RISQUES PRODUITS',
                            y: 16.4,
                        },
                    ]
                }, {
                    id: 'SUD EST',
                    name: 'SUD EST',
                    data: [
                        ['ALDA', 14],
                        ['Etudes & conseil / SSP Rhône-Alpes', 12.7],
                        ['Languedos Roussillon', 12.2],
                        ['Mesures Rhône-Alpes', 17.6],
                        ['PACA Corse', 15.5],
                    ]
                }]
            },
            yAxis: {
                min: 0,
                title:
                    {
                        text: 'Fréquence'
                    }
                ,
                stackLabels: {
                    enabled: true,
                    style:
                        {
                            fontWeight: 'bold',
                            color:
                                (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
                        }
                }
            }
            ,
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat:
                    '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            }
            ,
            plotOptions: {
                column: {
                    stacking: '',
                    dataLabels:
                        {
                            enabled: true,
                            color:
                                (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
                        }
                },
                scatter: {
                    tooltip: {
                        headerFormat: '{point.x}<br/>',
                        pointFormat: '{series.name}: {point.y}'
                    }
                }
            }
            ,
        };

        // if (response.status === 200) {
        //     let data = await response.json();
        //
        //     option.series[0].data = data.series;
        //     option.drilldown.series= data.drilldown;
        //
        //     this.setState({chartOption: option});
        // }
        this
            .setState({chartOption: option});
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-header ">
                    <h4>
                        Taux de fréquence
                        <SaveToDashboard
                            component="dashboard-at-frequency-breakdown"
                            size="6"
                            controller=""
                            script=""
                            title="RH - Taux de fréquence"
                        />
                    </h4>
                </div>
                <div className="card-body">
                    <HighchartsReact highcharts={Highcharts} options={this.state.chartOption}/>
                </div>
            </div>
        );
    }
}