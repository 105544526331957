import moment from "moment";

const initialState = {
    workorderDate: moment().format("DD/MM/YYYY"),
};

const dashboardReducer = (state = initialState, action) => {
    let nextState;

    switch (action.type) {
        case "setWorkorderDate":
            nextState = {
                ...state,
                workorderDate: action.value,
            };
            break;
        default:
            nextState = state;
            break;
    }

    return nextState;
};

export default dashboardReducer;
