import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, UncontrolledTooltip} from 'reactstrap'
import moment from "moment";
import Translator from "bazinga-translator";

moment.locale('fr');


export default class Advice extends Component {

    constructor(props) {
        super(props);

        this.state = {
            intervention: {
                id: this.props.intervention,
                advices: [],
            },
            advice: '',
            showAdviceModal: false,
            submitDisabled: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
     * @returns {Promise<void>}
     */
    componentDidMount() {
        this.loadIntervention();
    };

    /**
     * @returns {Promise<void>}
     */
    loadIntervention = async () => {
        let url = Routing.generate('pdp_get_intervention', {id: this.state.intervention.id});

        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (response.status == 200) {
            let responseData = await response.json();
            let intervention = JSON.parse(responseData);

            intervention.advices.reverse();

            this.setState({
                intervention: intervention,
            });
        }
    };

    handleChange = (event) => {
        this.setState({advice: event.target.value});
    };

    handleSubmit = async (event) => {
        if (this.state.advice !== "") {
            this.setState({submitDisabled: true});
            let url = Routing.generate('pdp_advice_add', {id: this.state.intervention.id});

            let data = new FormData();
            data.append('content', this.state.advice);
            let response = await fetch(url, {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                },
                body: data
            });

            if (response.status == 200) {
                let responseData = await response.json();
                let interventionData = JSON.parse(responseData);

                this.setState({
                    intervention: {
                        ...interventionData,
                        advices: interventionData.advices.reverse()
                    },
                    advice: '',
                    submitDisabled: false
                });
            }
        }
    };


    showAdviceModal = () => {
        this.setState(
            {
                showAdviceModal: true
            }
        );
    };

    closeAdviceModal = () => {
        this.setState(
            {
                showAdviceModal: false
            }
        );
    };

    scrollAdviceList() {
        const adviceList = document.getElementById('advice-list');
        if (adviceList) {
            adviceList.scrollTop = adviceList.scrollHeight;
        }
    }

    render() {

        let advicesList = this.state.intervention.advices.map((advice, index) => {
            let date = moment(advice.created);
            return (
                <div className="toast fade show" role="message" aria-live="assertive" aria-atomic="true" key={index}
                     data-autohide="false">
                    <div className="toast-header">
                        <strong className="mr-auto">{advice.user.first_name} {advice.user.last_name}</strong>
                        <small className="text-muted">{date.format('DD/MM/YYYY HH') + "h" + date.format('mm')}</small>
                    </div>
                    <div className="toast-body" style={{'whiteSpace': 'pre-line'}}>
                        {advice.content}
                    </div>
                </div>
            );
        });

        return (
            <span>
                <Button id="tooltip-advice" color="link" className="btn-actions messaging-notify"
                        data-badge={this.state.intervention.advices.length} onClick={this.showAdviceModal.bind(this)}>
                    <i className="far fa-comment fa-fw"></i>
                </Button>
                <UncontrolledTooltip
                    target={"tooltip-advice"}>{Translator.trans('mf.pdp.layout.pdp.btn.advice')}</UncontrolledTooltip>
                <Modal
                    className="modal-full-height modal-right"
                    isOpen={this.state.showAdviceModal}
                    toggle={this.closeAdviceModal.bind(this)}
                >
                    <ModalHeader toggle={this.closeAdviceModal.bind(this)}>
                        Avis
                    </ModalHeader>
                    <ModalBody id={"advice-list"}>
                        {advicesList}
                    </ModalBody>
                    {
                        this.props.granted ?
                            <ModalFooter>
                                <textarea value={this.state.advice} onChange={this.handleChange}
                                          className="form-control" rows="4"/>
                                <Button disabled={this.state.submitDisabled} block={true} color="primary"
                                        onClick={this.handleSubmit}>
                                    Envoyer
                                    {
                                        this.state.submitDisabled ?
                                            <Spinner size="sm" type={"grow"} color="light" className={"ml-2"}/>
                                            :
                                            ''
                                    }
                                </Button>
                            </ModalFooter>
                            : ''
                    }

                </Modal>
            </span>
        )
    }
}