import React from "react";
import { connect } from "react-redux";
import Routing from "../../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import SaveToDashboard from "../../save-to-dashboard";
import Translator from "bazinga-translator";
import ReactPaginate from "react-paginate";
import WorkorderDate from "./WorkorderDate";

Routing.setRoutingData(require("../../../../../../../../js/fos_js_routes.json"));

class PdpWorkOrders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            date: props.workorderDate,
            work_orders: [],
            itemPerPage: 5,
            nbTotalItems: 0,
            itemOffsetPage: 0,
            nbTotalItemPage: 0,
        };
    }

    getData = async () => {
        let params = {
            date: this.state.date,
            limit: this.state.itemPerPage,
            offset: this.state.itemOffsetPage,
        };

        let url = Routing.generate("pdp_work_order_today_list");

        url = url.concat(
            "?",
            Object.entries(params)
                .map(([key, val]) => `${key}=${val}`)
                .join("&")
        );

        let response = await fetch(url, {
            method: "GET",
            credentials: "same-origin",
        });

        if (response.status === 200) {
            let data = await response.json();

            this.setState({
                work_orders: data.informations.work_orders,
                nbTotalItems: data.informations.nbTotalItems,
                nbTotalItemPage: Math.ceil(data.informations.nbTotalItems / this.state.itemPerPage),
            });
        }
    };

    handleClickPages = async (page) => {
        let offset = Math.ceil(page.selected * this.state.itemPerPage);

        this.setState({ itemOffsetPage: offset }, () => this.getData());
    };

    handleSelectNbItem = (event) => {
        this.setState({ itemPerPage: event.target.value, itemOffsetPage: 0 }, () => this.getData());
    };

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate() {
        const date = this.props.workorderDate;

        if (date != this.state.date) {
            this.setState(
                {
                    date: date,
                },
                () => this.getData()
            );
        }
    }

    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-header ">
                    <h4>
                        {Translator.trans("mf.pdp.indicator.work_orders.title")}

                        <SaveToDashboard
                            component="dashboard-pdp-work-orders"
                            size="6"
                            controller=""
                            script=""
                            title={"PDP - " + Translator.trans("mf.pdp.indicator.work_orders.title")}
                        />
                    </h4>
                </div>
                <div className="card-body card-table table-responsive">
                    <WorkorderDate />

                    <table className="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th>{Translator.trans("mf.pdp.indicator.work_orders.pdp_number")}</th>
                                <th>{Translator.trans("mf.pdp.indicator.work_orders.subject")}</th>
                                <th>{Translator.trans("mf.pdp.indicator.work_orders.ordering_customer")}</th>
                                <th>{Translator.trans("mf.pdp.indicator.work_orders.sector")}</th>
                                <th>{Translator.trans("mf.pdp.indicator.work_orders.start_date")}</th>
                                <th>{Translator.trans("mf.pdp.indicator.work_orders.end_date")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.work_orders.length > 0 ? (
                                this.state.work_orders.map((workOrder, index) => {
                                    let link = Routing.generate("pdp_show_intervention_tiers", { id: workOrder.pdpId });
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <a target="_blank" href={link}>
                                                    {workOrder.pdpNumber}
                                                </a>
                                            </td>
                                            <td>
                                                <a target="_blank" href={link}>
                                                    {workOrder.subject}
                                                </a>
                                            </td>
                                            <td>
                                                <a target="_blank" href={link}>
                                                    {workOrder.orderingCustomer}
                                                </a>
                                            </td>
                                            <td>
                                                <a target="_blank" href={link}>
                                                    {workOrder.sector}
                                                </a>
                                            </td>
                                            <td>
                                                <a target="_blank" href={link}>
                                                    {workOrder.startDate}
                                                </a>
                                            </td>
                                            <td>
                                                <a target="_blank" href={link}>
                                                    {workOrder.endDate}
                                                </a>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={6}></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-md-3 col-sm-12">
                            {this.state.work_orders.length > 0 ? (
                                <div className="datatable-info">
                                    {this.state.itemOffsetPage + 1} - {this.state.itemOffsetPage + this.state.work_orders.length} sur{" "}
                                    {this.state.nbTotalItems} résultats
                                </div>
                            ) : (
                                <div className="datatable-info">0 - 0 sur 0 résultats</div>
                            )}
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <ReactPaginate
                                breakClassName="page-item"
                                breakLabel={<button className="page-link">...</button>}
                                pageClassName="page-item"
                                previousClassName="page-item"
                                previousLabel="Précédente"
                                nextClassName="page-item"
                                nextLabel="Suivante"
                                pageLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                pageCount={this.state.nbTotalItemPage}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handleClickPages}
                                containerClassName={"pagination justify-content-center"}
                                activeClassName={"active"}
                            />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <select className="pagination-dashboard form-control float-right" onChange={this.handleSelectNbItem}>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state) => state.dashboardReducer)(PdpWorkOrders);
