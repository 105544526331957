import Routing
    from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import PreventionPlanManager from "./PreventionPlanManager";

const routes = require("../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export class InterventionForm {

    constructor() {
        InterventionForm.initTiersAddress();
        InterventionForm.initTiersContact();
        InterventionForm.initWorkflow();
    };

    static loadInterventionTiers() {
        var url = Routing.generate('pdp_get_tiers_form', {
            'id': $('#intervention_tiersSearch').val()
        });
        $.ajax({
            url: url,
            success: function (html) {
                $('#tiers-form').replaceWith($(html).find('#tiers-form'));
                App.initSelectPicker();
                InterventionForm.initTiersAddress();
                InterventionForm.initTiersContact();
            },
            method: "GET",
            processData: false,
            contentType: false
        });
    }

    static initTiersAddress() {
        $('#intervention_addressSearch').on('change', function () {
            if ($(this).val()) {
                var url = Routing.generate('pdp_get_tiers_address_form', {
                    'id': $(this).val()
                });
                $.ajax({
                    url: url,
                    success: function (html) {
                        $('#tiers-address-form').replaceWith($(html).find('#tiers-address-form'));
                    },
                    method: "GET",
                    processData: false,
                    contentType: false
                });
            } else {
                $('#tiers-address-form input').each(function () {
                    $(this).val('')
                });
            }
        });
    }

    static initTiersContact() {
        $('#intervention_contactsSearch').on('change', function () {
            if ($(this).val()) {
                var url = Routing.generate('pdp_get_tiers_contact_form', {
                    'id': $(this).val()
                });
                $.ajax({
                    url: url,
                    success: function (html) {
                        $('#tiers-contact-form').replaceWith($(html).find('#tiers-contact-form'));
                    },
                    method: "GET",
                    processData: false,
                    contentType: false
                });
            } else {
                $('#tiers-contact-form input').each(function () {
                    $(this).val('')
                })
            }
        });
    }

    static initWorkflow() {
        $('.intervention-workflow-button').on('click', function () {
            let transition = $(this).data('transition');
            let interventionId = $(this).data('intervention-id');
            App.modalConfirmAction(function () {
                InterventionForm.applyTransition(transition, interventionId)
            });
        });
    }

    static applyTransition(transition, interventionId) {
        var url = Routing.generate('pdp_apply_transition', {
            'transition': transition,
            'id': interventionId
        });
        $.ajax({
            url: url,
            dataType: 'json',
            success: function (data) {
                $("#state").html(data['state']);
                $("#transition-buttons").replaceWith(data['buttons']);
                if (data['result'] === false) {
                    alert('problem');
                }
                InterventionForm.initWorkflow();
                PreventionPlanManager.initHistory();
            },
            method: "POST",
            processData: false,
            contentType: false
        });
    }
}