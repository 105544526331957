import React from 'react';
import SaveToDashboard from '../save-to-dashboard';
import Translator from 'bazinga-translator';

import Routing
    from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import ReactPaginate from "react-paginate";

const routes = require("../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export default class ResourceCount extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            count: 0
        }
    }

    async getData() {
        let url = Routing.generate('resource_count');
        let response = await fetch(url, {
            method: "GET",
            credentials: 'same-origin'
        });

        if (response.status == 200) {
            let data = await response.json();

            this.setState({
                count: data.count,
            });
        }
    }

    componentDidMount() {
        this.getData();
    }


    render() {
        return (
            <div className="card mb-2 mt-2">
                <div className="card-header">
                    <h4>
                        {Translator.trans('eobs.dashboard.activityMap.indicator.resource-count.title')}
                        <SaveToDashboard
                            component="dashboard-resource-count"
                            size="3"
                            controller=""
                            script=""
                            title={'Carte d\'activité - ' + Translator.trans('eobs.dashboard.activityMap.indicator.resource-count.title')}
                        />
                    </h4>
                </div>
                <div className="card-body">
                    <div className="row dashboard">
                        <div className="col-md-2">
                            <span className="icon-indicator fas fa-info fa-3x"></span>
                        </div>
                        <div className="col-md-10 text-right">
                            <span className="number">{this.state.count}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}