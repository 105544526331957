const initialState = {
    tiersSearch: null,
    addressSearch: null,
    contactsSearch: null,
    sector: null,
    startDate: null,
    endDate: null,
    autocompleteTiers: false,
    inputsToClear: [],
    inputsToReadonly: [],
    inputToChange: null,
    inputValue: null,
    readonlyState: false,
};

const formReducer = (state = initialState, action) => {
    let nextState;

    switch (action.type) {
        case "setInputEmpty":
            nextState = {
                ...state,
                inputsToClear: action.value,
            };
            break;
        case "setInputReadonly":
            nextState = {
                ...state,
                inputsToReadonly: action.inputsId,
                readonlyState: action.value,
            };
            break;
        case "setInputValue":
            nextState = {
                ...state,
                inputToChange: action.inputId,
                inputValue: action.value,
            };
            break;
        case "setTiersSearch":
            nextState = {
                ...state,
                tiersSearch: action.value,
                autocompleteTiers: action.autocompleteTiers
            };

            break;
        case "setAddressSearch":
            nextState = {
                ...state,
                addressSearch: action.value,
            };

            break;
        case "setContactsSearch":
            nextState = {
                ...state,
                contactsSearch: action.value,
            };

            break;
        case "setSector":
            nextState = {
                ...state,
                sector: action.value,
            };

            break;
        case "setStartDate":
            nextState = {
                ...state,
                startDate: action.value,
            };

            break;
        case "setEndDate":
            nextState = {
                ...state,
                endDate: action.value,
            };

            break;
        default:
            nextState = state;
            break;
    }

    return nextState;
};

export default formReducer;